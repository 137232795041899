@text-color: #f64;
@teachVideo: teach-Video;
.@{teachVideo}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  .@{teachVideo} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .title-wrapper > h1,
  > h1 {
    font-size: 42px;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei,
      Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
    .title-content {
      color: #cfcfcf;
      font-size: 16px;
    }
  }
}

.@{teachVideo} {
  padding: 85px 24px 240px 24px;
}
@content4: content4;
.@{content4}-wrapper {
  min-height: 720px;
  background: url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/home-banner.png?sign=4a3d64265c396b94b4a9a5be653a4fe6&t=1634014076)
    no-repeat 50% / cover;
  .@{content4} {
    height: 100%;
    overflow: hidden;
    &-video {
      border-radius: 15px;
      overflow: hidden;
      max-width: 900px;
      margin: auto;
      background: #fff;
      box-shadow: 0 3px 19px rgba(255, 255, 255, 0.75);
      video {
        display: block;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{content4}-wrapper {
    min-height: 350px;
    .@{content4} {
      overflow: hidden;
      width: 90%;
      margin: auto;
      &-video {
        top: 15%;
        background: url('https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg')
          no-repeat center;
        background-size: cover;
      }
    }
  }
}
