.recruit-page {
  margin-top: 70px;
  background: url(https://636c-cloud1-6gfg07qz1e69545f-1312777785.tcb.qcloud.la/bg.svg?sign=f8dc4fff4f630bb2ddd85a65eb4d72c8&t=1662717331)
    center/cover no-repeat;
  background-color: rgba(34, 39, 46, 1);
  padding-bottom: 50px;
}
.recruit-info {
  margin: 0 auto;
  width: 1100px;
  min-height: 100vh;
  &-banner {
    height: auto;
  }
  &-item {
    height: auto;
    margin-top: 10px;
  }
}

/* 移动端 */
@media (max-width: 999px) {
  .recruit-info {
    margin: 0 auto;
    width: 750px;
    min-height: 100vh;
    &-banner {
      height: auto;
    }
    &-item {
      height: auto;
      margin-top: 10px;
    }
  }
}
@media (max-width: 759px) {
  .recruit-info {
    margin: 0 auto;
    width: 370px;
    min-height: 100vh;
    &-banner {
      height: auto;
    }
    &-item {
      height: auto;
      margin-top: 10px;
    }
  }
}
