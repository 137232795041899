body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer-wrapper {
  width: 100%;
  position: relative;
}
.footer-wrapper .footer {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.footer-wrapper .title-wrapper > h1,
.footer-wrapper > h1 {
  font-size: 32px;
  color: #999;
  margin-bottom: 16px;
}
.footer-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.footer {
  padding: 128px 24px;
}
.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .footer {
  padding: 64px 24px 20px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #ff3d3d;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
}
.footer1-wrapper .copyright-wrapper .footer {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
.flooter-img-box {
  display: flex;
  flex-direction: row;
  width: 1047px;
  margin: 0 auto;
  justify-content: space-around;
  margin-top: 30px;
}
.flooter-img-box-item {
  width: 100px;
  height: 120px;
  text-align: center;
}
.flooter-img-box-item > img {
  height: 100px;
  width: 100px;
  margin-bottom: 5px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .footer {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .footer {
    padding: 0;
  }
  .footer1 .copyright-wrapper .footer .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}

.login-title {
  padding-top: 40px;
}
.login-title-left,
.login-title-right {
  line-height: 44px;
  border: 1px solid #cfcfcf;
  width: 154px;
  border-radius: 2px;
  display: inline-block;
  margin: 0 8px;
  font-family: MicrosoftYaHei;
}
.login-title-left.active,
.login-title-right.active {
  border-color: #ff4f4c;
  color: #ff4f4c;
  cursor: pointer;
}
.login-title-left:hover,
.login-title-right:hover {
  border-color: #ff4f4c;
  color: #ff4f4c;
  cursor: pointer;
}
.login .qr {
  margin-top: 28px;
  position: relative;
}
.login .qr-imageCode {
  border: 1px solid rgba(168, 168, 168, 0.8);
  width: 200px;
  height: 200px;
}
.login .qr-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login .qr-fild {
  position: absolute;
  width: 201px;
  height: 201px;
  top: 0;
  left: 23%;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
}
.login .qr-fild-content {
  width: 50px;
  height: 50px;
  background: url('https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/youjia/%E9%87%8D%E6%96%B0%E5%8A%A0%E8%BD%BD.png?sign=46f79ff890a1cfcc3828f70e96bf393a&t=1648471317') no-repeat 50% / cover;
}
.login .qr-fild .button {
  cursor: pointer;
  width: 140px;
  height: 45px;
  background: #f83e25;
  word-spacing: 6px;
  font-size: 16px;
  transition: all 0.2s ease-in;
  line-height: 45px;
  color: #ffff;
  font-weight: 500;
  border-radius: 4px;
}
.login .qr-fild .button:active {
  background: #c59e99;
}
.login .qr P {
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  transition: all 0.2s ease-in;
}
.login .login-text {
  line-height: 30px;
  margin-top: 10px;
}
.login .login-text .iconfont {
  color: #3fab40;
  font-size: 22px;
  top: 2px;
  font-family: 'iconfont' !important;
}
.login .login-text .text {
  margin: 0;
  padding-left: 5px;
  border: 0;
  font-size: 100%;
  font-weight: 400;
  vertical-align: baseline;
}
.login .privacy {
  line-height: 15px;
  margin-top: 15px;
  display: block;
  font-size: 14px;
  color: #9c9c9c;
  text-align: center;
}
.login .ant-modal {
  box-shadow: 0 4px 15px 0 #e8e8e8;
  border-radius: 6px;
}

.header {
  height: 55px;
  position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  transition: background 0.3s, opacity 0.3s;
  background-color: #202329;
  min-width: 1280px;
  -webkit-backdrop-filter: saturate(95%) blur(6px);
  backdrop-filter: saturate(95%) blur(6px);
}
.header.hasBG {
  background: rgba(18, 21, 26, 0.8);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}
.header .header-box {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.header img.logo {
  cursor: pointer;
  height: 30px;
  text-align: center;
  margin-top: 14px;
}
.header .header-items {
  height: 100%;
  display: flex;
  flex: 1 1;
  justify-content: flex-end;
}
.header .header-items-middle {
  width: 400px;
  display: flex;
  flex: 1 1;
  justify-content: center;
  margin: 0 auto;
}
.header .header-items .header-item {
  flex: none;
  position: relative;
  height: 100%;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', 'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
  font-weight: 400;
  text-align: left;
  color: #fff;
  cursor: pointer;
  line-height: 20px;
  transition: all 0.3s ease;
}
.header .header-items .header-item > img {
  width: 40px;
  height: 20px;
  margin-left: 10px;
}
.header .header-items .header-item.active {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.header .header-items .header-item.active::after {
  opacity: 1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-left: 35px;
  align-items: center;
}
.header .header-items .header-item::after {
  content: '';
  width: 0%;
  height: 2px;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.header .header-items .header-item .node {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: white;
  margin-right: 8px;
  position: relative;
  left: -14px;
  opacity: 0;
  transform: scaleX(1.67);
  transition: left 0.3s, transform 0.3s, opacity 0.1s;
}
.header .header-items .header-item .node.active {
  transform: scaleX(1);
  opacity: 1;
  left: 0;
}
.header .header-items .header-item:hover .node {
  transform: scaleX(1);
  opacity: 1;
  left: 0;
}
.header .header-items .header-item:hover {
  color: #ff3d3d;
  font-size: 15px;
  font-weight: 500;
}
.header .header-items .header-item .lottie-anima-new {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 10px;
  top: 10px;
}
.header .header-items .header-item::after {
  content: '';
  width: 0%;
  height: 2px;
  background: #ff3d3d;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease;
}
.header .header-items .header-item:hover::after {
  opacity: 1;
  width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-left: 35px;
  align-items: center;
}
.header .regist {
  cursor: pointer;
  flex: none;
  width: 108px;
  height: 70px;
  background: rgba(255, 255, 255, 0.05);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #fff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .regist .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.header .regist .animation {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100%;
  opacity: 1;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  transition: left 0.3s, opacity 0.1s;
}
.header .regist:hover .animation {
  left: 0;
  opacity: 1;
}
.header .login {
  cursor: pointer;
  flex: none;
  width: 150px;
  height: 55px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  font-weight: 700;
  text-align: center;
  color: #fff;
  line-height: 20px;
  padding-left: 40px;
  display: flex;
  align-items: center;
  position: relative;
}
.header .login .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.header .login .animation {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 100%;
  opacity: 1;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  transition: left 0.3s, opacity 0.1s;
}
.header .login:hover .animation {
  left: 0;
  opacity: 1;
}
.dropdown {
  background: #fff;
  border-radius: 20px !important;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.04), 0 4px 16px 4px rgba(0, 0, 0, 0.02), 0 4px 12px 0 rgba(0, 0, 0, 0.02);
  height: 388px;
  margin-top: 14px !important;
  padding: 0;
  width: 336px !important;
}
.dropdown .profile-bg {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 96px;
  left: 0;
  position: absolute;
  top: 0;
  width: 336px;
  z-index: -1;
}
.dropdown .header-profile-top {
  overflow: hidden;
  padding-left: 24px;
  padding-top: 24px;
}
.dropdown .header-profile-top .self-info-name {
  align-items: center;
  display: flex;
}
.dropdown .header-profile-top .self-info-name .name-desc {
  height: 48px;
  margin-left: 8px;
  margin-top: 4px;
}
.dropdown .header-profile-top .self-info-name .name-desc .name {
  align-items: center;
  display: flex;
  height: 20px;
}
.dropdown .header-profile-top .self-info-name .name-desc .name .name-img {
  height: 14px;
  margin-left: 2px;
}
.dropdown .header-profile-top .self-info-name .name-desc .name .name-link {
  color: #ff813a;
  font-weight: 500;
}
.dropdown .header-profile-top .self-info-name .name-desc .identify {
  color: #999 !important;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
  width: -webkit-max-content;
  width: max-content;
}
.dropdown .header-profile-top .self-info-name .name-desc .identify .hove-to-green {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  align-items: center;
}
.dropdown .header-profile-top .self-info-name .name-desc .identify .nc-icon {
  vertical-align: -0.15em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-rendering: optimizeLegibility;
  text-transform: none;
}
.dropdown .header-profile-top .self-page {
  box-sizing: border-box;
  color: #32ca99;
  display: block;
  font-size: 12px;
  font-weight: 500;
  height: 26px;
  line-height: 26px;
  position: absolute;
  right: 16px;
  text-align: center;
  top: 66px;
  width: 56px;
}
.dropdown .menu-top {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.header-right {
  display: flex;
  align-items: center;
  width: 200px;
}
.profile-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.vip-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  cursor: pointer;
}
.vip-menu-text {
  color: #f0f0f0;
  font-size: 12px;
  font-weight: 400;
  height: 12px;
  line-height: 12px;
  margin-top: 6px;
}
.ling-lone {
  background: #f8f8f8;
  height: 1px;
  margin-top: 20px;
  width: 336px;
}
.vip-card {
  background-size: cover;
  border-radius: 12px;
  box-sizing: border-box;
  height: 78px;
  margin-left: 24px;
  margin-top: 12px;
  padding-left: 16px;
  padding-top: 18px;
  position: relative;
  width: 288px;
}
.vip-card-bg {
  border-radius: 12px;
  height: 78px;
  left: 0;
  position: absolute;
  top: 0;
  width: 288px;
  z-index: -1;
}
.vip-card .vip-text-label {
  align-items: center;
  color: #333;
  display: flex;
  font-weight: 500;
  height: 24px;
  width: 108px;
}
.vip-card .vip-text-label .yj-vip-text {
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
}
.vip-card .vip-text-label .yj-vip-text.vip {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(312deg, #53f7e2, #bafca3 46%, #f2f07c 79%, #ffe741);
  -webkit-background-clip: text;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
}
.vip-card .vip-text-label .vip-label-pic {
  flex-shrink: 0;
  height: 18px;
  margin-left: 4px;
  width: 40px;
}
.vip-card .yj-vip-desc {
  color: #999;
  font-size: 12px;
  line-height: 26px;
}
.vip-card .open-vip-btn {
  -webkit-box-pack: center;
  align-items: center;
  background: linear-gradient(315deg, #ffdead, #ffbd7f 21%, #fff9d7 53%, #ffebba);
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  left: 208px;
  position: absolute;
  top: 27px;
  width: 64px;
}
.vip-card .open-vip-btn-text {
  color: #4c260e;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
}
.nc-nav-header-link {
  margin-left: 24px;
  width: 288px;
}
.nc-nav-header-link-item {
  align-items: center;
  border-radius: 8px;
  color: #555;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 400;
  height: 58px;
  line-height: 14px;
  margin-top: 12px;
  width: 72px;
}
.nc-nav-header-link-item-icon {
  margin-top: 8px;
  width: 20px;
  height: 20px;
}
.nc-nav-header-link-item-text {
  margin-top: 8px;
}
.nc-nav-header-link-line {
  background: #f8f8f8;
  height: 1px;
  margin-bottom: 12px;
  margin-top: 12px;
  width: 288px;
}
.nc-nav-header-link .account-set {
  align-items: center;
  color: #555;
  display: flex;
  font-size: 14px;
  height: 22px;
  line-height: 22px;
}
.nc-nav-header-link .account-set-item {
  margin-left: 44px;
  cursor: pointer;
  display: inline-block;
}
.nc-nav-header-link .account-set-middle {
  width: 1px;
  height: 14px;
  background: #f8f8f8;
  margin-left: 44px;
}
.vip-menu-dropdown {
  background-image: url(https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/vip-card-bg.png);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.04), 0 4px 16px 4px rgba(0, 0, 0, 0.02), 0 4px 12px 0 rgba(0, 0, 0, 0.02);
  background-size: cover;
  border-radius: 20px;
  box-sizing: border-box;
  height: 292px;
  overflow: hidden;
  padding-left: 30px;
  width: 364px;
}
.vip-menu-dropdown .title-line-1 {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(315deg, #53f7e2, #bafca3 46%, #f2f07c 79%, #f9e555);
  -webkit-background-clip: text;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  height: 20px;
  line-height: 20px;
  margin-top: 30px;
}
.vip-menu-dropdown .title-line-2 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  margin-top: 8px;
  width: 144px;
}
.vip-menu-dropdown .vip-desc-card-warpper {
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: linear-gradient(149deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.12));
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  height: 98px;
  justify-content: space-between;
  margin-top: 20px;
  padding: 8px;
  width: 304px;
}
.vip-menu-dropdown .vip-desc-card-warpper .privilege-card {
  align-items: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 82px;
  width: 88px;
}
.vip-menu-dropdown .vip-desc-card-warpper .privilege-card-icon {
  height: 36px;
  margin-top: 12px;
  width: 36px;
}
.vip-menu-dropdown .vip-desc-card-warpper .privilege-card-text {
  color: #fff;
  display: block;
  font-size: 12px;
  line-height: 12px;
  margin-top: 10px;
  text-align: center;
}
.vip-menu-dropdown .vips-btn {
  box-sizing: border-box;
  display: flex;
  margin-top: 20px;
}
.vip-menu-dropdown .vips-btn .learn-vip-btn-bg {
  background: linear-gradient(270deg, #ffc185, #fff3cf, #ffebbc);
  border-radius: 24px;
  box-shadow: 0 2px 12px 0 rgba(255, 195, 135, 0.36);
  box-sizing: border-box;
  height: 48px;
  margin-left: 12px;
  padding: 1px;
  width: 128px;
}
.vip-menu-dropdown .vips-btn .learn-vip-btn-bg-a {
  background: #080d26;
  height: 46px;
  width: 126px;
  align-items: center;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
}
.vip-menu-dropdown .vips-btn .learn-vip-btn-bg-a-span {
  color: #ffc285;
  font-weight: 500;
  line-height: 16px;
}
.vip-menu-dropdown .vips-btn .open-vip-btn {
  background: linear-gradient(315deg, #ffdead, #ffbd7f 21%, #fff9d7 53%, #ffebba);
  color: #4c260e;
  font-weight: 500;
  height: 48px;
  margin-left: 24px;
  position: relative;
  width: 128px;
  align-items: center;
  border-radius: 24px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
}

.index_content .content_box .btn_get {
  margin: 50px auto 0;
  cursor: pointer;
  width: 288px;
  height: 60px;
  line-height: 60px;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#13D8A7, endColorstr=#00C2EF);
  background-image: linear-gradient(90deg, #13d8a7 3%, #00c2ef);
  border-radius: 30px;
  font-size: 26px;
  color: #fff;
  box-shadow: 0 10px 20px rgba(19, 216, 167, 0.3);
  transition: all 0.2s ease-out 0s;
  position: relative;
  z-index: 2;
  text-align: center;
}
.index_content .content_box .btn_get:hover {
  transform: translateY(-6px);
  transition: all 0.4s;
}
.content_fuwu {
  margin-top: -550px;
  position: relative;
  z-index: 2;
}
.content_fuwu .fuwu_card {
  padding: 10px 0 20px;
  overflow: hidden;
  max-width: 1100px;
  margin: 0 auto;
}
.content_fuwu .fuwu_card li {
  float: left;
  width: 46%;
  text-align: center;
  font-size: 14px;
  margin: 20px 2% 0;
  padding: 0 10px 30px;
  background-color: #fff;
  border-radius: 50% 50% 0 0;
  min-height: 440px;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
}
.content_fuwu .fuwu_card li h4 {
  font-size: 24px;
  color: #222;
  text-align: center;
  line-height: 20px;
  margin-top: 38px;
}
.content_fuwu .fuwu_card li p {
  margin-top: 25px;
  color: #888;
  min-height: 44px;
}
.content_fuwu .fuwu_card li dl {
  padding-left: 12%;
  margin-top: -10px;
}
.content_fuwu .fuwu_card li dl dd {
  padding: 10px 0 10px 30px;
  text-align: left;
  line-height: 13px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
.content_fuwu .fuwu_card li dl dd .webicon-xuanze {
  position: absolute;
  left: 2px;
  top: 7px;
  font-size: 16px;
}
.content_fuwu .fuwu_card li dl dd .webicon-xuanze img {
  width: 21px;
  height: 21px;
  color: #13d8a7;
}
.content_fuwu .fuwu_card li dl dd span {
  color: #ff3b00;
}
.content_fuwu .fuwu_card li .btn_get {
  width: 200px;
  height: 44px;
  line-height: 44px;
  font-size: 20px;
  margin-top: 30px;
}

.index_content .content_tuandui {
  margin-top: -30px;
}
.index_content .content_box {
  padding: 80px 0;
  position: relative;
}
.index_content .content_box .team-icon {
  text-align: center;
}
.index_content .content_box .team-icon_ {
  width: 40px;
  height: 40px;
}
.index_content .content_box h3 {
  text-align: center;
  font-size: 32px;
  color: #222;
  line-height: 36px;
}
.index_content .content_box .text_tit {
  text-align: center;
  margin-top: 18px;
  color: #666;
  font-size: 16px;
}
.index_content .content_box .teacher_list {
  max-width: 1150px;
  margin: 0 auto;
  overflow: hidden;
  padding: 200px 0 60px;
}
.index_content .content_box .teacher_list li {
  float: left;
  height: 250px;
  width: 24%;
  position: relative;
}
.index_content .content_box .teacher_list li .tutorAvatar {
  position: absolute;
  float: left;
  top: -150px;
  width: 190px;
  z-index: -1;
  margin-left: 45px;
  top: -140px;
}
.index_content .content_box .teacher_list li .teacher_card {
  position: absolute;
  width: 96%;
  margin: auto;
  height: 339px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  overflow: hidden;
  z-index: 1;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.45));
}
.index_content .content_box .teacher_list li .teacher_card .tutorName {
  position: absolute;
  width: 85%;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 10px;
  top: 90px;
}
.index_content .content_box .teacher_list li .teacher_card .tutorName-odd {
  transform: skew(10deg, -10deg);
  background-image: linear-gradient(to right, #b8b8b8 70%, rgba(93, 89, 89, 0) 0%);
  background-position: bottom;
  background-size: 4px 1.1px;
  background-repeat: repeat-x;
  width: 100%;
  float: right;
}
.index_content .content_box .teacher_list li .teacher_card .tutorName-odd-name {
  position: absolute;
  display: inline-block;
  width: 50%;
  overflow: auto;
  background: #ff813a;
  text-align: center;
  margin-top: -15px;
  transform: skew(-15deg, 0);
  right: -10px;
  font-family: 'DFKai-SB';
}
.index_content .content_box .teacher_list li .teacher_card .tutorName-even {
  width: 230px;
  transform: skew(-10deg, 10deg);
  background-image: linear-gradient(to right, #b8b8b8 70%, rgba(93, 89, 89, 0) 0%);
  /* 35%设置虚线点x轴上的长度 */
  background-position: bottom;
  /* top配置上边框位置的虚线 */
  background-size: 4px 1.1px;
  /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
  background-repeat: repeat-x;
  float: left;
}
.index_content .content_box .teacher_list li .teacher_card .tutorName-even-name {
  position: absolute;
  display: inline-block;
  width: 50%;
  overflow: auto;
  background: #ff813a;
  text-align: center;
  transform: skew(15deg, 0);
  margin-top: -15px;
  left: -5px;
}
.index_content .content_box .teacher_list .teacher_info {
  position: absolute;
  margin-top: 120px;
  padding-right: 15px;
}
.index_content .content_box .teacher_list .teacher_info p {
  font-size: 15px;
  line-height: 1.6;
  position: relative;
  padding-left: 10px;
  margin-top: 3px;
}
.index_content .content_box .teacher_list .teacher_info p:before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #ff813a;
  display: block;
  position: absolute;
  left: 0;
  top: 7px;
}
.index_content .content_box .teacher_background {
  width: 100%;
  position: absolute;
  bottom: 150px;
  z-index: -1;
}
.index_content .content_box .teacher_background-ellipse {
  width: 1200px;
  height: 240px;
  margin: 0 auto;
  background: #ff813a;
  border-radius: 50% / 100% 100% 0 0;
}

.content_bg {
  background-color: #f9f9f9;
}
.content_box {
  padding: 80px 0;
}
.content_box h3 {
  text-align: center;
  font-size: 32px;
  color: #222;
  line-height: 36px;
}
.content_box .liucheng_list {
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 0;
  overflow: hidden;
  margin-top: 30px;
}
.content_box .liucheng_list li {
  float: left;
  width: 18%;
  min-height: 160px;
  padding: 40px 20px;
  border-radius: 10px 66px 10px 66px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12);
  margin-left: 2%;
  text-align: center;
  position: relative;
}
.content_box .liucheng_list li h4 {
  font-size: 18px;
  color: #222;
}
.content_box .liucheng_list li p {
  margin-top: 15px;
  color: #999;
  font-size: 13px;
}
.content_box .liucheng_list li span {
  position: absolute;
  right: -22px;
  width: 20px;
  text-align: center;
  top: 50%;
  line-height: 20px;
  margin-top: -10px;
}
.content_box .liucheng_list li span img {
  width: 18px;
  height: 18px;
}
.content_box .liucheng_list li:first-child {
  margin-left: 10px;
}

.message-list {
  overflow: auto;
}
.show_chat {
  width: 400px;
  height: 500px;
  opacity: 1;
  z-index: 20;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
  position: fixed;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.4);
  border-radius: 6px;
  cursor: default;
  overflow: hidden;
  display: none;
}
.show_chat .chat_tit {
  padding: 12px 20px;
  background-color: #ff8741;
  line-height: 26px;
  font-size: 20px;
  position: relative;
  color: #fff;
}
.show_chat .chat_tit .btn_min:hover {
  background: #ff9659;
}
.show_chat .chat_tit img {
  float: left;
  width: 26px;
  height: 26px;
  border-radius: 6px;
  margin-right: 6px;
}
.show_chat .chat_tit span {
  width: 50px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s ease-out 0s;
}
.show_chat .chat_tit span img {
  width: 20px;
  height: 20px;
  width: 37px;
  height: 36px;
  margin-top: 6px;
  margin-left: 6px;
}
.show_chat .chat_tit span img:hover {
  transform: rotate(90deg);
  transition: all 0.8s;
}
.show_chat .chat_box {
  margin: 3px 5px 0 3px;
  padding-bottom: 20px;
  padding-right: 18px;
  overflow-y: auto;
  color: #333;
  height: 357px;
}
.show_chat .chat_box .chat_def {
  text-align: center;
  padding: 15px 0;
  color: #888;
}
.show_chat .chat_enter {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  padding: 15px 20px;
  background-color: #fff;
  border-top: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.show_chat .chat_enter .chat_enter_input {
  width: 80%;
  height: 60px;
  resize: none;
  line-height: 19px;
  border: none;
  font-size: small;
  margin-left: -7px;
}
.show_chat .chat_enter .chat_enter_btn {
  width: 60px;
  position: absolute;
  right: 20px;
  top: 15px;
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 3px;
}
.chat_enter_btn {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  border: 1px solid #dcdfe6;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  background: #ff8741;
  border-color: #ff8741;
  color: #fff;
}
.chat_enter_btn:hover {
  cursor: pointer;
  background: #ff975a;
  transition: 0.1s;
}

.home {
  height: 100%;
  width: 100%;
  overflow: auto;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum', 'tnum', "tnum";
  font: 12px/1.5 Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, SimSun, sans-serif;
  background-color: #fff;
  color: #333;
}
input,
samp,
textarea {
  font-family: Helvetica Neue, Arial, PingFang SC, Microsoft Yahei, SimSun, sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}
*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
}
div,
p,
textarea {
  box-sizing: border-box;
}
li,
ul {
  list-style: none;
}
dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.banner {
  padding: 130px 0 120px;
  text-align: center;
  position: relative;
  height: 700px;
  margin-top: 70px;
}
.banner .banner_bg {
  background: url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.616pic.com%2Fbg_w1180%2F00%2F13%2F23%2FWjzkkY0eMd.jpg&refer=http%3A%2F%2Fpic.616pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642232967&t=d18b07220e418021faa673e94e46ec41) 30% no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.22;
}
.banner h2 {
  font-size: 40px;
  color: #222;
  line-height: 42px;
  position: relative;
  font-weight: 700;
  z-index: 2;
}
.banner .text {
  font-size: 18px;
  color: #888;
  line-height: 24px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
.index_content .content_box .btn_get {
  margin: 50px auto 0;
  cursor: pointer;
  width: 288px;
  height: 60px;
  line-height: 60px;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#13D8A7, endColorstr=#00C2EF);
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-radius: 30px;
  font-size: 26px;
  color: #fff;
  box-shadow: 0 10px 20px rgba(218, 162, 43, 0.3);
  transition: all 0.2s ease-out 0s;
  position: relative;
  z-index: 2;
  text-align: center;
}
.float_r {
  display: block;
  position: fixed;
  box-sizing: border-box;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  z-index: 1000000000;
  width: 64px;
  border-radius: 4px;
  right: 0px;
  bottom: 0px;
  height: 64px;
  margin-bottom: 80px;
}
.float_r ul img {
  width: 40px;
  height: 37px;
  margin-left: 6px;
  margin-top: 5px;
}
.float_r ul .gotop img {
  width: 39px;
  height: 32px;
  margin-left: 6px;
  margin-top: 11px;
}
.float_r ul li {
  margin-top: 20px;
  width: 52px;
  height: 52px;
  background-color: #ff7220;
  position: relative;
  color: #fff;
  cursor: pointer;
}
.float_r ul li i {
  display: block;
  line-height: 52px;
  text-align: center;
  font-size: 36px;
}
.float_r ul li p {
  position: absolute;
  right: 52px;
  top: 0;
  height: 52px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  background-color: #ff8741;
  line-height: 52px;
  font-size: 14px;
  border-radius: 30px 0 0 30px;
  padding-left: 20px;
  width: 0;
  transition: all 0.2s ease-out 0s;
}
.float_r ul li:hover {
  background-color: #ff8741;
  transition: all 0.2s ease-out 0s;
}
.float_r ul li:hover p {
  width: 100px;
}

.side-img-container {
  width: 120px;
  height: 323px;
  border-radius: 8px;
  position: fixed;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  z-index: 2;
}
.side-img-container .close-sideImg {
  position: absolute;
  right: 0px;
  top: 1px;
  width: 35px;
  height: 25px;
  background-color: transparent;
  border-style: none;
}
.side-img-container .close-sideImg:hover {
  background-color: #ff9c62;
}
.side-img-container .close-sideImg img {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.side-img-container .close-sideImg img:hover {
  transform: rotate(90deg);
  transition: all 0.8s;
}
.side-img-container .side-img {
  width: 100%;
  height: 100%;
  display: block;
}

.header-content {
  display: flex;
  padding-top: 50px;
  width: 988px;
  margin: 0 auto;
  margin-top: 70px;
}
.header-content:before {
  display: block;
  content: '';
  width: 150px;
  height: 150px;
  background: #ff4f4f;
  opacity: 0.04;
  border-radius: 50%;
  position: absolute;
  left: -54px;
  top: 100px;
}
.header-content .header_img {
  width: 602px;
  height: 495px;
  margin-right: -102px;
  float: right;
  display: block;
}
.modal-title {
  width: '100%';
  cursor: 'move';
  text-align: center;
  align-items: center;
}
.pr {
  position: relative;
}
.header-content .header1 {
  padding-bottom: 32px;
  padding-left: 20px;
  margin: 120px 0 0 -20px;
  box-flex: 1;
  flex: 1 1;
  overflow: hidden;
  position: relative;
  float: left;
}
.header-content .header1 .tit1 {
  line-height: 38px;
  margin-bottom: 26px;
  font-size: 38px;
  font-weight: 700;
  color: #404040;
}
.header-content .header1 p {
  font-size: 18px;
  font-weight: 400;
  color: #404040;
  line-height: 36px;
}
.header-content .header1 p span {
  font-weight: 500;
  color: #ff4f4f;
}
.header-content .header1 .indexButton {
  margin-top: 34px;
  display: block;
  height: 62px;
  width: 188px;
  background: #ff4f4f;
  box-shadow: 0 6px 24px -4px #ffa4a4;
  border-radius: 31px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-align: center;
  width: 240px;
  border-width: 0px;
}

.caseIndex {
  width: 1032px;
  margin: 180px auto 0;
  height: 468px;
}
.caseIndex .casetit {
  line-height: 42px;
  margin-bottom: 98px;
  font-size: 38px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}
.caseIndex .clearfix {
  display: flex;
  justify-content: center;
  align-items: center;
}
.caseIndex .clearfix-item {
  width: 306px;
  height: 418px;
  display: block;
  position: relative;
  padding-top: 38px;
}
.caseIndex .clearfix-item .item-header-title {
  line-height: 28px;
  margin-bottom: 14px;
  padding-left: 114px;
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 700;
  color: #404040;
}
.caseIndex .clearfix-item .item-header-describe {
  bottom: 0;
  text-align: center;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 700;
  color: #9c9c9c;
}
.caseIndex .clearfix-item .item-icon {
  width: 120px;
  height: 120px;
  margin: 36px auto;
}
.caseIndex .clearfix-item .item-icon .icon {
  width: 100px;
  height: 100px;
}
.itemList {
  opacity: 1;
  line-height: 28px;
  padding-left: 32px;
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.itemList .list::before {
  content: '';
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  top: 6px;
  background: url(https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/modify_icon/right2.png) no-repeat;
  background-size: 60%;
  background-position: center;
}
.itemList .contentActive {
  width: 95%;
  margin: 0 auto;
  transform: translateY(-115px);
  margin-bottom: 25px;
}
.mid-indexButtons {
  margin: auto;
  display: block;
  line-height: 62px;
  width: 188px;
  background: #ff4f4f;
  box-shadow: 0 6px 24px -4px #ffa4a4;
  border-radius: 31px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: 40px;
  bottom: 30px;
}

.problem-content {
  height: 563px;
  margin: 180px 0 75px;
  overflow: hidden;
}
.problem-content:before {
  display: block;
  content: "";
  width: 3000px;
  height: 563px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -3144px;
  background: #fff8f8;
}
.problem-content .content {
  width: 1016px;
  margin: 0 auto;
  height: 563px;
}
.problem-content .content .left-tab {
  width: 364px;
  height: 563px;
  float: left;
}
.problem-content .content .left-tab .lefttit {
  width: 100%;
  height: 34px;
  margin: 36px 0;
  font-size: 30px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}
.problem-content .content .left-tab .Active {
  background-color: #fff;
}
.problem-content .content .left-tab .tab-cursor {
  width: 364px;
  height: 110px;
  margin-bottom: 32px;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.problem-content .content .left-tab .tab-cursor .icon img {
  width: 50px;
  height: 50px;
  margin: 0 25px 0 72px;
}
.problem-content .content .left-tab .tab-cursor img {
  display: block;
}
.problem-content .content .left-tab .tab-cursor .flex1 {
  flex: 1 1;
  overflow: hidden;
}
.problem-content .content .left-tab .tab-cursor .flex1-title {
  line-height: 22px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  color: #404040;
  padding-top: 0;
}
.problem-content .content .left-tab .tab-cursor .flex1-text {
  font-size: 14px;
  color: #404040;
}
.problem-content .content .pic {
  width: 600px;
  height: 540px;
  float: right;
}
.problem-content .content .pic img {
  display: block;
  width: 100%;
  height: 100%;
}
.mid-indexButton {
  margin: auto;
  display: block;
  line-height: 62px;
  width: 188px;
  background: #ff4f4f;
  box-shadow: 0 6px 24px -4px #ffa4a4;
  border-radius: 31px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.mid-content {
  padding-top: 180px;
  width: 988px;
  margin: 0 auto;
}
.mid-content:before {
  display: block;
  content: '';
  width: 260px;
  height: 260px;
  background: #ff4f4f;
  opacity: 0.04;
  border-radius: 50%;
  position: absolute;
  right: -60px;
  top: 86px;
}
.mid-content .indexButton {
  margin: 0 auto;
  display: block;
  line-height: 62px;
  width: 188px;
  background: #ff4f4f;
  box-shadow: 0 6px 24px -4px #ffa4a4;
  border-radius: 31px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.mid-content2 {
  display: flex;
  align-items: center;
  width: 1065px;
  padding-top: 134px;
  margin: 0 auto;
  width: 1020px;
  position: relative;
}
.mid-content2 .pic2 {
  width: 677px;
  height: 516px;
  margin-right: 62px;
  float: left;
  display: block;
  margin-left: -22px;
}
.mid-content2 .text {
  flex: 1 1;
  position: relative;
  float: right;
}
.mid-content2 .text-title {
  line-height: 34px;
  font-size: 30px;
  font-weight: 700;
  color: #404040;
}
.mid-content2 .text .text-in {
  line-height: 23px;
  margin: 50px 0 74px;
  font-size: 14px;
  color: #606060;
}
.mid-content2 .text .text-in p {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  line-height: 16px;
  margin-bottom: 12px;
}
.mid-content2 .text .text-in p img {
  width: 15px;
}
.mid-content2 .text .indexButton {
  display: block;
  line-height: 62px;
  width: 188px;
  background: #ff4f4f;
  box-shadow: 0 6px 24px -4px #ffa4a4;
  border-radius: 39px;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  text-align: center;
}
.mid-content3 {
  width: 960px;
  margin: 0 auto 180px;
  padding-top: 180px;
  position: relative;
}
.mid-content3-title {
  line-height: 42px;
  font-size: 30px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}
.mid-content3 img {
  margin-top: 80px;
  width: 960px;
  height: 364px;
  object-fit: contain;
  float: right;
  display: block;
}
.header-content .header1 .indexButton,
.mid-content .indexButton,
.mid-indexButton,
.mid-content2 .text .indexButton {
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
}
.header-content .header1 .indexButton:hover,
.mid-content .indexButton:hover,
.mid-indexButton:hover,
.mid-content2 .text .indexButton:hover {
  transform: translateY(-4px);
  transition: all 0.6s;
  background: hsl(27, 100%, 50%);
}
a,
div,
p,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}

.discourse {
  width: 1282px;
  margin: 0 auto 12px;
  padding-top: 30px;
  position: relative;
}
.discourse-title {
  line-height: 34px;
  font-size: 30px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}
.discourse-swiper {
  width: 1282px;
  height: 846px;
  margin-top: 40px;
  background-color: #fff;
  color: #404040;
  position: relative;
}
.userComment {
  background-size: cover;
  background-position: center center;
  position: unset;
  display: block;
  width: 100%;
  transform: translate(0px, 0px);
}
.userComments {
  height: 1000px;
}
.banner-anim .banner-anim-arrow-default {
  position: absolute;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  top: 45%;
  margin: 0 auto;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
  margin: 0 -10px;
}
.banner-anim .banner-anim-arrow-default:hover {
  transition: all 1s;
  background: #f38404;
  box-shadow: 0 0 30px 0 rgba(0, 9, 85, 0.08);
}
.banner-anim .banner-anim-arrow-default.next:before,
.banner-anim .banner-anim-arrow-default.prev:before,
.banner-anim .banner-anim-arrow-default.next:after,
.banner-anim .banner-anim-arrow-default.prev:after {
  width: 2px;
  height: 15px;
  background: #fff;
  display: block;
  content: ' ';
  position: absolute;
  margin-left: 18px;
}

.resume {
  width: 100%;
  background-color: #f5f5f7;
  height: 800px;
}
.resume-content {
  width: 1200px;
  margin: 0 auto;
  padding: 80px 0 80px;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  display: flex;
}
.resume-content-left {
  width: 370px;
  list-style: none;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(1, 35, 99, 0.04);
  background-color: #fff;
  height: 600px;
  margin-top: 10px;
}
.resume-content-left .resumeHeader {
  height: 80px;
}
.resume-content-left .resumeHeader-btn {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  height: 85%;
  padding-top: 20px;
}
.resume-content-left .resumeHeader-btn button {
  width: 45%;
  height: 100%;
  font-size: larger;
  border-radius: 5px;
}
.resume-content-left .resumeHeader-btn-left {
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-width: 0;
  margin-right: 6px;
}
.resume-content-left .resumeHeader-btn-left span {
  color: #fff;
  font-weight: bold;
}
.resume-content-left .resumeHeader-btn-left:hover {
  background-image: linear-gradient(90deg, #ffa16b, #fe6060);
}
.resume-content-left .resumeHeader-btn-right {
  background: #fff;
  margin-left: 9px;
  border-width: 1px;
  border-style: solid;
  border-color: #ff3d3d;
}
.resume-content-left .resumeHeader-btn-right-img {
  width: 20px;
  height: 20px;
}
.resume-content-left .resumeHeader-btn-right span {
  color: #ff813a;
  font-weight: bold;
}
.resume-content-left .resumeHeader-btn-right:hover {
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-width: 0;
}
.resume-content-left .resumeHeader-btn-right:hover span {
  color: #fff;
}
.resume-content-left .resumeHeader-btn img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
  margin-top: -2px;
}
.resume-content-left .resumeTab {
  margin: 0 15px;
  position: relative;
  height: 500px;
}
.resume-content-left .resumeTab .resumeCard {
  position: relative;
  width: 98%;
  height: 85px;
  border-radius: 8px;
  cursor: pointer;
  background: url(https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/background.jpeg?sign=36a2bf0247578fcf3b0c749b52e48dfb&t=1652179567) no-repeat 20% / cover;
  margin: 0 auto;
  margin-bottom: 10px;
}
.resume-content-left .resumeTab .resumeCard-fileImg {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 10px;
  top: 10px;
}
.resume-content-left .resumeTab .resumeCard-on {
  float: right;
  width: 80%;
  margin-top: 13px;
}
.resume-content-left .resumeTab .resumeCard-on-name {
  font-weight: bold;
}
.resume-content-left .resumeTab .resumeCard-down {
  float: right;
  width: 80%;
}
.resume-content-left .resumeTab .resumeCard-down-score {
  display: inline;
}
.resume-content-left .resumeTab .resumeCard-down-language {
  display: inline;
}
.resume-content-left .resumeTab .resumeCard-down-time {
  margin-top: 2px;
  color: #868686;
  font-size: smaller;
  float: right;
  margin-right: 20px;
}
.resume-content-left .resumeTab .resumeCard-choose {
  display: inline-block;
  position: absolute;
  bottom: 5px;
  margin-left: 20px;
}
.resume-content-left .resumeTab .resumeCard-choose a {
  color: #ff6e1a;
  font-size: smaller;
}
.resume-content-left .resumeTab .resumeCard-choose a img {
  width: 11px;
  height: 11px;
  margin-right: 2px;
  margin-bottom: 2px;
}
.resume-content-left .resumeTab .resumeCard-choose a:nth-child(n+2) {
  margin-left: 25px;
}
.resume-content-left .resumeTab .resumeCard:hover {
  box-shadow: 0 0 8px #dadada;
}
.resume-content-left .resumeTab .bottom-img {
  width: 100%;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}
.resume-content-right {
  width: 872px;
  overflow: visible;
  margin-top: 10px;
  margin-left: 15px;
}
.resume-content-right-analyseCard {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  overflow: visible;
}
.resume-content-right-analyseCard-children {
  cursor: pointer;
  border-radius: 5px;
  width: 49.2%;
  height: 95%;
  background-color: #ffffff;
  padding: 20px;
}
.resume-content-right-analyseCard-children:hover {
  box-shadow: 0 0 10px #d4d4d4;
}
.resume-content-right-analyseCard-children .analyseCard-header {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #f9f9f9;
}
.resume-content-right-analyseCard-children .analyseCard-header-icon {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}
.resume-content-right-analyseCard-children .analyseCard-header span {
  margin-left: 5px;
  font-size: 14px;
}
.resume-content-right-analyseCard-children .analyseCard-header-btn {
  cursor: pointer;
}
.resume-content-right-analyseCard-children .analyseCard-header-btn:link,
.resume-content-right-analyseCard-children .analyseCard-header-btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  color: #626262;
  font-size: small;
  font-weight: 400;
  padding: 3px 15px;
  border: 1px solid #afafaf;
  border-radius: 1000px;
  display: inline-block;
  transition: all 0.2s;
  position: absolute;
  right: 0;
}
.resume-content-right-analyseCard-children .analyseCard-header-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 3px 7px rgba(101, 101, 101, 0.5);
}
.resume-content-right-analyseCard-children .analyseCard-header-btn:hover::after {
  background-color: #ffb46a;
  transform: scaleX(1.2) scaleY(1.3);
  opacity: 0;
}
.resume-content-right-analyseCard-children .analyseCard-header-btn:active {
  transform: translateY(-3px);
}
.resume-content-right-analyseCard-children .analyseCard-header-btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 100px;
  top: 0;
  position: absolute;
  right: 0;
  z-index: -1;
  transition: all 0.3s;
}
.resume-content-right-analyseCard-children .analyseCard-content {
  margin-top: 12px;
  width: 100%;
  height: 100%;
  margin-left: 2px;
}
.resume-content-right-analyseCard-children .analyseCard-content-image {
  float: left;
  width: 60px;
  height: 60px;
}
.resume-content-right-analyseCard-children .analyseCard-content-describe {
  margin-left: 80px;
  padding-top: 5px;
}
.resume-content-right-analyseCard-children .analyseCard-content-describe-state {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
}
.resume-content-right-analyseCard-children .analyseCard-content-describe-text {
  font-size: 14px;
  font-weight: 400;
  color: #606060;
}
.resume-content-right .recommend {
  width: 100%;
  height: 400px;
  border-radius: 5px;
  background: #fff;
  margin-top: 7px;
  padding: 20px;
}
.resume-content-right .recommend-title {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  color: #404040;
  font-weight: 400;
  position: relative;
  margin-bottom: 20px;
}
.resume-content-right .recommend-box {
  display: flex;
}
.resume-content-right .recommend-box-img {
  position: relative;
  width: 242px;
  height: 322px;
  border-radius: 4px;
  margin-right: 20px;
  cursor: pointer;
}
.resume-content-right .recommend-box-img-resume {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ececec;
  border-radius: 4px;
}
.resume-content-right .recommend-box-info {
  flex: 1 1;
}
.resume-content-right .recommend-box-info-title {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}
.resume-content-right .recommend-box-info-title h6 {
  font-size: 18px;
  font-weight: 500;
  color: #404040;
  line-height: 25px;
  margin-right: 8px;
  margin-bottom: 0;
}
.resume-content-right .recommend-box-info-title span {
  font-size: 12px;
  font-weight: 400;
  color: #f64;
  line-height: 12px;
  padding: 4px 8px;
  background: #fff6f4;
  border-radius: 10px;
}
.resume-content-right .recommend-box-info-text {
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  line-height: 20px;
  margin-bottom: 16px;
}
.resume-content-right .recommend-box-info-content {
  padding: 16px 16px 18px;
  background: #f9f9f9;
  margin-bottom: 20px;
  border-radius: 20px 0 20px 0;
}
.resume-content-right .recommend-box-info-content-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}
.resume-content-right .recommend-box-info-content-title-header-img {
  width: 22px;
  height: 22px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
}
.resume-content-right .recommend-box-info-content-title-header span {
  font-size: 12px;
  font-weight: 500;
  color: #404040;
  line-height: 17px;
}
.resume-content-right .recommend-box-info-content-title-img {
  width: 28px;
}
.resume-content-right .recommend-box-info-content-text {
  font-size: 12px;
  font-weight: 400;
  color: #606060;
  line-height: 17px;
}
.resume-content-right .recommend-box-info-btn {
  display: flex;
  align-items: center;
  position: relative;
  top: 10px;
}
.resume-content-right .recommend-box-info-btn button {
  width: 94px;
  height: 36px;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  margin-right: 12px;
}
.resume-content-right .recommend-box-info-btn-cn {
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-width: 0;
  margin-right: 6px;
  color: #f5f5f7;
}
.resume-content-right .recommend-box-info-btn-cn:hover {
  background-image: linear-gradient(90deg, #ffa16b, #fe6060);
  box-shadow: 3px 3px 10px #ffeded;
  transform: translateY(-1px);
}
.resume-content-right .recommend-box-info-btn-en {
  border-width: 1px;
  border-style: solid;
  border-color: #ff3d3d;
  color: #ff813a;
  background: #fff;
  font-weight: bold;
}
.resume-content-right .recommend-box-info-btn-en:hover {
  box-shadow: 3px 3px 10px #ffd3ba;
  transform: translateY(-1px);
}
.resume-content-right .recommend-box-info-btn-right {
  position: absolute;
  right: 0;
}
.resume-content-right .recommend-box-info-btn-right-server {
  background: linear-gradient(50deg, #ffeed5, #ffc87b);
  padding: 0.35em 0;
  font-size: 17px;
  border: none;
  color: #462a03;
  font-weight: 500;
  border-radius: 1.2em;
  letter-spacing: 0.08em;
  display: flex;
  position: relative;
  align-content: center;
  align-items: center;
  overflow: hidden;
  height: 2.6em;
  padding-left: 2.8em;
  padding-right: 0.9em;
  font-size: 13px;
}
.resume-content-right .recommend-box-info-btn-right-server-icon {
  background: #fff;
  height: 2em;
  width: 2em;
  border-radius: 2em;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0.4em;
  transition: all 0.5s;
}
.resume-content-right .recommend-box-info-btn-right-server-icon img {
  transition: all 0.5s;
}
.resume-content-right .recommend-box-info-btn-right-server:hover .recommend-box-info-btn-right-server-icon {
  width: calc(100% - 0.8rem);
}

.alert-main {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
}
.alert-main .template {
  width: 824px;
  top: 50%;
  transform: translate(-3%, -42%);
  padding: 32px 32px 24px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  height: 478px;
}
.alert-main .template .menu-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.alert-main .template .menu-list .menu:first-child {
  margin-bottom: 16px;
}
.alert-main .template .menu-list .menu {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 178px;
  height: 170px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  padding: 20px 0 24px;
  cursor: pointer;
}
.alert-main .template .menu-list .menu:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.alert-main .template .menu-list .menu:hover .btn {
  opacity: 1;
}
.alert-main .template .menu-list .menu img {
  display: block;
  width: 52px;
  height: 52px;
  margin-bottom: 6px;
}
.alert-main .template .menu-list .menu p {
  padding: 0 6px;
  text-align: center;
  display: block;
  margin-block-start: 16px;
  margin-block-end: 16px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.alert-main .template .menu-list .menu .title {
  width: 103px;
  font-size: 15px;
  font-weight: 500;
  color: #404040;
  line-height: 17px;
  margin-bottom: -6px;
}
.alert-main .template .menu-list .menu .desc {
  font-size: 12px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 17px;
}
.alert-main .template .menu-list .menu .btn {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 100;
}
.alert-main .template .menu-list .menu .btn button {
  width: 154px;
  height: 35px;
  color: #f64;
  background: #fff;
  font-size: 13px;
  font-weight: 400;
  line-height: 27px;
  padding: 0;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #f64;
  box-sizing: border-box;
}
.alert-main .template .menu-list .menu .btn button:hover {
  display: block;
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-width: 0;
  color: #fff;
}
.alert-main .template .template-more-btn {
  width: 240px;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #ececec;
  font-weight: 400;
  font-size: 16px;
  color: #606060;
  text-align: center;
  line-height: 42px;
  box-sizing: border-box;
  margin: 24px auto 0;
}
.alert-main .template .template-more-btn:hover {
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-width: 0;
  color: #fff;
}

.alert-main {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.alert-main .import-resume {
  width: 600px;
  height: 332px;
  top: 50%;
  transform: translate(-4%, -42%);
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 2px;
}
.alert-main .import-resume .tips {
  width: 512px;
  height: 170px;
  background: #f9f9f9;
  border-radius: 6px;
  border: 2px dashed #cfcfcf;
  margin: 44px auto 30px;
}
.alert-main .import-resume .tips .title {
  font-size: 18px;
  color: #404040;
  margin-top: 56px;
  line-height: 50px;
  padding-top: 8px;
  font-weight: 700;
  text-align: center;
}
.alert-main .import-resume .tips .msg {
  color: #9c9c9c;
  margin-top: 16px;
  margin-bottom: 24px;
}
.alert-main .import-resume .upload-resume {
  width: 600px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: transparent;
}
.alert-main .import-resume .upload-resume .ele-upload {
  width: 100%;
  height: 100%;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  outline: 0;
}
.alert-main .import-resume .upload-resume .ele-upload .ele-upload-dragger {
  width: 100%;
  height: 100%;
  background-color: transparent;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.alert-main .import-resume .upload-resume .ele-upload .ele-upload-dragger .upload-btn {
  margin-top: 250px;
  width: 207px;
  height: 46px;
  background: #ff7a41;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  line-height: 33px;
  cursor: pointer;
}
.alert-main .import-resume .upload-resume .ele-upload .ele-upload-dragger .upload-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.alert-main .import-resume .upload-resume .ele-upload .ele-upload-input {
  display: none;
}

.personal {
  width: 100%;
  background-color: #f5f5f7;
}
.personal-content {
  width: 1116px;
  margin: 0 auto;
  padding: 90px 0 80px;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  display: flex;
}
.personal-content-left {
  width: 260px;
  list-style: none;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 rgba(1, 35, 99, 0.04);
  background-color: #fff;
}
.personal-content-left .personalItem {
  height: 70px;
  margin: 10px;
  padding: 20px 40px 20px 30px;
  cursor: pointer;
}
.personal-content-left .personalItem-content {
  border-left: 6px solid #fff;
  color: #404040;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
.personal-content-left .personalItem-content:hover {
  color: #ff4906;
  transition: all 0.3s ease;
}
.personal-content-left .personalItem-content.active {
  border-color: #ff4906;
  transition: all 0.5s ease-in-out;
}
.personal-content-left .ant-divider-horizontal {
  margin: 0;
}
.personal-content-right {
  width: 832px;
  overflow: hidden;
  border-radius: 2px;
  padding: 0 24px;
}
.personal-content-right .baseInfo {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(1, 35, 99, 0.04);
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.personal-content-right .baseInfo-from {
  margin-top: 20px;
}
.personal-content-right .baseInfo-btn {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.personal-content-right .baseInfo-btn .button {
  border-radius: 10px;
  width: 50px;
  height: 20px;
  background-color: #ff4906;
}
.personal-content-right .baseInfo-isEdit {
  position: absolute;
  top: 20px;
  right: 50px;
}
.personal-content-right .account {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(1, 35, 99, 0.04);
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.personal-content-right .account-from {
  margin-top: 20px;
}
.personal-content-right .account-btn {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.personal-content-right .account-btn .button {
  border-radius: 10px;
  width: 50px;
  height: 20px;
  background-color: #ff4906;
}
.personal-content-right .account-isEdit {
  position: absolute;
  top: 20px;
  right: 50px;
}
.personal-content-right .order {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(1, 35, 99, 0.04);
  padding: 24px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.personal-content-right .order-table {
  margin-top: 20px;
}

.recruit-info-banner-item {
  height: 250px;
}
.recruit-info-banner-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
/* 移动端 */
@media (max-width: 999px) {
  .recruit-info-banner-item {
    height: 200px;
    width: 750px;
  }
  .recruit-info-banner-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
@media (max-width: 759px) {
  .recruit-info-banner-item {
    height: 150px;
    width: 370px;
  }
  .recruit-info-banner-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.recruit-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  color: #ffff;
}
.recruit-item {
  display: flex;
  width: 100%;
  flex-flow: wrap;
  position: relative;
}
.recruit-item-code {
  font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-feature-settings: "tnum";
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  margin-bottom: 10px;
}
.recruit-item-date {
  display: flex;
  color: #909090;
}
.recruit-item-btn {
  display: flex;
  justify-content: space-between;
}
.recruit-item-website {
  margin-left: 12px;
  flex: none;
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  text-align: left;
  color: #8c8c8c;
  line-height: 21px;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.recruit-item .state {
  display: inline-block;
}
.recruit-item .state-icon {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  margin-top: 1px;
}
.ant-card-bordered {
  border-radius: 0.4em;
  transition: border 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: #fafafa 0.1em solid;
}
.ant-card-bordered:hover {
  box-shadow: 0.3em 0.3em 0.7em #00000015;
}
.ant-typography {
  margin-bottom: 0px;
}
.ant-avatar {
  width: 43px;
  height: 43px;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px;
}

.recruit-page {
  margin-top: 70px;
  background: url(https://636c-cloud1-6gfg07qz1e69545f-1312777785.tcb.qcloud.la/bg.svg?sign=f8dc4fff4f630bb2ddd85a65eb4d72c8&t=1662717331) center / cover no-repeat;
  background-color: #22272e;
  padding-bottom: 50px;
}
.recruit-info {
  margin: 0 auto;
  width: 1100px;
  min-height: 100vh;
}
.recruit-info-banner {
  height: auto;
}
.recruit-info-item {
  height: auto;
  margin-top: 10px;
}
/* 移动端 */
@media (max-width: 999px) {
  .recruit-info {
    margin: 0 auto;
    width: 750px;
    min-height: 100vh;
  }
  .recruit-info-banner {
    height: auto;
  }
  .recruit-info-item {
    height: auto;
    margin-top: 10px;
  }
}
@media (max-width: 759px) {
  .recruit-info {
    margin: 0 auto;
    width: 370px;
    min-height: 100vh;
  }
  .recruit-info-banner {
    height: auto;
  }
  .recruit-info-item {
    height: auto;
    margin-top: 10px;
  }
}

.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 200px;
  transition: all 0.2s ease;
}
.ant-form-item-control-input .y-input .ant-input-affix-wrapper {
  border: 1px solid #f3f4f6;
}
.ant-form-item-control-input .y-input .Yinput {
  border-radius: 5px;
  color: #111827;
  background-color: #f3f4f6;
}
.ant-form-item-control-input:hover {
  width: 210px;
}

.ant-form-item-control-input {
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 200px;
  transition: all 0.2s ease;
}
.ant-form-item-control-input .y-select .ant-select-selector {
  border-radius: 5px;
  color: #111827;
  background-color: #f3f4f6;
  border: 1px solid #f3f4f6;
}
.ant-form-item-control-input .y-select .ant-select-selection-placeholder {
  text-align: left;
}
.ant-form-item-control-input .y-select .ant-select-selection-item {
  text-align: left;
}
.ant-form-item-control-input:hover {
  width: 210px;
}

@font-face {
  font-family: MicrosoftYaHei;
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/IugGDA0sxXGyx6Me8DftE.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/bbkOTmCuycOXH0W885hQo.woff') format('woff');
  font-display: swap;
}
.service-page {
  width: 100vw;
  background: #f9f9f9;
}
.service-page .service-content {
  margin: 0 auto;
  height: auto;
  width: 1054px;
  padding: 90px 0 35px;
}
.service-page .service-info {
  background: #fff;
  border-radius: 10px;
  padding: 32px 72px 48px 72px;
  width: 1054px;
}
.service-page .service-info-title {
  font-size: 18px;
  color: #404040;
  font-weight: 700;
  position: relative;
}
.service-page .service-info-title > .num {
  width: 32px;
  position: absolute;
  height: 19px;
  line-height: 19px;
  top: 3px;
  font-size: 18px;
  left: -40px;
  border-radius: 10px 6px 0 10px;
  background: #ff4f4f;
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  color: #fff;
  text-align: center;
}
.service-page .service-info-contant {
  margin-top: 32px;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 12px;
  gap: 12px;
}
.service-page .service-info-contant-item {
  padding: 16px 20px 20px;
  border-radius: 6px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  background: #fff;
  cursor: pointer;
  margin-bottom: 20px;
  width: 445px;
}
.service-page .service-info-contant-item:hover {
  box-shadow: 0 0 10px #d4d4d4;
}
.service-page .service-info-contant-item .service-item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.service-page .service-info-contant-item .service-item-header > h6 {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: #404040;
  line-height: 22px;
}
.service-page .service-info-contant-item .service-item-header > h6 > img {
  width: 50px;
  height: 20px;
  margin-left: 10px;
}
.service-page .service-info-contant-item .service-item-header-detail {
  position: relative;
  width: 48px;
  height: 22px;
  line-height: 22px;
  background: #f9f9f9;
  border-radius: 11px;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: #bcbcbc;
  text-align: center;
}
.service-page .service-info-contant-item .service-item-header-detail:hover {
  background-color: #505667;
  color: #fff;
}
.service-page .service-info-contant-item .service-item-desc {
  height: 45px;
  font-size: 13px;
  font-weight: 400;
  color: #606060;
  line-height: 20px;
}
.service-page .service-info-contant-item .service-item-flooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.service-page .service-info-contant-item .service-item-flooter-left {
  display: flex;
  align-items: center;
}
.service-page .service-info-contant-item .service-item-flooter-left .img {
  width: 44px;
  margin-right: 6px;
}
.service-page .service-info-contant-item .service-item-flooter-left > span {
  font-size: 12px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 12px;
  width: 100px;
}
.service-page .service-info-contant-item .service-item-flooter-btn {
  width: 76px;
  height: 28px;
  line-height: 26px;
  font-size: 13px;
  border-radius: 14px;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  color: #ff4f4c;
  border: 1px solid #ff4f4c;
  background: #fff;
  position: relative;
  padding-left: 14px;
}
.service-page .service-info-contant-item .service-item-flooter-btn:hover {
  background: #ff4f4c;
  border: 1px solid #ff4f4c;
  color: #fff;
}
.service-page .service-info-contant-item .service-item-flooter-btn:hover i {
  color: #fff;
}
.service-page .service-info-contant-item .service-item-flooter-btn.active {
  background: #ff4f4c;
  border: 1px solid #ff4f4c;
  color: #fff;
  padding-left: 0;
}
.service-page .service-info-contant-item .service-item-flooter-btn > i {
  font-size: 10px;
  color: #ff4f4c;
  font-family: 'iconfont' !important;
  font-size: 15px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.service-page .service-info-contant-item .service-item-flooter-btn > i::before {
  content: '+';
  position: absolute;
  left: 15px;
  top: -1px;
}
.service-page .service-info-contant .condition-title {
  font-size: 18px;
  font-weight: 600;
  font-family: MicrosoftYaHei;
}
.service-page .service-info-step {
  margin-top: 32px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.service-page .service-info-step .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #ff4f4f;
  border-color: #ff4f4f;
}
.service-page .service-info-step .ant-steps-item-title:hover {
  color: #ff4f4f;
}
.service-page .service-info-step .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #ff4f4f;
}
.service-page .service-info-step .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ff4f4f;
}
.service-page .service-info-step .ant-steps-item-wait .ant-steps-item-icon:hover {
  border-color: #ff4f4f;
}
.service-page .service-info-step .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title:hover {
  color: #ff4f4f;
}
.service-page .service-info-step .step-box {
  width: 500px;
  margin: 0 auto;
}
.service-page .service-info-step > .btn {
  width: 200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
}
.service-page .service-other {
  background: #fff;
  border-radius: 10px;
  padding: 32px 72px 48px 72px;
  width: 1054px;
  border-radius: 2px;
  margin-top: 24px;
}
.service-page .service-other-process {
  width: 100%;
  height: 120px;
  margin-top: 40px;
}
.service-page .service-other-process > img {
  width: 100%;
  height: 75px;
}
.service-page .service-other-question {
  background: #fff;
  border-radius: 2px;
  height: 380px;
  width: 100%;
  overflow-y: auto;
  margin-top: 20px;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
.service-page .service-other-question::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
.service-page .service-other-question .question-desc {
  color: #9c9c9c;
  font-size: 14px;
  margin-top: -10px;
  line-height: 20px;
}
.service-page .service-other-question .service-page .service-other-question .question-desc {
  color: #9c9c9c;
  font-size: 14px;
  /* margin-top: 14px; */
  line-height: 20px;
}
.service-page .service-other-question .red-text {
  color: #ff4f4c;
}
.service-page .service-floot {
  height: 126px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  box-shadow: 0 -2px 12px 0 hsla(0, 0%, 88%, 0.5);
  background-color: #fff;
}
.service-page .service-floot-cont {
  display: flex;
  justify-content: space-between;
  width: 1054px;
  margin: 0 auto;
}
.service-page .service-floot-cont-left {
  flex: 1 1;
  margin-right: 24px;
  margin-top: 24px;
  width: 600px;
}
.service-page .service-floot-cont-left .left-title {
  line-height: 29px;
  font-size: 13px;
  color: #404040;
}
.service-page .service-floot-cont-left .left-title > span {
  color: #ff4f4c;
}
.service-page .service-floot-cont-right {
  display: flex;
}
.service-page .service-floot-cont-right .oderCont {
  text-align: right;
  margin-top: 24px;
}
.service-page .service-floot-cont-right .oderCont .oder-cont-money {
  margin-top: 6px;
  line-height: 28px;
  font-size: 16px;
  color: #404040;
}
.service-page .service-floot-cont-right .oderCont .oder-cont-money > span {
  top: 2px;
  font-size: 28px;
  color: #ff4f4c;
  position: relative;
}
.service-page .service-floot-cont-right .oderCont .oder-cont-money > span > .pre-num {
  font-size: 14px;
  font-weight: 400;
  color: #9c9c9c;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  bottom: 4px;
}
.service-page .service-floot-cont-right .oderCont .use-coupons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #606060;
  line-height: 20px;
  cursor: pointer;
}
.service-page .service-floot-cont-right .oderCont .service-agreement {
  color: gray;
  line-height: 29px;
  font-size: 12px;
}
.service-page .service-floot-cont-right .oderCont .service-agreement > span {
  cursor: pointer;
}
.service-page .service-floot-cont-right .btnCont {
  width: 164px;
  height: 52px;
  line-height: 52px;
  background: #ff4f4c;
  border-radius: 4px;
  cursor: pointer;
  margin: 37px 0 0 22px;
  color: #fff;
  text-align: center;
  font-size: 18px;
}
.coupons-modal .ant-modal-header {
  padding: 16px 24px 10px 24px;
  border-radius: 4px 3px 0 0;
  border-bottom: 0px;
}
.coupons-modal .ant-modal-body {
  padding: 0 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.coupons-modal .ant-modal-footer {
  border-top: 0px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-modal .ant-modal {
  box-shadow: 0 4px 15px 0 #e8e8e8;
  border-radius: 6px;
}
.pay-modal .ant-modal-header {
  padding: 28px 24px 10px 24px;
  border-radius: 10px 10px 0 0;
  border-bottom: 0px;
}
.pay-modal .ant-modal-content {
  border-radius: 10px;
}
.pay-modal .pay-error {
  width: 223px;
  text-align: end;
  height: 14px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #f64;
  line-height: 46px;
}
.pay-modal .detail {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  width: 100%;
  background: #f9f9f9;
  border-radius: 8px;
  margin: 0 auto;
  padding: 24px;
  box-sizing: border-box;
  text-align: left;
}
.pay-modal .detail-title {
  width: 70px;
  height: 13px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 24px;
}
.pay-modal .detail-desc {
  width: 473px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #404040;
  line-height: 24px;
}
.pay-modal .contant {
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  padding-top: 24px;
}
.pay-modal .contant .pay {
  width: 100%;
  height: 174px;
  border-radius: 8px;
  margin-bottom: 4px;
  box-sizing: border-box;
  border: 1px solid #f5f6fa;
  padding: 24px;
  display: flex;
}
.pay-modal .contant .pay .Qrcode {
  width: 95px;
  height: 96px;
  margin-right: 18px;
  position: relative;
  top: 10px;
}
.pay-modal .contant .pay .Qrcode .Qr-mask {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 105px;
  height: 107px;
  background-color: rgba(98, 95, 95, 0.818);
  justify-content: center;
  padding-top: 40px;
  text-align: center;
}
.pay-modal .contant .pay .paycontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pay-modal .contant .pay .paycontent > p {
  display: flex;
  margin-bottom: 10px;
  color: #404040;
  font-size: 14px;
  align-items: flex-end;
}
.pay-modal .contant .pay .paycontent > p .count {
  font-size: 28px;
  font-weight: 600;
  color: #f64;
}
.pay-modal .contant .pay .pay-success-btn {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.pay-modal .contant .pay .pay-success-btn button {
  font-size: 18px;
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: radial-gradient(100% 100% at 100% 0%, #ff813a 0%, #ff3d3d 100%);
  box-shadow: 0px 0.01em 0.01em rgba(45, 35, 66, 0.4), 0px 0.3em 0.7em -0.01em rgba(45, 35, 66, 0.3), inset 0px -0.01em 0px rgba(58, 65, 111, 0.5);
  padding: 0 2em;
  border-radius: 0.3em;
  color: #fff;
  height: 2.6em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}
.pay-modal .contant .pay .pay-success-btn button:hover {
  box-shadow: 0px 0.1em 0.2em rgba(45, 35, 66, 0.4), 0px 0.4em 0.7em -0.1em rgba(45, 35, 66, 0.3), inset 0px -0.1em 0px #ff3d3d;
  transform: translateY(-0.1em);
}
.pay-modal .contant .pay .pay-success-btn button:active {
  box-shadow: inset 0px 0.1em 0.6em #ff3d3d;
  transform: translateY(0em);
}
.pay-modal .contant .pay-success {
  width: 100%;
  height: 200px;
}
.pay-modal .contant .pay-success .ant-result-success {
  padding: 0;
}
.pay-modal .contant .pay-success .ant-result-title {
  font-size: 20px;
}
.pay-modal .contant .pay-success .ant-result-success .ant-result-icon {
  margin-bottom: 5px;
}
.pay-modal .contant .pay-success .ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
  font-size: 25px;
}

@keyframes float-horizontal-67040540 {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes float-67040540 {
  0% {
    transform: rotate(0deg) translateY(0);
  }
  50% {
    transform: rotate(5deg) translateY(-10px);
  }
  to {
    transform: rotate(0deg) translateY(0);
  }
}
.before-fly {
  opacity: 0;
}
.after-fly {
  opacity: 1;
}
.fly-up {
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fly-up;
}
@keyframes fly-up {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.supervip {
  background-color: #050925;
  position: relative;
  height: 100%;
}
.supervip .container_supervip {
  display: flex;
  justify-content: center;
  position: relative;
  height: 14.0625rem;
}
.supervip .container_supervip .supervip_main {
  width: 63.75rem;
  z-index: 9;
  display: flex;
  justify-content: center;
  margin-top: 5.75rem;
}
.supervip .container_supervip .supervip_main .main_header {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 0;
  width: 20.5625rem;
  height: 4rem;
}
.supervip .container_supervip .supervip_main .main_header .main_icon img {
  width: 4rem;
  height: 4rem;
}
.supervip .container_supervip .supervip_main .main_header .main_header_title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.supervip .container_supervip .supervip_main .main_header .main_header_title span {
  font-size: 32px;
  font-weight: 800;
  color: #febd7d;
  line-height: 32px 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', Geneva, Verdana, sans-serif;
}
.supervip .container_supervip .supervip_main .main_header .main_header_title p {
  font-size: 16px;
  font-weight: 400;
  color: #ffb26a;
  line-height: 16px;
}
.supervip .mask-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 630px;
}
.supervip .mask-container .mask-layout {
  position: absolute;
  width: 100%;
}
.supervip .mask-container .vip_container {
  display: flex;
  justify-content: center;
  z-index: 9;
}
.supervip .mask-container .vip_container .vip_item_layout {
  width: 15rem;
  background-color: #050925;
  border-radius: 0.75rem;
  margin-right: 1.25rem;
  transition-duration: 0.5s;
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout {
  background: none;
  background-image: url(https://static.wondercv.com/cv_pc_vue/img/mountVip-yearVip-Bg.f7dec8d4.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  height: 11.5rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout .vip-name-text {
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.5625rem;
  font-weight: 700;
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout .vip_price_layout {
  display: flex;
  height: 2.875rem;
  align-items: flex-end;
  margin-top: 0.625rem;
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout .vip_price_layout .vip-unit-text {
  color: #fff;
  font-size: 0.875rem;
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout .vip_price_layout .vip-price-text {
  color: #fff;
  font-size: 2.875rem;
  align-items: flex-end;
  font-weight: 700;
  line-height: 1;
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout .vip-pre_price-text {
  font-size: 14px;
  color: hsla(0, 0%, 100%, 0.4);
}
.supervip .mask-container .vip_container .vip_item_layout .vip_item_child_layout .vip-btn-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.75rem;
  height: 2.25rem;
  background: #fff;
  border-radius: 1.25rem;
  font-size: 0.875rem;
  color: #1c1f3a;
  margin-top: 10px;
  cursor: pointer;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  height: 11.5rem;
  background: linear-gradient(130deg, #fff5e6, #e8c7a5);
  border-radius: 0.75rem 0.75rem 0 0;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip .vip-name-text {
  color: #84510f;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.5625rem;
  font-weight: 700;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip .vip_price_layout {
  display: flex;
  height: 2.875rem;
  align-items: flex-end;
  margin-top: 0.625rem;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip .vip_price_layout .vip-unit-text {
  color: #84510f;
  font-size: 0.875rem;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip .vip_price_layout .vip-price-text {
  color: #84510f;
  font-size: 2.875rem;
  align-items: flex-end;
  font-weight: 700;
  line-height: 1;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip .plus_vip_pre_price_layout {
  color: #84510f;
}
.supervip .mask-container .vip_container .vip_item_layout .plus_vip .plus_vip-btn-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.75rem;
  height: 2.25rem;
  background: #fff;
  border-radius: 1.25rem;
  font-size: 0.875rem;
  color: #84510f;
  margin-top: 10px;
  cursor: pointer;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container {
  padding-top: 2rem;
  border: 1px solid #dcdcdc;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .title_img {
  display: flex;
  justify-content: center;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .title_img img {
  width: 5.3125rem;
  height: 1rem;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .plus_limit_time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px 20px 18px;
  width: 9.9375rem;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .plus_limit_time .block_tag {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  padding: 2px 4px;
  margin-right: 4px;
  background: linear-gradient(132deg, #414761, #1c1e37);
  border-radius: 2px;
  font-size: 12px;
  color: #f9e2c9;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .plus_vip-desc-gold-text {
  color: #cfa980;
  font-size: 14px;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .hover_div {
  display: none;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .hover_div:hover {
  box-sizing: border-box;
  z-index: 6;
  position: absolute;
  cursor: pointer;
  top: 15px;
  left: 0;
  width: 206px;
  height: 86px;
  padding: 15px 0 0 30px;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .hover_div:hover ul li {
  margin-bottom: 10px;
  font-weight: 400;
  color: #c6884c;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .function_title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14px;
  margin-bottom: 24px;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .function_title .descript {
  font-size: 14px;
  font-weight: 500;
  color: #febd7d;
  margin: 0 4px;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .function_title .left,
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .function_title .right {
  width: 26px;
  height: 1px;
  background: linear-gradient(270deg, #d3d3d3, #fff);
  border-radius: 1px;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .function_title .right {
  background: linear-gradient(270deg, #fff, #d3d3d3);
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .vip-desc-layout {
  display: flex;
  justify-content: space-between;
  margin: 0 1.125rem 1.25rem 1.125rem;
  font-size: 0.875rem;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .vip-desc-layout .vip-desc-black-text {
  color: #cfcfcf;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .vip-desc-layout .vip-desc-gold-text {
  color: #c9a980;
}
.supervip .mask-container .vip_container .vip_item_layout .vip-desc-container .vip-desc-layout .vip-desc-gray-text {
  color: #cfcfcf6b;
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout {
  background: none;
  background-image: url(https://static.wondercv.com/cv_pc_vue/img/life_member_bg.80bd1a60.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15rem;
  height: 11.5rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout .vip-name-text {
  color: #fff;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-top: 1.5625rem;
  font-weight: 700;
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout .vip_price_layout {
  display: flex;
  height: 2.875rem;
  align-items: flex-end;
  margin-top: 0.625rem;
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout .vip_price_layout .vip-unit-text {
  color: #fff;
  font-size: 0.875rem;
  margin-bottom: 0.3125rem;
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout .vip_price_layout .vip-price-text {
  color: transparent;
  background: linear-gradient(132deg, #fff2e2, #e3c8a9);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 2.875rem;
  align-items: flex-end;
  font-weight: 700;
  line-height: 1;
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout .final_vip_pre_price_layout {
  font-size: 0.875rem;
  color: hsla(0, 0%, 100%, 0.4);
}
.supervip .mask-container .vip_container .vip_item_layout .final-member-layout .final-vip-btn-layout {
  background: linear-gradient(125deg, #fff5e8, #e3c8a9);
  color: #1d1f36;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12.75rem;
  height: 2.25rem;
  border-radius: 1.25rem;
  font-weight: 700;
  font-size: 0.875rem;
  margin-top: 10px;
  cursor: pointer;
}
.supervip .mask-container .vip_container .vip_item_layout:hover {
  transform: translateY(-20px);
  transition: all 0.3s;
  box-shadow: 0 6px 20px 5px rgba(27, 52, 196, 0.1), 0 16px 24px 2px rgba(0, 0, 0, 0.05);
}
.supervip .feature-container {
  height: 153.75rem;
}
.supervip .feature-container .rights-title {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.supervip .feature-container .rights-title .left {
  height: 21px;
  width: 209px;
}
.supervip .feature-container .rights-title .title {
  height: 37px;
  margin: 0 30px;
  width: 242px;
}
.supervip .feature-container .rights-title .right {
  height: 21px;
  width: 209px;
}
.supervip .feature-container .rights-content {
  height: 2460px;
  margin: 104px auto 0;
  position: relative;
  width: 1200px;
}
.supervip .feature-container .rights-content .rights-intelligent-title {
  height: 46px;
  left: 714px;
  position: absolute;
  top: 108px;
  width: 240px;
}
.supervip .feature-container .rights-content .rights-intelligent-desc {
  color: #ddd;
  font-size: 20px;
  left: 720px;
  line-height: 36px;
  position: absolute;
  top: 178px;
  width: 480px;
}
.supervip .feature-container .rights-content .rights-intelligent-desc .bg {
  height: 30px;
  left: -6px;
  position: absolute;
  top: 2px;
  width: 128px;
}
.supervip .feature-container .rights-content .rights-intelligent-bg .bg {
  height: 430px;
  left: 0;
  position: absolute;
  top: 0;
  width: 610px;
  z-index: 0;
}
.supervip .feature-container .rights-content .rights-intelligent-bg .float1 {
  animation: float-horizontal-67040540 7s ease-in-out infinite paused;
  animation-play-state: running;
  height: 50px;
  left: 30px;
  position: absolute;
  top: 45px;
  width: 230px;
  z-index: 1;
}
.supervip .feature-container .rights-content .rights-intelligent-bg .float2 {
  animation: float-67040540 7s ease-in-out infinite paused;
  animation-play-state: running;
  height: 40px;
  left: 514px;
  position: absolute;
  top: 297px;
  width: 40px;
  z-index: 1;
}
.supervip .feature-container .rights-content .rights-1-title {
  height: 46px;
  left: 30px;
  position: absolute;
  top: 558px;
  width: 240px;
}
.supervip .feature-container .rights-content .rights-1-bg {
  height: 547px;
  position: absolute;
  right: 0;
  top: 450px;
  width: 741px;
}
.supervip .feature-container .rights-content .rights-1-bg .bg {
  height: 547px;
  width: 741px;
}
.supervip .feature-container .rights-content .rights-1-bg .bg-video {
  background: #fff;
  border-radius: 12px;
  height: 127px;
  position: absolute;
  right: 24px;
  top: 50px;
  width: 215px;
}
.supervip .feature-container .rights-content .rights-1-desc {
  color: #ddd;
  font-size: 20px;
  left: 36px;
  line-height: 36px;
  position: absolute;
  top: 628px;
  width: 450px;
}
.supervip .feature-container .rights-content .rights-1-desc .bg {
  height: 30px;
  left: -6px;
  position: absolute;
  top: 3px;
  width: 128px;
}
.supervip .feature-container .rights-content .rights-2-title {
  height: 46px;
  left: 714px;
  position: absolute;
  top: 1026px;
  width: 240px;
}
.supervip .feature-container .rights-content .rights-2-bg {
  height: 430px;
  left: 0;
  position: absolute;
  top: 900px;
  width: 610px;
}
.supervip .feature-container .rights-content .rights-2-bg .bg {
  height: 100%;
  width: 100%;
}
.supervip .feature-container .rights-content .rights-2-bg .bg-video {
  background: #fff;
  border-radius: 14px;
  height: 248px;
  left: 113px;
  position: absolute;
  top: 110px;
  width: 432px;
}
.supervip .feature-container .rights-content .rights-2-bg .float {
  animation: float-horizontal-67040540 12s ease-in-out infinite paused;
  animation-play-state: running;
  height: 40px;
  left: 84px;
  position: absolute;
  top: 343px;
  width: 490px;
}
.supervip .feature-container .rights-content .rights-2-desc {
  color: #ddd;
  font-size: 20px;
  line-height: 36px;
  position: absolute;
  right: 30px;
  top: 1096px;
  width: 450px;
}
.supervip .feature-container .rights-content .rights-2-desc .bg {
  height: 30px;
  left: -6px;
  position: absolute;
  top: 3px;
  width: 128px;
}
.supervip .feature-container .rights-content .rights-column-title {
  height: 46px;
  left: 30px;
  position: absolute;
  top: 1449px;
  width: 240px;
}
.supervip .feature-container .rights-content .rights-column-desc {
  color: #ddd;
  font-size: 20px;
  left: 36px;
  line-height: 36px;
  position: absolute;
  top: 1519px;
  width: 480px;
}
.supervip .feature-container .rights-content .rights-column-desc .bg {
  height: 30px;
  left: -6px;
  position: absolute;
  top: 3px;
  width: 128px;
}
.supervip .feature-container .rights-content .rights-column-bg {
  height: 430px;
  position: absolute;
  right: 0;
  top: 1350px;
  width: 610px;
}
.supervip .feature-container .rights-content .rights-column-bg .bg {
  height: 100%;
  width: 100%;
}
.supervip .feature-container .rights-content .rights-column-bg .float {
  position: absolute;
  width: 191px;
  height: 257px;
  top: 136px;
  right: 416px;
  animation: float-horizontal-67040540 7s ease-in-out infinite;
}
.supervip .feature-container .rights-content .rights-3-title {
  height: 46px;
  left: 714px;
  position: absolute;
  top: 1944px;
  width: 240px;
}
.supervip .feature-container .rights-content .rights-3-bg {
  height: 582px;
  left: 0;
  position: absolute;
  top: 1789px;
  width: 722px;
}
.supervip .feature-container .rights-content .rights-3-bg .bg {
  height: 100%;
  width: 100%;
}
.supervip .feature-container .rights-content .rights-3-bg .bg-1 {
  animation: float-horizontal-67040540 7s ease-in-out infinite paused;
  animation-play-state: running;
  height: 230px;
  left: 96px;
  position: absolute;
  top: 101px;
  width: 514px;
}
.supervip .feature-container .rights-content .rights-3-desc {
  color: #ddd;
  font-size: 20px;
  left: 720px;
  line-height: 36px;
  position: absolute;
  top: 2014px;
  width: 450px;
}
.supervip .feature-container .rights-content .rights-3-desc .bg {
  height: 30px;
  left: -6px;
  position: absolute;
  top: 3px;
  width: 128px;
}

.container_sum {
  background-image: url(https://files.wondercv.com/pc/member/bottom-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 15.75rem;
  position: relative;
}
.container_sum .sum_main {
  position: relative;
  top: 3rem;
  width: 43.75rem;
  height: 9.75rem;
  margin: 0 auto;
}
.container_sum .sum_main .main_intro {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.container_sum .sum_main .main_intro .intro_item {
  width: 9.25rem;
  height: 3.5rem;
  text-align: center;
}
.container_sum .sum_main .main_intro .intro_item p:first-child {
  height: 2.0625rem;
  line-height: 2.0625rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #f9f9f9;
}
.container_sum .sum_main .main_intro .intro_item p:last-child {
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: #f9f9f9;
}
.container_sum .sum_main .main_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.375rem auto;
  width: 15rem;
  height: 3.875rem;
  border-radius: 1.9375rem;
  background-image: linear-gradient(90deg, #f64, #ff9f79);
  cursor: pointer;
}
.container_sum .sum_main .main_btn p {
  font-size: 1.25rem;
  color: #f9f9f9;
}

.vip-modal .ant-modal {
  box-shadow: 0 4px 15px 0 #e8e8e8;
  border-radius: 20px;
}
.vip-modal .ant-modal-header {
  padding: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: 0px;
}
.vip-modal .ant-modal-header .vip-model-header {
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  background-image: url(https://static.nowcoder.com/fe/file/oss/1660807988193WFMQM.png);
  background-size: 100% 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 116px;
  padding-left: 30px;
  padding-top: 24px;
  align-items: center;
}
.vip-modal .ant-modal-header .vip-model-header .vip-modal-header-v {
  display: flex;
}
.vip-modal .ant-modal-header .vip-model-header .el-image {
  margin-right: 8px;
}
.vip-modal .ant-modal-header .vip-model-header .tw-text-whitle {
  color: #fff;
}
.vip-modal .ant-modal-header .vip-model-header .tw-text-whitle .tw-text-name {
  font-size: 16px;
  line-height: 24px;
}
.vip-modal .ant-modal-header .vip-model-header .tw-text-whitle .tw-text-desc {
  font-size: 12px;
  line-height: 18px;
}
.vip-modal .ant-modal-content {
  border-radius: 20px;
}
.vip-modal .ant-modal-content .ant-modal-close-x {
  color: #fff;
}
.vip-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}
.vip-modal .ant-modal-content .vip-dialog-body {
  border-radius: 20px;
  margin-top: -20px;
  position: relative;
  box-sizing: border-box !important;
  height: 455px !important;
  padding: 16px;
  background-color: #f5f6fa;
  display: flex;
  justify-content: space-between;
}
.vip-modal .ant-modal-content .vip-content-left {
  flex-direction: column;
  width: 608px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  height: 191px;
  padding: 25px 16px 16px 16px;
  background: #fff;
  border-radius: 8px;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item {
  width: 110px;
  height: 150px;
  background: #f5f6fa;
  border-radius: 8px;
  color: #404040;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .position-bottom {
  width: 110px;
  height: 28px;
  border-radius: 0 0 8px 8px;
  bottom: 0;
  webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: linear-gradient(132deg, #fff2e2, #e3c8a9);
  color: #3b3f57;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .recommend-final-vip-layout {
  box-sizing: border-box;
  background: linear-gradient(132deg, #fff2e2, #e3c8a9);
  color: #3b3f57;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  width: 43px;
  height: 22px;
  border-radius: 10px 0 10px 0;
  top: -12px;
  left: 0;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item > .time {
  height: 14px;
  margin-top: 28px;
  line-height: 14px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #404040;
  cursor: pointer;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item > .time.active {
  color: #efdfc9;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .pic-container {
  display: flex;
  justify-content: center;
  height: 24px !important;
  margin-bottom: 12px;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .pic-tip {
  padding-top: 4px;
  padding-right: 4px;
  font-size: 14px;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .pic-text {
  line-height: 24px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .old-pic {
  display: flex;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 12px;
  font-size: 12px;
  color: #9c9c9c;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item.active {
  background: #272742;
  color: #efdfc9 !important;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item:last-child {
  box-sizing: border-box;
  width: 210px;
  border: 1px solid #d6a77a;
  background: #fcf7f2;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item:last-child.active {
  background: #272742;
  color: #efdfc9 !important;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .pic-explain {
  height: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #c6884c;
  line-height: 12px;
  margin-top: 11px;
}
.vip-modal .ant-modal-content .vip-content-left .vip-options-item .pic-explain.active {
  color: #efdfc9;
}
.vip-modal .ant-modal-content .vip-content-left-bottom {
  height: 216px;
  box-sizing: border-box;
  background: linear-gradient(44deg, #fff6f4, #fff);
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 0 20px 0;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .vip-icon-container {
  display: flex;
  flex-flow: wrap;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .kind.opcity {
  opacity: 0.4;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .kind .div-img {
  display: flex;
  justify-content: center;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .kind .div-img > img {
  width: 50px;
  height: 50px;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .kind > p {
  display: block;
  height: 14px;
  width: 100px !important;
  margin: 4px 0;
  font-weight: 500;
  color: #404040;
  text-align: center;
  transform: scale(0.8);
}
.vip-modal .ant-modal-content .vip-content-left-bottom .kind > span {
  display: block;
  width: 100%;
  text-align: center;
  transform: scale(0.7);
  font-size: 8px;
  font-weight: 400;
  color: #9c9c9c;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container {
  display: flex;
  align-items: center;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container .left {
  width: 162px;
  height: 186px;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container .left > img {
  width: 100%;
  height: 100%;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container .and {
  margin: 0 32px 0 31px;
  height: 20px;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container .and > img {
  width: 100%;
  height: 100%;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container .right {
  width: 308px;
  height: 174px;
}
.vip-modal .ant-modal-content .vip-content-left-bottom .svip-container .right > img {
  width: 100%;
  height: 100%;
}
.vip-modal .ant-modal-content .vip-content-right {
  border-radius: 8px;
  height: 423px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed {
  position: relative;
  width: 182px;
  height: 423px;
  background: #fff;
  border-radius: 8px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-title {
  width: 182px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  font-style: 14px;
  font-weight: 400;
  color: #282b42;
  background: #ecedf2;
  border-radius: 8px 8px 0 0;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-comps {
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  height: 32px;
  width: 70px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-comps > a {
  text-decoration: underline;
  color: #f64;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-QRcode {
  width: 112px;
  height: 112px;
  position: relative;
  margin: 16px auto 24px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-QRcode .Qrcode {
  width: 120px;
  height: 121px;
  margin-right: 18px;
  position: relative;
  top: 10px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-QRcode .Qrcode .Qr-mask {
  position: absolute;
  top: -5px;
  left: -5px;
  width: 130px;
  height: 132px;
  background-color: rgba(98, 95, 95, 0.818);
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success {
  width: 100%;
  height: 200px;
  padding: 80px 16px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success .ant-result-success {
  padding: 0;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success .ant-result-title {
  font-size: 20px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success .ant-result-success .ant-result-icon {
  margin-bottom: 5px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success .ant-result-success .ant-result-icon > .anticon {
  color: #52c41a;
  font-size: 25px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-type {
  display: flex;
  flex-direction: column;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-type-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-type-img > img {
  width: 19px;
  height: 18px;
  margin-right: 10px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-type-img .pay-icon {
  margin-top: 10px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-price {
  text-align: center;
  font-size: 24px;
  color: #f64;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 16px;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success-btn {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success-btn button {
  font-size: 18px;
  display: inline-block;
  outline: 0;
  border: 0;
  cursor: pointer;
  will-change: box-shadow, transform;
  background: radial-gradient(100% 100% at 100% 0%, #ff813a 0%, #ff3d3d 100%);
  box-shadow: 0px 0.01em 0.01em rgba(45, 35, 66, 0.4), 0px 0.3em 0.7em -0.01em rgba(45, 35, 66, 0.3), inset 0px -0.01em 0px rgba(58, 65, 111, 0.5);
  padding: 0 2em;
  border-radius: 0.3em;
  color: #fff;
  height: 2.6em;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.15s ease, transform 0.15s ease;
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success-btn button:hover {
  box-shadow: 0px 0.1em 0.2em rgba(45, 35, 66, 0.4), 0px 0.4em 0.7em -0.1em rgba(45, 35, 66, 0.3), inset 0px -0.1em 0px #ff3d3d;
  transform: translateY(-0.1em);
}
.vip-modal .ant-modal-content .vip-content-right .pay-fixed .pay-success-btn button:active {
  box-shadow: inset 0px 0.1em 0.6em #ff3d3d;
  transform: translateY(0em);
}

.mid-content4 {
  padding-top: 180px;
  background: #fff8f8;
  width: 100%;
}
.mid-content4-title {
  line-height: 34px;
  margin-bottom: 130px;
  font-weight: 700;
  color: #404040;
  text-align: center;
  font-size: 42px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
}
.mid-content4-card {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 581px;
}
.mid-content4-card .lists {
  height: 463px;
  padding: 40px 24px 24px;
  background: #fff;
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 70px;
  cursor: pointer;
  box-sizing: border-box;
}
.mid-content4-card .lists .empty-button {
  height: 10px;
  width: 280px;
}
.mid-content4-card .lists .content {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
  transition: all 0.5s;
}
.mid-content4-card .lists .content .lottie-anima {
  width: 120px;
  height: 120px;
}
.mid-content4-card .lists .content .head {
  font-size: 24px;
  font-weight: 500;
  color: #404040;
  line-height: 26px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.mid-content4-card .lists .content .head .money {
  color: #ff4f4f;
  font-size: 14px;
  position: relative;
  top: 5px;
  left: 2px;
  margin-left: 5px;
  font-weight: 600;
}
.mid-content4-card .lists .content .head .num {
  width: 52px;
  height: 26px;
  font-weight: 500;
  font-size: 26px;
  color: #ff4f4f;
}
.mid-content4-card .lists .content .listcontent {
  font-size: 14px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 22px;
  margin-bottom: 22px;
}
.mid-content4-card .lists .content .advantage {
  width: 100%;
  height: 136px;
}
.lists {
  height: 463px;
}
.lists .list-buttons {
  width: 280px;
  border-radius: 25px;
  box-sizing: border-box;
  border: 1px solid;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 14px;
  color: #fff;
  background: #ff4f4f;
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  visibility: hidden;
}
.lists .list-buttons:hover {
  background: hsl(27, 100%, 50%);
  transition: 0.6s;
}
.lists .list-havebuy {
  font-size: 14px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 20px;
}
.lists:hover {
  box-shadow: 0 10px 36px 0 hsla(0, 0%, 87%, 0.5);
}

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '\601D\6E90\9ED1\4F53   Medium';
  font-weight: 500;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/q7_PsTQyPvO135qdVeP8B.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/1blCc4F0p92H-ThpSMCxm.woff') format('woff');
  font-display: swap;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'FONT-Family';
  font-weight: 700;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/f_5ND-JUB7SPEqC3nUgu4.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/NZhs-WFqGQNn0l5EF-Pf-.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: MicrosoftYaHei;
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/IugGDA0sxXGyx6Me8DftE.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/bbkOTmCuycOXH0W885hQo.woff') format('woff');
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '\963F\91CC\5988\5988\6570\9ED1\4F53   Bold';
  font-weight: 700;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/C443pzCX73qc.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/c4Kk7dzkelPm.woff') format('woff');
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '\963F\91CC\5988\5988\6570\9ED1\4F53  2';
  font-weight: 700;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/KxijwPQqCeMw.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/rI4rAQzvnYFk.woff') format('woff');
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '\4ED3\8033\6E14\9633\4F53   W04';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/2NzepqxsFPak.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/8yCPNLzipYTJ.woff') format('woff');
  font-display: swap;
}
.home .brand {
  width: 100%;
  height: calc(100vw / 16 * 9);
  overflow-y: hidden;
  color: #fff;
  background: url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/home-banner.png?sign=4a3d64265c396b94b4a9a5be653a4fe6&t=1634014076) no-repeat 50% / cover;
  position: relative;
}
.home .brand .banner-bg {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  object-fit: unset;
  display: block;
}
.home .brand > .bg-info {
  z-index: 2;
  position: absolute;
  top: 25%;
}
.home .brand > .bg-info .name {
  font-size: 66px;
  font-family: '\963F\91CC\5988\5988\6570\9ED1\4F53  2';
  text-align: left;
  color: #fff;
  line-height: 90px;
  letter-spacing: 5px;
}
.home .brand > .bg-info .name > span {
  display: inline-block;
}
.home .brand > .bg-info .slogon {
  font-size: 66px;
  font-family: '\4ED3\8033\6E14\9633\4F53   W04';
  text-align: left;
  color: #fff;
  line-height: 90px;
  letter-spacing: 5px;
}
.home .brand > .bg-info .slogon > span {
  display: inline-block;
}
.home .brand > .bg-info .animation-item {
  transform: translateY(50%);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1), opacity 0.3s cubic-bezier(0.67, 0.19, 0.94, 0.81);
}
.home .brand > .bg-info .btns {
  display: flex;
  margin-top: 30px;
}
.home .brand > .bg-info .btns .realization {
  cursor: pointer;
  width: 194px;
  height: 50px;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  text-align: left;
  color: #fff;
  line-height: 22px;
  letter-spacing: 1px;
}
.home .brand > .bg-info .btns .to-ad {
  cursor: pointer;
  margin-left: 24px;
  width: 194px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  text-align: left;
  color: #fff;
  line-height: 22px;
  letter-spacing: 1px;
}
.home .brand > .bg-info .description {
  margin-top: 27px;
  width: 410px;
  font-size: 10px;
  font-family: MicrosoftYaHei;
  text-align: left;
  color: #fff;
  line-height: 24px;
}

.home-main {
  /* banner 区域 */
  /* 案例 */
}
.home-main .fd-content {
  min-width: 1216px;
  background: #fff;
  font-family: PingFangSC, Helvetica Neue, Helvetica, PingFang SC-Light, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
}
.home-main h2,
.home-main h3,
.home-main h4,
.home-main h5 {
  font-family: 'YouSheBiaoTiHei';
  font-weight: normal;
  font-style: normal;
}
.home-main .home-layout-wrapper {
  width: 100%;
  height: 750px;
  text-align: center;
  overflow: hidden;
}
.home-main .home-layout-wrapper .home-layout {
  margin: 0 auto;
  width: 1340px;
  padding: 0 24px;
  overflow: hidden;
  height: 100%;
}
.home-main .home-layout-wrapper .home-layout > div {
  float: left;
  width: 100%;
}
.home-main .home-layout-wrapper .home-layout .col .content-wrapper {
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
.home-main .home-layout-wrapper:nth-of-type(odd) {
  background: #fff;
}
.home-main .home-layout-wrapper:nth-of-type(even) {
  background-color: #f7f7f7;
}
.home-main h2,
.home-main h3 {
  text-align: center;
  font-weight: normal;
}
.home-main h2 {
  font-size: 30px;
  line-height: 38px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 80px;
}
.home-main h3 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.85);
}
.home-main .line {
  width: 40px;
  height: 2px;
  display: block;
  background: #ffbf00;
  margin: 16px auto 72px;
}
.home-main .home-case-wrapper .col .content-wrapper {
  height: 360px;
  padding: 16px;
}
.home-main .home-case-wrapper .col .content-wrapper .ss {
  display: flex;
  direction: row;
}
.home-main .home-case-wrapper .col .content-wrapper .image {
  width: 80%;
  height: 80%;
  border-radius: 2px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-main .home-case-wrapper .col .content-wrapper:hover .code-wrapper {
  opacity: 1;
}
.home-main .home-case-wrapper .col .code-wrapper {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.home-main .home-case-wrapper .col .code-wrapper canvas {
  height: 160px;
  width: 160px;
  margin-left: 32px;
}
.home-main .home-case-wrapper .col .code-wrapper h4 {
  font-size: 16px;
  font-weight: normal;
  margin: 72px auto 12px;
  color: rgba(0, 0, 0, 0.85);
}
.home-main .home-serve-wrapper {
  height: 788px;
  text-align: center;
}
.home-main .home-serve-wrapper .col {
  font-size: 16px;
}
.home-main .home-serve-wrapper .col .content-wrapper {
  padding: 24px 16px;
  height: 440px;
}
.home-main .home-serve-wrapper .col .content-wrapper .icon-hover {
  opacity: 0;
  transition: opacity 0.45s;
}
.home-main .home-serve-wrapper .col .content-wrapper:hover .icon-hover {
  opacity: 1;
}
.home-main .home-serve-wrapper .col .content-wrapper:hover p {
  color: rgba(0, 0, 0, 0.65);
}
.home-main .home-serve-wrapper .col .image {
  width: 32px;
  height: 32px;
  margin: 8px auto 12px;
}
.home-main .home-serve-wrapper .col h3 {
  margin-bottom: 24px;
}
.home-main .home-serve-wrapper .col p {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.43);
  transition: color 0.3s;
  font-weight: 400;
}
.home-main .home-serve-wrapper .col .exp {
  position: absolute;
  bottom: 24px;
  left: 0;
  width: 100%;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25);
}
.home-main .home-user-wrapper {
  height: 440px;
}
.home-main .home-user-wrapper h2 {
  margin-top: 64px;
}
.home-main .home-user-wrapper .col {
  margin-bottom: 32px;
}
.home-main .home-user-wrapper .col i {
  display: block;
  width: 170px;
  height: 56px;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}
.device,
.device::before,
.device::after,
.device *,
.device *::before,
.device *::after {
  box-sizing: border-box;
  display: block;
}
.device {
  position: relative;
  transform: scale(1);
  z-index: 1;
}
.device .device-frame {
  z-index: 1;
}
.device .device-content {
  background-color: #fff;
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  position: relative;
}
.device-iphone-x {
  height: 868px;
  width: 428px;
}
.device-iphone-x .device-frame {
  background: #222;
  border-radius: 68px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 7px #e2e3e4;
  height: 868px;
  padding: 28px;
  width: 428px;
}
.device-iphone-x .device-content {
  border-radius: 40px;
  height: 812px;
  width: 375px;
}
.device-iphone-x .device-stripe::after,
.device-iphone-x .device-stripe::before {
  border: solid rgba(51, 51, 51, 0.25);
  border-width: 0 7px;
  content: '';
  height: 7px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
}
.device-iphone-x .device-stripe::after {
  top: 85px;
}
.device-iphone-x .device-stripe::before {
  bottom: 85px;
}
.device-iphone-x .device-header {
  background: #222;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 30px;
  left: 50%;
  margin-left: -102px;
  position: absolute;
  top: 28px;
  width: 204px;
}
.device-iphone-x .device-header::after,
.device-iphone-x .device-header::before {
  content: '';
  height: 10px;
  position: absolute;
  top: 0;
  width: 10px;
}
.device-iphone-x .device-header::after {
  background: radial-gradient(circle at bottom left, transparent 0, transparent 75%, #222 75%, #222 100%);
  left: -10px;
}
.device-iphone-x .device-header::before {
  background: radial-gradient(circle at bottom right, transparent 0, transparent 75%, #222 75%, #222 100%);
  right: -10px;
}
.device-iphone-x .device-sensors::after,
.device-iphone-x .device-sensors::before {
  content: '';
  position: absolute;
}
.device-iphone-x .device-sensors::after {
  background: #444;
  border-radius: 3px;
  height: 6px;
  left: 50%;
  margin-left: -25px;
  top: 32px;
  width: 50px;
}
.device-iphone-x .device-sensors::before {
  background: #444;
  border-radius: 50%;
  height: 14px;
  left: 50%;
  margin-left: 40px;
  top: 28px;
  width: 14px;
}
.device-iphone-x .device-btns {
  background: #c8cacb;
  height: 32px;
  left: -3px;
  position: absolute;
  top: 115px;
  width: 3px;
}
.device-iphone-x .device-btns::after,
.device-iphone-x .device-btns::before {
  background: #c8cacb;
  content: '';
  height: 62px;
  left: 0;
  position: absolute;
  width: 3px;
}
.device-iphone-x .device-btns::after {
  top: 60px;
}
.device-iphone-x .device-btns::before {
  top: 140px;
}
.device-iphone-x .device-power {
  background: #c8cacb;
  height: 100px;
  position: absolute;
  right: -3px;
  top: 200px;
  width: 3px;
}
.home-main .home-layout-wrapper .home-layout {
  margin: 0 auto;
  width: 1340px;
  padding: 0 24px;
  overflow: hidden;
  height: 750px;
}
.device-iphone-x {
  height: 521px;
  width: 257px;
}
.device-iphone-x .device-frame {
  background: #222;
  border-radius: 43px;
  box-shadow: inset 0 0 2px 1px #c8cacb, inset 0 0 0 5px #e2e3e4;
  height: 521px;
  padding: 28px;
  width: 257px;
}
.device-iphone-x .device-header {
  background: #222;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 20px;
  left: 50%;
  margin-left: -59px;
  position: absolute;
  top: 15px;
  width: 122px;
}
.device-iphone-x .device-btns {
  background: #c8cacb;
  height: 19px;
  left: -3px;
  position: absolute;
  top: 80px;
  width: 3px;
}
.device-iphone-x .device-power {
  background: #c8cacb;
  height: 60px;
  position: absolute;
  right: -3px;
  top: 120px;
  width: 3px;
}
.device-iphone-x .device-content {
  border-radius: 30px;
  height: 471px;
  width: 228px;
  margin-left: -13px;
  margin-top: -9px;
}
.device-iphone-x .device-btns::after,
.device-iphone-x .device-btns::before {
  background: #c8cacb;
  content: '';
  height: 45px;
  left: 0;
  position: absolute;
  width: 3px;
  top: 95px;
}
.device-iphone-x .device-btns::after {
  top: 36px;
}
.device-iphone-x .device-stripe::after,
.device-iphone-x .device-stripe::before {
  border: solid rgba(51, 51, 51, 0.25);
  border-width: 0 7px;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
}
.device-iphone-x .device-stripe::after {
  top: 53px;
}
.device-iphone-x .device-stripe::before {
  bottom: 53px;
}
.device-iphone-x .device-sensors::before {
  background: #444;
  border-radius: 50%;
  height: 10px;
  left: 48%;
  margin-left: 38px;
  top: 17px;
  width: 10px;
}
.device-iphone-x .device-sensors::after {
  background: #444;
  border-radius: 3px;
  height: 4px;
  left: 50%;
  margin-left: -13px;
  top: 20px;
  width: 34px;
}
.home-main .home-case-wrapper .col .code-wrapper h4 {
  font-size: 16px;
  font-weight: normal;
  margin: 72px auto 12px;
  margin-top: 120px;
  color: rgba(0, 0, 0, 0.85);
}
.home-main .home-layout-wrapper .home-layout {
  margin: 0 auto;
  width: 1440px;
  padding: 0 0px;
  overflow: hidden;
  height: 733px;
}
.home-main .home-layout-wrapper .home-layout .col .content-wrapper {
  background: #fff;
  height: 490px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 26px;
  padding: 0px;
  top: 0px;
}
.home-main .home-case-wrapper .col .content-wrapper .image {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-wrapper .home-page {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: #000;
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
}
.feature6-title-text.active {
  color: #ff6600;
}
.feature6-title-bar {
  height: 6px;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #ff3d3d;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #ff813a;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(0, 0, 0, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}

.teach-Video-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.teach-Video-wrapper .teach-Video {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.teach-Video-wrapper .title-wrapper > h1,
.teach-Video-wrapper > h1 {
  font-size: 42px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  color: #f64;
  margin-bottom: 16px;
}
.teach-Video-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.teach-Video-wrapper .title-wrapper .title-content {
  color: #cfcfcf;
  font-size: 16px;
}
.teach-Video {
  padding: 85px 24px 240px 24px;
}
.content4-wrapper {
  min-height: 720px;
  background: url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/home-banner.png?sign=4a3d64265c396b94b4a9a5be653a4fe6&t=1634014076) no-repeat 50% / cover;
}
.content4-wrapper .content4 {
  height: 100%;
  overflow: hidden;
}
.content4-wrapper .content4-video {
  border-radius: 15px;
  overflow: hidden;
  max-width: 900px;
  margin: auto;
  background: #fff;
  box-shadow: 0 3px 19px rgba(255, 255, 255, 0.75);
}
.content4-wrapper .content4-video video {
  display: block;
  margin: auto;
}
@media screen and (max-width: 767px) {
  .content4-wrapper {
    min-height: 350px;
  }
  .content4-wrapper .content4 {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .content4-wrapper .content4-video {
    top: 15%;
    background: url('https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg') no-repeat center;
    background-size: cover;
  }
}

.userfeedback-wrapper {
  width: 100%;
  position: relative;
}
.userfeedback-wrapper .userfeedback {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.userfeedback-wrapper .title-wrapper > h1,
.userfeedback-wrapper > h1 {
  font-size: 42px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  color: #f64;
  margin-bottom: 16px;
}
.userfeedback-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.userfeedback-wrapper .title-wrapper .title-content {
  color: #9c9c9c;
  font-size: 22px;
}
.userfeedback {
  padding: 108px 24px;
}
.userfeedback-wrapper {
  min-height: 720px;
  background: #fff;
}
.userfeedback-wrapper .userfeedback {
  height: 100%;
}
.userfeedback-wrapper .userfeedback-video {
  border-radius: 10px;
  max-width: 984px;
  margin: auto;
  background: #fff;
  transition: all 0.5s;
}
.userfeedback-wrapper .userfeedback-video video {
  display: block;
  margin: auto;
}
.userfeedback-wrapper .userfeedback-video:hover {
  box-shadow: 0 0 30px 0 rgba(0, 9, 85, 0.08);
}
.banner-user .banner-anim-arrow-default {
  position: absolute;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  top: 45%;
  margin: 0 auto;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 2;
  margin: 0 -120px;
}
.banner-user .banner-anim-arrow-default:hover {
  transition: all 1s;
  background: #f38404;
  box-shadow: 0 0 30px 0 rgba(0, 9, 85, 0.08);
}
.banner-user .banner-anim-arrow-default.next:before,
.banner-user .banner-anim-arrow-default.prev:before,
.banner-user .banner-anim-arrow-default.next:after,
.banner-user .banner-anim-arrow-default.prev:after {
  width: 2px;
  height: 15px;
  background: #fff;
  display: block;
  content: ' ';
  position: absolute;
  margin-left: 18px;
}
@media screen and (max-width: 767px) {
  .userfeedback-wrapper {
    min-height: 350px;
  }
  .userfeedback-wrapper .userfeedback {
    overflow: hidden;
    width: 90%;
    margin: auto;
  }
  .userfeedback-wrapper .userfeedback-video {
    top: 15%;
    background: url('https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg') no-repeat center;
    background-size: cover;
  }
}
.user-feedback {
  height: 320px;
}
.banner-content {
  margin: 0 auto;
  height: 260px;
  padding: 50px 80px 60px;
}
.banner-content-top {
  display: flex;
  display: -webkit-box;
  margin-bottom: 20px;
}
.banner-content-top .info {
  display: flex;
  flex-direction: column;
}
.banner-content-top .info .info-name {
  font-size: 20px;
  font-weight: 400;
  color: #202329;
  line-height: 35px;
}
.banner-content-top .info .info-desc {
  font-size: 16px;
  font-weight: 400;
  color: #9c9c9c;
  line-height: 22px;
}
.banner-content-bottom {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  line-height: 28px;
}
.banner-user {
  height: 300px;
}
.banner-user-elem {
  color: black;
  position: relative;
  overflow: hidden;
}
.banner-user-elem .banner-user-title {
  font-size: 32px;
  top: 40%;
}
.banner-user-elem .banner-user-text {
  top: 40%;
}
.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.Startuse-home-page-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.Startuse-home-page-wrapper .Startuse-home-page {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.Startuse-home-page-wrapper .Startuse-title-wrapper {
  margin: 0 auto 45px;
  text-align: center;
}
.Startuse-home-page-wrapper .Startuse-title-wrapper > h1,
.Startuse-home-page-wrapper > h1 {
  font-size: 42px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  color: black;
  margin-bottom: 16px;
}
.content-button {
  letter-spacing: 0.5px;
}
.content-button .Startuse-button {
  width: 264px;
  height: 66px;
  line-height: 66px;
  text-align: center;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  box-shadow: 0 10px 18px -1px rgba(255, 94, 59, 0.35);
  border-radius: 33px;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  margin: 30px auto 112px;
  cursor: pointer;
}
.Startuse-button:hover {
  background: linear-gradient(90deg, #61ff3a, #3d91ff);
}
.Startuse-home-page {
  padding: 10px 24px;
}

.ProcessPC-wrapper {
  min-height: 450px;
  margin: 0 auto;
}
.ProcessPC-wrapper.home-page-wrapper .home-page {
  position: relative;
  width: 1200px;
  margin: 0 auto;
}
.ProcessPC-content-wrapper {
  background-color: #fff;
  height: 400px;
}
.ProcessPC-show {
  width: 1250px;
  height: 377px;
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  background: url(https://files.wondercv.com/homepage-pc/tab-shadow.png) no-repeat 0 0/100%;
  z-index: -1;
}
.ProcessPC-title {
  display: inline-block;
  position: relative;
  margin: 0 22px;
  text-align: center;
}
.ProcessPC-title-wrapper {
  min-width: 1000px;
  width: 1200px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 48px;
  position: absolute;
  height: 150px;
  background-color: #fff;
  top: -110px;
  border-radius: 10px 10px 0 0;
  padding-top: 28px;
}
.ProcessPC-title-image {
  position: absolute;
  justify-content: center;
}
.ProcessPC-title-text {
  display: inline-block;
  margin: 0 56px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
  transition: color 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  margin-bottom: 10px;
  color: #262a30;
  font-size: 20px;
  font-weight: 500;
}
.ProcessPC-title-text.active {
  color: #ff3d3d;
}
.ProcessPC-title-desc {
  color: #959ba3;
  font-size: 14px;
  font-weight: 400;
  padding-left: 38px;
}
.ProcessPC-title-desc.active {
  color: #ff4f4c;
}
.ProcessPC-title-bar {
  height: 3px;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  width: 40%;
  margin: auto;
  display: block;
}
.ProcessPC-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ProcessPC-number,
.ProcessPC-text {
  text-align: center;
}
.ProcessPC-number {
  font-size: 48px;
  color: #ff3d3d;
  font-weight: 600;
}
.ProcessPC-number-wrapper {
  text-align: center;
}
.ProcessPC-left-wrapper {
  text-align: center;
  padding-left: 20px;
}
.ProcessPC-left .imghvr-fold-right > img {
  transform-origin: 100% 50%;
}
.ProcessPC-left .imghvr-fold-right figcaption {
  transform: rotateY(-90deg) translate3d(50%, 0%, 0) scale(0.6);
  transform-origin: 0 50%;
}
.ProcessPC-left .imghvr-fold-right:hover > img {
  transform: rotateY(90deg) scale(0.6) translateX(-50%);
}
.ProcessPC-right {
  padding-left: 1px;
}
.ProcessPC-right-wrapper {
  text-align: center;
  letter-spacing: 0.5px;
}
.ProcessPC-right-wrapper-item {
  margin: 50px 0 0 0;
  display: flex;
  flex-direction: column;
}
.ProcessPC-right-wrapper-item-tag {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  color: #ff4f4c;
  width: 68px;
  height: 24px;
  line-height: 24px;
  background: #ffe6e6;
  border-radius: 12px;
  text-align: center;
  margin-bottom: 15px;
}
.ProcessPC-right-wrapper-item-title {
  font-size: 28px;
  font-weight: 400;
  color: #404040;
  line-height: 40px;
  text-align: left;
  margin-bottom: 15px;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
}
.ProcessPC-right-wrapper-item-content {
  height: auto;
}
.ProcessPC-right-wrapper-item-content > p {
  position: relative;
  width: 440px;
  color: #959ba3;
  text-align: left;
  font-size: 15px;
  font-weight: 400;
  padding-left: 20px;
  line-height: 23px;
  margin-bottom: 14px;
}
.ProcessPC-right-wrapper-item-content > p::before {
  position: absolute;
  content: '';
  left: 0;
  top: 8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #959ba3;
}
.ProcessPC-right-wrapper-item-Button {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  width: 220px;
  height: 49px;
  line-height: 49px;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-radius: 24px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 10px 18px -1px rgba(255, 94, 59, 0.35);
  transition: all 0.5s;
}
.ProcessPC-right-wrapper-item-Button:hover {
  box-shadow: 0 10px 18px -1px #959ba3;
  transform: scale(1.1);
}
.ProcessPC-text {
  font-size: 20px;
}
.ProcessPC-unit {
  font-size: 16px;
  color: #ff813a;
}
.ProcessPC .ant-carousel ƒ .slick-dots-bottom {
  bottom: -24px;
}
.ProcessPC .ant-carousel .slick-dots li button {
  background: rgba(0, 0, 0, 0.3);
}
.ProcessPC .ant-carousel .slick-dots li.slick-active button {
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
}
@media screen and (max-width: 767px) {
  .ProcessPC-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .ProcessPC-wrapper.home-page-wrapper .home-page {
    padding: 56px 24px 64px !important;
  }
  .ProcessPC-title-text {
    margin: 0 14px;
  }
  .ProcessPC-title-bar {
    width: 40%;
  }
  .ProcessPC-number-wrapper {
    margin-bottom: 16px;
  }
}
.title-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@font-face {
  font-family: '\601D\6E90\9ED1\4F53   Medium';
  font-weight: 500;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/q7_PsTQyPvO135qdVeP8B.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/1blCc4F0p92H-ThpSMCxm.woff') format('woff');
  font-display: swap;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: MicrosoftYaHei;
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/IugGDA0sxXGyx6Me8DftE.woff2') format('woff2'), url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/bbkOTmCuycOXH0W885hQo.woff') format('woff');
  font-display: swap;
}
.mid-content {
  padding-top: 70px;
  width: 988px;
  margin: 0 auto;
}
.mid-content .mid-tit {
  line-height: 42px;
  font-size: 30px;
  font-weight: 700;
  color: #404040;
  font-family: '\601D\6E90\9ED1\4F53   Medium' !important;
  text-align: center;
}
.mid-content .mid-list {
  margin: 77px 0 408px;
}
.mid-content .mid-list .clear-list {
  position: relative;
  width: 205px;
  padding: 13px 14px 0;
  height: 268px;
  box-shadow: 0 10px 36px 0 hsla(0, 0%, 87%, 0.5);
  border-radius: 4px;
  margin: 0 21px;
  background-color: #fff;
  float: left;
}
.mid-content .mid-list .clear-list:hover {
  box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
}
.mid-content .mid-list .clear-list:hover .card-button {
  transform: translate(-50%, 50%);
  opacity: 1;
}
.mid-content .mid-list .clear-list .lottie-anima,
.mid-content .mid-list .clear-list .pic {
  width: 177px;
  height: 104px;
}
.mid-content .mid-list .clear-list img {
  display: block;
}
.mid-content .mid-list .clear-list .f-tit {
  line-height: 18px;
  margin: 34px 0 17px;
  font-size: 18px;
  font-weight: 700;
  color: #404040;
  text-align: center;
}
.mid-content .mid-list .clear-list .text {
  line-height: 20px;
  margin: 0 2px;
  font-size: 14px;
  color: #606060;
}
.mid-content .mid-list .card-button {
  transform: translate(-50%, 125%);
  width: 60%;
  border-radius: 1rem;
  border: none;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  color: #fff;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  position: absolute;
  left: 50%;
  bottom: 0;
  opacity: 0;
  transition: 0.3s ease-out;
}

@font-face {
  font-family: 'FZJUNH_CUJW';
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
}
@font-face {
  font-family: 'MicrosoftYaHei';
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* @font-face { */
/*   font-family: "FZJUNH_CUJW"; */
/*   src: url("https://ali2.a.kwimgs.com/udata/pkg/ks-ad-fe/FZJHJW.ttf"); */
/* } */
body,
.app.pc {
  width: 100%;
}
.home::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
.home {
  height: auto;
  width: 100%;
  overflow: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}
.home::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
.home > *:nth-child(2) {
  min-height: calc(100vh - 190px);
}
.home .calculate-margin-left {
  margin-left: calc(80px + (100vw - 1440px) / 2);
}
.home .calculate-padding-left {
  padding-left: calc(80px + (100vw - 1440px) / 2);
}
.home .calculate-left {
  left: calc(40px + (100vw - 1440px) / 2);
}
.home .calculate-right {
  right: calc(40px + (100vw - 1440px) / 2);
}
@media (max-width: 1440px) {
  .home .calculate-margin-left {
    margin-left: 80px;
  }
  .home .calculate-padding-left {
    padding-left: 80px;
  }
  .home .calculate-left {
    left: 40px;
  }
  .home .calculate-right {
    right: 40px;
  }
}
.home figure {
  margin: 0;
}
.home .toTop {
  height: 90px;
  width: 90px;
  position: fixed;
  bottom: 59px;
  right: 9px;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.home .toTop.visible {
  opacity: 1;
}
.home .toTop img {
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 0.3s;
}
.home .fade-in {
  opacity: 0;
  transform: translate3d(0, 90px, 0);
  transition: opacity 1s, transform 1s;
}
.home .fade-in.visible {
  opacity: 1;
  transform: translateZ(0);
}
.home .fade-in-40 {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
  transition: opacity 1s, transform 1s;
}
.home .fade-in-40.visible {
  opacity: 1;
  transform: translateZ(0);
}
.home .animation-number {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 48px;
  overflow: hidden;
}
.home .animation-number div.number-box {
  transition: top 1s;
  width: 36px;
  position: relative;
  top: 0;
}
.home .animation-number div.number-box .num-item {
  width: 36px;
  height: 48px;
  text-align: center;
}
.home .animation-button {
  position: relative;
  overflow: hidden;
}
.home .animation-button > span {
  opacity: 0;
  transform: translate3d(0, -10px, 0);
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.home .animation-button::before {
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .animation-button:hover::before {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}
.home .animation-button:hover::after {
  opacity: 1;
}
.home .animation-button:not(:hover) > span {
  transition-delay: 0s !important;
}
.home .animation-button:hover > span {
  opacity: 1;
  transform: translateZ(0);
}
.home .solution {
  height: 500px;
}
.home .news {
  min-height: 100vh;
}
.home .news .news-banner {
  background-color: #607aab;
  text-align: center;
}
.home .news .news-banner img {
  width: auto;
  height: 360px;
}
.home .news .news-live {
  background-color: #080504;
  text-align: center;
  height: 891px;
  position: relative;
}
.home .news .news-live img {
  width: 1300px;
  height: 693px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-35%, 0);
}
.home .news .news-live iframe {
  border: none;
  position: relative;
  width: 1280px;
  height: 640px;
  margin-top: 140px;
}
.home .news .news-live video {
  object-fit: fill;
  position: relative;
  width: 1280px;
  height: 640px;
  margin-top: 140px;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  outline: none;
  background: black;
  text-decoration: none;
}
.home .news .ssp-tabs {
  margin-top: 28px;
}
.home .news .ssp-tabs .ant-tabs-ink-bar {
  background: #ff4906;
}
.home .news .ssp-tabs .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  line-height: 18px;
  letter-spacing: 0;
}
.home .news .ssp-tabs .ant-tabs-tab {
  color: #1f1f1f;
}
.home .news .ssp-tabs .ant-tabs-tab:hover {
  color: #ff4906;
}
.home .news .ssp-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  font-weight: 700;
  color: #1f1f1f;
  text-align: left;
  line-height: 18px;
  letter-spacing: 0;
}
.home .news .ssp-tabs .ant-tabs-nav::before {
  border: none;
}
.home .news .list-container {
  margin-top: 24px;
}
.home .news .list-container .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: -40px;
}
.home .policy {
  background: #f7f7f7;
}
.home .policy .policy-banner {
  text-align: center;
  background-size: auto 100%;
  min-width: 1280px;
  flex-direction: column;
  align-items: center;
  height: 360px;
  position: relative;
  overflow: hidden;
  background: url('https://ali2.a.kwimgs.com/udata/pkg/ks-ad-ssp-cdn/home/news-header-banner.jpg') no-repeat 50%;
  background-color: #080504;
  color: white;
}
.home .policy .policy-banner h1 {
  font-family: FZJUNH_CUJW;
  text-align: center;
  color: #fff;
  line-height: 48px;
  margin-top: 147px;
}
.home .policy .policy-banner p {
  font-size: 14px;
  text-align: center;
  color: #fff;
  line-height: 30px;
  letter-spacing: 0;
  margin-top: 8px;
}
.home .policy .ssp-tabs {
  margin-top: 28px;
}
.home .policy .ssp-tabs .ant-tabs-ink-bar {
  background: #ff4906;
}
.home .policy .ssp-tabs .ant-tabs-tab {
  color: #1f1f1f;
}
.home .policy .ssp-tabs .ant-tabs-tab:hover {
  color: #ff4906;
}
.home .policy .ssp-tabs .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: left;
  line-height: 18px;
  letter-spacing: 0;
}
.home .policy .ssp-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1f1f1f;
  font-weight: 700;
}
.home .policy .ssp-tabs .ant-tabs-nav::before {
  border: none;
}
.home .policy .list-container {
  margin-top: 24px;
}
.home .policy .list-container .list {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-left: -40px;
}
.home .detail {
  min-height: calc(100vh - 190px);
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 44px;
}
.home .detail .dt-header {
  padding-top: 20px;
  height: 150px;
}
.home .detail .dt-header h1 {
  margin-top: 24px;
  font-size: 36px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  font-weight: 700;
  text-align: center;
  color: #1a1e2a;
  line-height: 46px;
}
.home .detail .detail-content {
  margin-top: 48px;
  font-size: 16px;
  line-height: 1.8;
}
.home .detail .detail-content img {
  max-width: 100%;
}
.ssp-layout {
  width: 100%;
}
.ssp-layout .ssp-layout-innner {
  position: relative;
  margin: 0 auto;
  width: 1280px;
}
.report-card {
  cursor: pointer;
  margin-bottom: 72px;
  width: 400px;
  height: 426px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 16px 30px -4px rgba(0, 0, 0, 0.04);
  margin-left: 40px;
}
.report-card .card-pic {
  height: 225px;
  overflow: hidden;
}
.report-card .card-pic img {
  width: 100%;
}
.report-card .card-info {
  padding: 20px 30px;
}
.report-card .card-info .title {
  letter-spacing: 1px;
  line-height: 26px;
  color: #1f1f1f;
  text-align: left;
  font-weight: 700;
  font-size: 20px;
  height: 52px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.report-card .card-info .intro {
  margin-top: 16px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: left;
  color: #8c8c8c;
  line-height: 22px;
  letter-spacing: 0;
  overflow: hidden;
  height: 44px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.report-card .card-info .card-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
.report-card .card-info .card-footer a {
  font-size: 16px;
  font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
  font-weight: 700;
  text-align: left;
  color: #8c8c8c;
  line-height: 1;
  letter-spacing: 1px;
  position: relative;
}
.report-card .card-info .card-footer a span {
  vertical-align: middle;
  margin-right: 4px;
}
.report-card .card-info .card-footer a .arrow {
  display: inline-block;
  position: relative;
  width: 22px;
  height: 2px;
  z-index: 1;
  background-color: #8c8c8c;
}
.report-card .card-info .card-footer a .arrow::before,
.report-card .card-info .card-footer a .arrow::after {
  content: ' ';
  height: 2px;
  width: 7px;
  background-color: #8c8c8c;
  position: absolute;
  right: -2px;
  transition: all 0.2s;
}
.report-card .card-info .card-footer a .arrow::before {
  bottom: 2px;
  transform: rotate(45deg);
}
.report-card .card-info .card-footer a .arrow::after {
  top: 2px;
  transform: rotate(-45deg);
}
.report-card .card-info .card-footer .date {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: right;
  color: #8c8c8c;
  line-height: 22px;
  letter-spacing: 0;
}
.ssp-layout {
  width: 100%;
}
.ssp-layout .ssp-layout-innner {
  position: relative;
  margin: 0 auto;
  width: 1280px;
}

.success-main {
  width: 100%;
  height: 1000px;
  margin: 56px auto 0;
  box-sizing: border-box;
  position: relative;
}
.success-main .title {
  width: 100%;
  height: 600px;
  background: linear-gradient(180deg, #ecfafa, #f8f8f8);
  background-repeat: no-repeat;
  background-size: 100% 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;
  position: relative;
}
.success-main .title .icon {
  text-align: center;
  border-radius: 50%;
  margin-top: 32px;
  position: relative;
}
.success-main .title .icon > img {
  width: 45px;
  height: 45px;
}
.success-main .title .p-title-success {
  font-size: 24px;
  font-weight: 700;
  font-family: cursive;
}
.success-main .title .member-tips {
  opacity: 0.7;
  margin-top: 10px;
}
.success-main .title .member-img {
  width: 230px;
  height: 230px;
  margin: 32px auto 0;
  text-align: center;
  box-shadow: 0 6px 24px -4px #ecfafa;
}
.success-main .title .number-code-tips {
  font-size: 16px;
  margin-top: 16px;
  line-height: 26px;
  text-align: center;
  color: rgba(51, 51, 51, 0.7);
}
.success-main .title .member-desc {
  position: absolute;
  left: 50%;
  bottom: -125px;
  transform: translateX(-50%);
  width: 707px;
  background: #fff;
  box-shadow: 0 6px 48px 0 rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  margin-top: 20px;
}
.success-main .title .member-desc .vip-content-left {
  flex-direction: column;
  width: 608px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.success-main .title .member-desc .vip-content-left .vip-options {
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  height: 191px;
  padding: 25px 16px 16px 16px;
  background: #fff;
  border-radius: 8px;
}
.success-main .title .member-desc .vip-content-left .vip-options-item {
  width: 110px;
  height: 150px;
  background: #f5f6fa;
  border-radius: 8px;
  color: #404040;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .position-bottom {
  width: 110px;
  height: 28px;
  border-radius: 0 0 8px 8px;
  bottom: 0;
  webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: linear-gradient(132deg, #fff2e2, #e3c8a9);
  color: #3b3f57;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .recommend-final-vip-layout {
  box-sizing: border-box;
  background: linear-gradient(132deg, #fff2e2, #e3c8a9);
  color: #3b3f57;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  width: 43px;
  height: 22px;
  border-radius: 10px 0 10px 0;
  top: -12px;
  left: 0;
}
.success-main .title .member-desc .vip-content-left .vip-options-item > .time {
  height: 14px;
  margin-top: 28px;
  line-height: 14px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #404040;
  cursor: pointer;
}
.success-main .title .member-desc .vip-content-left .vip-options-item > .time.active {
  color: #efdfc9;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .pic-container {
  display: flex;
  justify-content: center;
  height: 24px !important;
  margin-bottom: 12px;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .pic-tip {
  padding-top: 4px;
  padding-right: 4px;
  font-size: 14px;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .pic-text {
  line-height: 24px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .old-pic {
  display: flex;
  font-weight: 300;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 12px;
  font-size: 12px;
  color: #9c9c9c;
}
.success-main .title .member-desc .vip-content-left .vip-options-item.active {
  background: #272742;
  color: #efdfc9 !important;
}
.success-main .title .member-desc .vip-content-left .vip-options-item:last-child {
  box-sizing: border-box;
  width: 210px;
  border: 1px solid #d6a77a;
  background: #fcf7f2;
}
.success-main .title .member-desc .vip-content-left .vip-options-item:last-child.active {
  background: #272742;
  color: #efdfc9 !important;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .pic-explain {
  height: 12px;
  font-size: 12px;
  font-weight: 600;
  color: #c6884c;
  line-height: 12px;
  margin-top: 11px;
}
.success-main .title .member-desc .vip-content-left .vip-options-item .pic-explain.active {
  color: #efdfc9;
}
.success-main .title .member-desc .vip-content-left-bottom {
  height: 216px;
  box-sizing: border-box;
  background: linear-gradient(44deg, #fff6f4, #fff);
  border-radius: 8px;
  overflow: hidden;
  padding: 20px 0 20px 0;
}
.success-main .title .member-desc .vip-content-left-bottom .vip-icon-container {
  display: flex;
  flex-flow: wrap;
}
.success-main .title .member-desc .vip-content-left-bottom .kind.opcity {
  opacity: 0.4;
}
.success-main .title .member-desc .vip-content-left-bottom .kind .div-img {
  display: flex;
  justify-content: center;
}
.success-main .title .member-desc .vip-content-left-bottom .kind .div-img > img {
  width: 50px;
  height: 50px;
}
.success-main .title .member-desc .vip-content-left-bottom .kind > p {
  display: block;
  height: 14px;
  width: 100px !important;
  margin: 4px 0;
  font-weight: 500;
  color: #404040;
  font-size: 14px;
  text-align: center;
  transform: scale(0.8);
}
.success-main .title .member-desc .vip-content-left-bottom .kind > span {
  display: block;
  width: 100%;
  text-align: center;
  transform: scale(0.7);
  font-size: 8px;
  font-weight: 400;
  color: #9c9c9c;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container {
  display: flex;
  align-items: center;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container .left {
  width: 162px;
  height: 186px;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container .left > img {
  width: 100%;
  height: 100%;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container .and {
  margin: 0 32px 0 31px;
  height: 20px;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container .and > img {
  width: 100%;
  height: 100%;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container .right {
  width: 308px;
  height: 174px;
}
.success-main .title .member-desc .vip-content-left-bottom .svip-container .right > img {
  width: 100%;
  height: 100%;
}
.success-main .text-welfare {
  width: 100%;
  color: #494d5b;
  font-size: 14px;
  text-align: center;
  margin-top: 140px;
}
.success-main .item {
  width: 713px;
  margin-top: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #494d5b;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.success-main .item-p-title {
  font-size: 16px;
  font-weight: 700;
}
.success-main .item-p-desc {
  margin-top: 10px;
}

#Nav3_0.jzih1dpqqrg-editor_css {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.ant-menu-item > .item-sub-item > .jzj81c9wabh-editor_css {
  float: none;
  margin: 4px 0px 0px;
}
.ant-menu > .ant-menu-item > .jzj8295azrs-editor_css {
  padding: 0;
}
#Feature0_0 .ant-col > .content0-block-item > .jzj8xt5kgv7-editor_css {
  font-size: 18px;
}
#Nav3_0 .home-page-mobile > .jzjgnya1gmn-editor_css {
  width: 110px;
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .content0-block-icon {
  width: 10px;
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .jzjgrlz134-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjgrlz134-editor_css {
  width: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjncn210ql-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndq0dueg-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndsyw8sf-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndw5oerk-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne24af8c-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne54fwqm-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-row > .ant-col > .jzjgrrupf2c-editor_css {
  padding: 0;
}
#Feature0_0 div > .ant-row > .content0-block {
  min-height: 340px;
  padding: 24px;
  transition: box-shadow 0.45s ease 0s;
}
#Feature0_0 div > .ant-row > .content0-block:hover {
  box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
}
#Footer1_0 .ant-row > .ant-col > .jzl0qcpyjra-editor_css {
  width: 100px;
}
#Footer1_0 .ant-col > div > .jzl0u1bko6-editor_css {
  margin: 16px 0 0;
}

.home-page-mobile-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home-page-mobile-wrapper .home-page-mobile {
  height: 100%;
  max-width: 1200px;
  position: relative;
  margin: auto;
  will-change: transform;
}
.home-page-mobile-wrapper .title-wrapper > h1,
.home-page-mobile-wrapper > h1 {
  font-size: 32px;
  color: #213547;
  margin-bottom: 16px;
}
.home-page-mobile-wrapper .title-wrapper {
  margin: 0 auto 64px;
  text-align: center;
}
.home-page-mobile {
  padding: 128px 24px;
}
@media screen and (max-width: 767px) {
  .home-page-mobile-wrapper .home-page-mobile {
    padding: 56px 24px;
  }
  .home-page-mobile-wrapper .home-page-mobile > h1 {
    font-size: 24px;
    margin: 0 auto 32px;
  }
  .home-page-mobile-wrapper .home-page-mobile > h1.title-h1 {
    margin-bottom: 8px;
  }
  .home-page-mobile-wrapper .home-page-mobile > p {
    margin-bottom: 32px;
  }
}

body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
/* .content-wrapper > .tween-one-leaving,
.queue-anim-leaving {
  // position: absolute !important;
  // width: 100%;
} */
.video {
  max-width: 800px;
}
#react-content {
  min-height: 100%;
}
.home-page-mobile-wrapper p {
  padding: 0;
  margin: 0;
}

.header3 {
  background: #fff;
  width: 100%;
  z-index: 1;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  top: 0;
  position: fixed;
}
.header3 .home-page-mobile {
  padding: 0 24px;
}
.header3-logo {
  display: inline-block;
  position: relative;
  width: 150px;
  line-height: 64px;
}
.header3-logo img {
  vertical-align: middle;
  display: inline-block;
}
.header3-logo a {
  display: block;
}
.header3-menu {
  float: right;
}
.header3-menu .ant-menu-horizontal {
  border-bottom: none;
}
.header3-menu .ant-menu {
  line-height: 62px;
  height: 64px;
  background: transparent;
}
.header3-menu .ant-menu a {
  display: block;
}
.header3-item-block {
  padding: 0 8px;
}
.header3-item-block > * {
  display: inline-block;
}
.header3-item .ant-menu-sub .ant-menu-item,
.header3-item-child .ant-menu-sub .ant-menu-item,
.header3-menu .ant-menu-sub .ant-menu-item,
.header3-item .ant-menu-inline .ant-menu-item,
.header3-item-child .ant-menu-inline .ant-menu-item,
.header3-menu .ant-menu-inline .ant-menu-item {
  height: auto;
  line-height: 1.5;
}
.header3-item .item-sub-item,
.header3-item-child .item-sub-item,
.header3-menu .item-sub-item {
  display: block;
  padding: 8px 24px;
}
.header3-item .item-image,
.header3-item-child .item-image,
.header3-menu .item-image {
  float: left;
  margin-right: 16px;
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.header3-item .item-title,
.header3-item-child .item-title,
.header3-menu .item-title {
  font-size: 14px;
  color: #ff5000;
  margin-left: 46px;
}
.header3-item .item-content,
.header3-item-child .item-content,
.header3-menu .item-content {
  font-size: 12px;
  color: rgba(255, 80, 0, 0.75);
  margin-left: 46px;
}
@media screen and (max-width: 767px) {
  .header3-logo {
    z-index: 101;
  }
  .header3.home-page-mobile-wrapper .home-page-mobile {
    padding: 0 24px;
  }
  .header3-menu {
    height: auto;
    float: inherit;
    position: relative;
    left: -24px;
    width: calc(100% + 48px);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background: #fff;
  }
  .header3-menu li {
    padding: 0 24px;
  }
  .header3-menu li.ant-menu-submenu {
    padding: 0;
  }
  .header3-menu .item-sub-item {
    padding: 8px 0;
  }
  .header3-mobile-menu {
    width: 16px;
    height: 14px;
    cursor: pointer;
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  .header3-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  .header3-mobile-menu :first-child {
    margin-top: 0;
  }
  .header3 .ant-menu {
    height: auto;
    overflow: hidden;
  }
  .header3 .ant-menu .ant-menu-item-selected {
    border: none;
  }
  .header3 .open {
    height: auto;
  }
  .header3 .open .header3-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .header3 .open .header3-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .header3 .open .header3-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .header3 .open > .header3-menu {
    opacity: 1;
    pointer-events: auto;
  }
  .header3-item-block {
    height: 40px;
    line-height: 40px;
  }
}

.banner5 {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, rgba(253, 253, 253, 0.3) 0%, rgba(193, 218, 255, 0.3) 100%);
  overflow: hidden;
  background: url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/home-banner.png?sign=4a3d64265c396b94b4a9a5be653a4fe6&t=1634014076) no-repeat 50% / cover;
  margin-top: 60px;
}
.banner5 .banner5-page {
  padding: 1px 0 0;
}
.banner5-title {
  font-size: 48px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 8px;
  font-family: FZJUNH_CUJW ;
  font-weight: 500;
}
.banner5-title-wrapper {
  width: 40%;
  max-width: 450px;
  position: relative;
  top: 30%;
  left: 24px;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.banner5-explain {
  color: #fff;
  font-size: 24px;
  margin-bottom: 24px;
}
.banner5-content {
  font-size: 18px;
  color: #e4dfdf;
  margin-bottom: 48px;
}
.banner5-button-wrapper .banner5-button {
  background-image: linear-gradient(135deg, #ff813a 0%, #ff3d3d 100%);
  box-shadow: 0 8px 24px rgba(95, 155, 241, 0.35);
  border-radius: 20px;
  border: none;
  padding: 0 42px;
  line-height: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
}
.banner5-button-wrapper .banner5-button:hover {
  transform: translateY(-5px);
  box-shadow: 0 13px 24px rgba(95, 155, 241, 0.45);
  background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
}
.banner5-image {
  overflow: hidden;
  width: 55%;
  max-width: 710px;
  position: absolute;
  top: 15%;
  right: 24px;
}
@media screen and (max-width: 767px) {
  .banner5 {
    min-height: 500px;
  }
  .banner5 .banner5-page.home-page {
    padding-top: 1px;
    padding-bottom: 0;
  }
  .banner5-title-wrapper {
    text-align: center;
    position: static;
    width: 100%;
    margin-top: 64px;
    left: 0;
    top: 0;
  }
  .banner5-title {
    font-size: 32px;
  }
  .banner5-explain {
    font-size: 18px;
  }
  .banner5-content {
    font-size: 14px;
  }
  .banner5-image {
    position: static;
    width: 100%;
    top: 0;
    right: 0;
    margin: 32px 0 24px;
  }
}

.feature6-wrapper {
  min-height: 360px;
  margin: 0 auto;
  overflow: hidden;
}
.feature6-wrapper.home-page-mobile-wrapper .home-page-mobile {
  padding: 64px 24px;
}
.feature6-title {
  display: inline-block;
}
.feature6-title-wrapper {
  text-align: center;
  margin-bottom: 48px;
}
.feature6-title-text {
  display: inline-block;
  margin: 0 72px;
  font-size: 24px;
  color: #ff5000;
  transition: color 0.45s ease-in-out;
  cursor: pointer;
}
.feature6-title-text.active {
  color: #ff5000;
}
.feature6-title-bar {
  height: 6px;
  background: #ff5000;
  width: 20%;
  margin: auto;
  display: block;
}
.feature6-title-bar-wrapper {
  position: relative;
  margin-top: 8px;
  transition: left 0.45s ease-in-out;
}
.feature6-number,
.feature6-text {
  text-align: center;
}
.feature6-number {
  font-size: 48px;
  color: #ff5000;
  font-weight: 600;
}
.feature6-number-wrapper {
  text-align: center;
}
.feature6-text {
  font-size: 20px;
}
.feature6-unit {
  font-size: 16px;
  color: #ff5000;
}
.feature6 .ant-carousel .slick-dots-bottom {
  bottom: -24px;
}
.feature6 .ant-carousel .slick-dots li button {
  background: rgba(255, 80, 0, 0.3);
}
.feature6 .ant-carousel .slick-dots li.slick-active button {
  background: #ff5000;
}
@media screen and (max-width: 767px) {
  .feature6-wrapper {
    padding-bottom: 0;
    min-height: 580px;
  }
  .feature6-wrapper.home-page-mobile-wrapper .home-page-mobile {
    padding: 56px 24px 64px;
  }
  .feature6-title-text {
    margin: 0 14px;
  }
  .feature6-title-bar {
    width: 40%;
  }
  .feature6-number-wrapper {
    margin-bottom: 16px;
  }
}

.feature7-wrapper {
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  background-color: #f7f9fc;
}
.feature7-wrapper.home-page-mobile-wrapper .home-page-mobile {
  padding: 64px 24px;
}
.feature7-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature7-title-h1 {
  font-size: 32px;
  color: #213547;
  font-weight: 500;
}
.feature7-title-content {
  margin-top: 16px;
}
.feature7-block {
  margin-top: 28px;
}
.feature7-block-group {
  display: block;
  padding: 28px 24px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.08);
  background-image: url('https://gw.alipayobjects.com/mdn/rms_ae7ad9/afts/img/A*fMOFSpRXMxsAAAAAAAAAAABkARQnAQ');
  background-repeat: no-repeat;
  background-position: 100% 100%;
  transition: box-shadow 0.45s ease-in-out, transform 0.45s ease-in-out;
}
.feature7-block-group:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
}
.feature7-block-image {
  float: left;
  width: 24px;
}
.feature7-block-image img {
  width: 100%;
}
.feature7-block-title {
  font-size: 14px;
  float: left;
  margin-left: 8px;
  margin-bottom: 16px;
  color: #213547;
}
.feature7-block-content {
  clear: both;
  color: rgba(33, 53, 71, 0.75);
}
@media screen and (max-width: 767px) {
  .feature7-wrapper {
    min-height: auto;
  }
  .feature7-wrapper.home-page-mobile-wrapper .home-page-mobile {
    padding: 56px 24px;
  }
}

.feature8-wrapper {
  min-height: 680px;
  margin: 0 auto;
  overflow: hidden;
  background-color: rgba(253, 253, 253, 0.3);
  background-image: linear-gradient(360deg, rgba(193, 218, 255, 0.3) 0%, rgba(253, 253, 253, 0.3) 80%);
}
.feature8-wrapper.home-page-mobile-wrapper .home-page-mobile {
  padding: 64px 24px;
}
.feature8-title-wrapper {
  text-align: center;
  margin-bottom: 40px;
}
.feature8-title-h1 {
  font-size: 32px;
  color: #213547;
  font-weight: 500;
}
.feature8-title-content {
  margin-top: 16px;
}
.feature8-carousel {
  width: calc(100% + 72px);
  margin-left: -36px;
}
.feature8-carousel-title {
  font-size: 20px;
  text-align: center;
}
.feature8-carousel-title-block {
  display: inline-block;
  color: rgba(33, 53, 71, 0.45);
  transition: color 0.45s;
  padding: 0 40px;
  position: relative;
  cursor: pointer;
}
.feature8-carousel-title-block.active {
  color: #213547;
  cursor: auto;
}
.feature8-carousel-title-block::before {
  display: block;
  content: '';
  width: 1px;
  height: 70%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background: #d8d8d8;
}
.feature8-carousel-title-block:last-child::before {
  display: none;
}
.feature8-block {
  margin-top: 48px;
}
.feature8-block-wrapper {
  margin-top: 32px;
}
.feature8-block-row {
  padding: 0 36px;
}
.feature8-block-col {
  text-align: center;
  margin: 36px 0;
  position: relative;
}
.feature8-block-col:last-child .feature8-block-arrow {
  display: none;
}
.feature8-block-child {
  padding: 36px 24px 24px;
  border-radius: 6px;
  height: 100%;
  transition: box-shadow 0.45s ease-in-out, background 0.45s ease-in-out;
}
.feature8-block-child:hover {
  background-color: #f6f9ff;
  box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
}
.feature8-block-child:hover .feature8-block-content {
  opacity: 1;
}
.feature8-block-image {
  display: inline-block;
  width: 48px;
}
.feature8-block-image img {
  width: 100%;
}
.feature8-block-title {
  font-size: 16px;
  font-weight: 400;
  color: #213547;
  margin-top: 16px;
}
.feature8-block-content {
  opacity: 1;
  font-size: 14px;
  line-height: 22px;
  color: rgba(33, 53, 71, 0.65);
  margin-top: 36px;
  text-align: left;
  transition: opacity 0.45s ease-in-out;
}
.feature8-block-arrow {
  position: absolute;
  right: 0;
  width: 16px;
  top: 80px;
}
.feature8-button {
  text-align: center;
}
.feature8-button-wrapper {
  margin-top: 16px;
}
.feature8-button .ant-btn {
  padding: 8px 40px;
  border-radius: 20px;
  line-height: 40px;
  height: 40px;
  border: none;
  color: #fff;
  background-color: #3c89f6;
  background-image: linear-gradient(135deg, #ff813a 0%, #ff3d3d 100%);
  box-shadow: 0 9px 22px rgba(95, 155, 241, 0.35);
  transition: color 0.45s ease-in-out, box-shadow 0.45s ease-in-out, transform 0.45s ease-in-out;
  will-change: transform, box-shadow;
}
.feature8-button .ant-btn:hover {
  transform: translateY(-5px);
  box-shadow: 0 14px 26px rgba(95, 155, 241, 0.4);
}
@media screen and (max-width: 767px) {
  .feature8-wrapper {
    padding-bottom: 0;
    min-height: 1540px;
  }
  .feature8-wrapper.home-page-mobile-wrapper .home-page-mobile {
    padding: 56px 24px 64px;
  }
  .feature8-carousel-title {
    font-size: 16px;
  }
  .feature8-carousel-title-block {
    padding: 0 8px;
  }
  .feature8-block-child {
    width: 80%;
    margin: auto;
    background-color: #f6f9ff;
    box-shadow: 0 12px 36px rgba(100, 100, 136, 0.15);
  }
  .feature8-block-content {
    text-align: center;
  }
  .feature8-block-arrow {
    bottom: -40px;
    top: auto;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(90deg);
  }
}

.footer1-wrapper {
  background: #001529;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: #999;
}
.footer1-wrapper .footer1 .home-page-mobile {
  padding: 64px 24px 80px;
}
.footer1-wrapper .block {
  padding: 0 32px;
}
.footer1-wrapper .block .logo {
  max-width: 180px;
}
.footer1-wrapper .block .slogan {
  font-size: 12px;
  margin-top: -20px;
}
.footer1-wrapper .block > h2 {
  margin-bottom: 24px;
  color: #ccc;
}
.footer1-wrapper .block a {
  color: #999;
  margin-bottom: 12px;
  float: left;
  clear: both;
}
.footer1-wrapper .block a:hover {
  color: #ff5000;
}
.footer1-wrapper .block .image-jzl0tcm4f1d img {
  width: 60%;
}
.footer1-wrapper .copyright-wrapper {
  width: 100%;
  border-top: 1px solid rgba(233, 233, 233, 0.1);
}
.footer1-wrapper .copyright-wrapper .home-page-mobile {
  padding: 0 24px;
  overflow: hidden;
}
.footer1-wrapper .copyright-wrapper .copyright {
  height: 80px;
  text-align: center;
  line-height: 80px;
}
@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
  }
  .footer1-wrapper .footer1 .home-page-mobile {
    padding: 64px 24px 32px;
  }
  .footer1 .logo {
    margin: 0 auto 24px;
  }
  .footer1 .block {
    text-align: center;
    margin-bottom: 32px;
    padding: 0;
  }
  .footer1 > ul {
    width: 90%;
    margin: 20px auto 0;
    padding: 10px 0;
  }
  .footer1 > ul > li {
    width: 100%;
  }
  .footer1 > ul > li h2 {
    margin-bottom: 10px;
  }
  .footer1 > ul > li li {
    display: inline-block;
    margin-right: 10px;
  }
  .footer1 .copyright-wrapper .home-page-mobile {
    padding: 0;
  }
  .footer1 .copyright-wrapper .home-page-mobile .copyright {
    font-size: 12px;
  }
  .footer1 .copyright span {
    width: 90%;
  }
}


.comp-loading-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.Panel-text {
  display: block;
  padding: 10px 20px;
  border: 1px dashed #999999;
  border-radius: 5px;
  background-color: #fafafb;
}
.Panel-text p {
  color: #777777;
  margin: 10px 0;
  font-family: 'microsoft yahei';
}

.panel-case {
  padding-bottom: 20px;
}
.Panel-text {
  position: relative;
  display: block;
  padding: 10px 20px;
  border: 1px dashed #999999;
  border-radius: 5px;
  background-color: #fafafb;
  margin-bottom: 20px;
}
.Panel-text b {
  color: #5d6876;
}
.Panel-text p {
  color: #777777;
  margin: 10px 0;
  font-family: 'microsoft yahei';
}
.Panel-text .case_masking {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
  transition: all 0.1s;
}
.Panel-text .case_masking span {
  display: inline-block;
  line-height: 40px;
  padding: 0 17px;
  border-radius: 5px;
  background: linear-gradient(90deg, #ff813a, #ff3d3d);
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 5px 15px 0px rgba(0, 192, 145, 0.5);
}
.Panel-text:hover {
  border-color: #ff3d3d;
  background-color: rgba(254, 144, 0, 0.05);
}
.Panel-text:hover .tag {
  color: #fe9000 !important;
}
.Panel-text:hover .case_masking {
  opacity: 1;
}
.Panel-text:hover b {
  color: #fe9000;
}

.sider-drawer .site-drawer-render-in-current-wrapper {
  position: absolute;
  height: 100%;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  background: #fafafa;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}
.sider-drawer .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
  margin-top: 5px;
  border-radius: 30px;
}
.sider-drawer .ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
  /* box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%), -12px 0 48px 16px rgb(0 0 0 / 3%); */
  margin-top: 5px;
  border-radius: 30px;
}
.sider-drawer .ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border: 0;
}
.sider-drawer .ant-drawer-header {
  position: relative;
  padding: 0px 12px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.sider-drawer .ant-drawer-body {
  margin: 50px 0;
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
  position: relative;
}
.sider-drawer .panel_header {
  left: 0px;
  top: 68px;
  width: 100%;
  height: 80px;
  margin-bottom: 34px;
  padding: 0 35px;
  position: fixed;
  background: #fff;
  z-index: 3;
}
.sider-drawer .panel_header .icon.tips {
  position: relative;
  float: left;
  width: 48px;
  height: 48px;
  margin-left: 10px;
  margin-right: 20px;
  border: solid 1px #cccccc;
  border-radius: 10px;
  background: #ffffff url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E5%B0%8F%E8%B4%B4%E5%A3%AB.png?sign=2845b5f2129347b3979672b8577aa7cf&t=1636386777) no-repeat;
  background-size: 60%;
  background-position: center;
  cursor: pointer;
}
.sider-drawer .panel_header .icon.tips:hover {
  background: #ff5a0e url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E5%B0%8F%E8%B4%B4%E5%A3%AB.png?sign=2845b5f2129347b3979672b8577aa7cf&t=1636386777) no-repeat;
  background-position: center;
  background-size: 60%;
}
.sider-drawer .panel_header .icon.tips.current {
  background: #ff5a0e url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E5%B0%8F%E8%B4%B4%E5%A3%AB.png?sign=2845b5f2129347b3979672b8577aa7cf&t=1636386777) no-repeat;
  background-size: 60%;
  background-position: center;
}
.sider-drawer .panel_header .icon.tips.current::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 20px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: #ff5a0e;
}
.sider-drawer .panel_header .icon.diagnose {
  position: relative;
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border: solid 1px #cccccc;
  border-radius: 10px;
  background: #ffffff url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E8%AF%8A%E6%96%AD.png?sign=e3ace02a90a5c83b1e605dcb2074f6b0&t=1636386747) no-repeat;
  background-size: 60%;
  background-position: center;
  cursor: pointer;
}
.sider-drawer .panel_header .icon.diagnose:hover {
  background: #ff5a0e url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E8%AF%8A%E6%96%AD.png?sign=e3ace02a90a5c83b1e605dcb2074f6b0&t=1636386747) no-repeat;
  background-position: center;
  background-size: 60%;
}
.sider-drawer .panel_header .icon.diagnose.current {
  background: #ff5a0e url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E8%AF%8A%E6%96%AD.png?sign=e3ace02a90a5c83b1e605dcb2074f6b0&t=1636386747) no-repeat;
  background-position: center;
  background-size: 60%;
}
.sider-drawer .panel_header .icon.diagnose.current::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 20px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: #ff5a0e;
}
.sider-drawer .panel_header .icon.case {
  position: relative;
  float: left;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border: solid 1px #cccccc;
  border-radius: 10px;
  background: #ffffff url('https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E6%A1%88%E4%BE%8B%20(1).png?sign=4f7bb20b3f7e03675cf1d8d1e34748ef&t=1636386805') no-repeat;
  background-size: 60%;
  background-position: center;
  cursor: pointer;
}
.sider-drawer .panel_header .icon.case:hover {
  background: #ff5a0e url('https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E6%A1%88%E4%BE%8B%20(1).png?sign=4f7bb20b3f7e03675cf1d8d1e34748ef&t=1636386805') no-repeat;
  background-size: 60%;
  background-position: center;
}
.sider-drawer .panel_header .icon.case.current {
  background: #ff5a0e url('https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/resume/%E6%A1%88%E4%BE%8B%20(1).png?sign=4f7bb20b3f7e03675cf1d8d1e34748ef&t=1636386805') no-repeat;
  background-size: 60%;
  background-position: center;
}
.sider-drawer .panel_header .icon.case.current::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 20px;
  width: 8px;
  height: 8px;
  transform: rotate(45deg);
  background-color: #ff5a0e;
}
.sider-drawer .panel-content {
  height: 95%;
  width: 100%;
}
.sider-drawer .circle {
  height: 62px;
  width: 62px;
  margin-right: 223px;
}
.sider-drawer .circle .background {
  fill: transparent;
  stroke: #ff5a0e;
  transition: all 200ms ease;
}
.sider-drawer .circle .foreground {
  fill: transparent;
  stroke-dasharray: 377;
  stroke-dashoffset: 377;
  stroke: #37ee89;
  transform-origin: 50% 50%;
  transform: rotate(-270deg);
  transition: all 800ms ease;
}
.sider-drawer .circle .line {
  stroke-width: 2;
  stroke: #ff5a0e;
  transform-origin: 50% 50%;
  transition: all 500ms ease;
}
.sider-drawer .circle .line2 {
  transform: rotate(-90deg);
}
.sider-drawer .circle:hover {
  cursor: pointer;
}
.sider-drawer .circle:hover .background {
  stroke: transparent;
}
.sider-drawer .circle:hover .foreground {
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
}
.sider-drawer .circle:hover .line {
  stroke: #37ee89;
}
.sider-drawer .circle:hover .line {
  transform: rotate(180deg);
}
.sider-drawer .circle:hover .line2 {
  transform: rotate(0);
}

.layout-wrapper {
  position: relative;
}
.layout-wrapper .layout-header {
  position: fixed;
  z-index: 3;
  width: 100%;
  padding: 0;
  padding-right: 24px;
  background-color: white;
  box-shadow: 0 5px 21px 0 rgba(78, 78, 78, 0.05);
}
.layout-wrapper .layout-header .logo-wrapper img {
  margin-left: 16px;
  cursor: pointer;
}
.layout-wrapper .layout-header .ant-input-affix-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  border-radius: 5px;
  width: 250px;
  background-color: #f3f4f6;
  transition: all 0.2s ease;
  border: 1px solid #f3f4f6;
}
.layout-wrapper .layout-header .ant-input-affix-wrapper .ant-input {
  background-color: #f3f4f6;
  transition: all 0.2s ease;
  border: 1px solid #f3f4f6;
}
.layout-wrapper .layout-header .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(254, 144, 0, 0.3);
}
.layout-wrapper .layout-header .ant-input-affix-wrapper:hover {
  width: 300px;
}
.layout-wrapper .layout-content {
  position: relative;
  padding: 24px 24px;
  margin-top: 64px;
}
.layout-wrapper .side-collap {
  padding: 12px;
  border-top: 1px solid #f0f0f0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: right;
}
.layout-wrapper .trigger {
  font-size: 16px;
}
.layout-wrapper .trigger:hover {
  color: #1890ff;
}
.layout-wrapper .ant-menu-horizontal {
  border: none;
}
.layout-wrapper .page-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 24px 0;
  overflow-x: hidden;
}
.layout-wrapper .layout-sider {
  z-index: 1;
  position: relative;
}
.layout-wrapper .layout-sider-left {
  left: 0;
  top: 64px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  z-index: 1;
  width: 120px;
  min-width: 120px;
  transition: all 0.3s;
}
.layout-wrapper .layout-sider-left-menuItem {
  width: 100%;
  height: 90px;
  padding-left: 10px;
}
.layout-wrapper .layout-sider-left .menuItem-box {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  color: #333;
  cursor: pointer;
  transition: background 0.2s;
  margin-top: 5px;
  padding-top: 10px;
}
.layout-wrapper .layout-sider-left .menuItem-box a {
  width: 10px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
}
.layout-wrapper .layout-sider-left .menuItem-box:hover {
  background-color: #fe9000;
}
.layout-wrapper .layout-sider-right {
  right: 0;
  top: 64px;
  height: 100vh;
  position: fixed;
  z-index: 1;
  width: 120px;
  background: rgba(254, 144, 0, 0);
  min-width: 120px;
  transition: all 0.3s;
}
.layout-wrapper .layout-sider-right .ant-menu {
  background: rgba(254, 144, 0, 0);
}
.layout-wrapper .layout-sider-right-menuItem {
  width: 100%;
  height: 90px;
  padding-right: 10px;
}
.layout-wrapper .layout-sider-right .menuItem-box {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s;
  margin-top: 5px;
  padding-top: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  opacity: 1;
  transition: all 0.4s;
}
.layout-wrapper .layout-sider-right .menuItem-box .menuItem-text {
  width: 100%;
  height: 35px;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
  top: 13px;
  right: 3px;
  opacity: 0;
}
.layout-wrapper .layout-sider-right .menuItem-box .menuItem-text:hover {
  opacity: 1;
}
.layout-wrapper .layout-sider-right .menuItem-box:hover {
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 5px 21px 0 rgba(224, 162, 26, 0.05);
}
.pdf-confirm-box {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}
.pdf-confirm-box-name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}
.pdf-confirm-box-name-tips {
  font-size: 9px;
  color: #c7c7c7;
  padding: 5px;
}
.pdf-confirm-box-name .ant-input-affix-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  border-radius: 5px;
  width: 250px;
  background-color: #f3f4f6;
  transition: all 0.2s ease;
  border: 1px solid #f3f4f6;
}
.pdf-confirm-box-name .ant-input-affix-wrapper .ant-input {
  background-color: #f3f4f6;
  transition: all 0.2s ease;
  border: 1px solid #f3f4f6;
}
.pdf-confirm-box-name .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(254, 144, 0, 0.3);
}
.pdf-confirm-box-name .ant-input-affix-wrapper:hover {
  width: 300px;
}
.pdf-confirm-box-name .ant-radio-checked .ant-radio-inner {
  border-color: #ff4906;
}
.pdf-confirm-box-name .ant-radio-checked .ant-radio-inner::after {
  background: #ff4906;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, html, i, iframe, img, input, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}
