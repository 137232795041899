@bgColor: #f7f7f7;
@titleColor: fade(#000, 85);
@contentColor: fade(#000, 43);

.home-main {
  .fd-content {
    min-width: 1216px;
    background: #fff;
    font-family: PingFangSC, Helvetica Neue, Helvetica, PingFang SC-Light, Hiragino Sans GB,
      Microsoft YaHei, SimSun, sans-serif;
  }

  h2,
  h3,
  h4,
  h5 {
    font-family: 'YouSheBiaoTiHei';
    font-weight: normal;
    font-style: normal;
  }

  .home-layout-wrapper {
    width: 100%;
    height: 750px;
    text-align: center;
    overflow: hidden;

    .home-layout {
      margin: 0 auto;
      width: 1340px;
      padding: 0 24px;
      overflow: hidden;
      height: 100%;

      > div {
        float: left;
        width: 100%;
      }

      .col {
        .content-wrapper {
          border-radius: 4px;
          background: #fff;
          cursor: pointer;
          overflow: hidden;
          position: relative;
        }
      }
    }

    &:nth-of-type(odd) {
      background: #fff;
    }

    &:nth-of-type(even) {
      background-color: @bgColor;
    }
  }

  h2,
  h3 {
    text-align: center;
    font-weight: normal;
  }

  h2 {
    font-size: 30px;
    line-height: 38px;
    color: @titleColor;
    margin-top: 80px;
  }

  h3 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: @titleColor;
  }

  .line {
    width: 40px;
    height: 2px;
    display: block;
    background: #ffbf00;
    margin: 16px auto 72px;
  }

  /* banner 区域 */

  /* 案例 */
  .home-case-wrapper {
    .col {
      .content-wrapper {
        height: 360px;
        padding: 16px;

        .ss {
          display: flex;
          direction: row;
        }

        .image {
          width: 80%;
          height: 80%;
          border-radius: 2px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }

        &:hover {
          .code-wrapper {
            opacity: 1;
          }
        }
      }

      .code-wrapper {
        position: absolute;
        background: fade(#fff, 90);
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.45s cubic-bezier(0.165, 0.84, 0.44, 1);

        //二维码位置
        canvas {
          height: 160px;
          width: 160px;
          margin-left: 32px;
        }

        h4 {
          font-size: 16px;
          font-weight: normal;
          margin: 72px auto 12px;
          color: @titleColor;
        }
      }
    }
  }

  .home-serve-wrapper {
    height: 788px;
    text-align: center;

    .col {
      font-size: 16px;

      .content-wrapper {
        padding: 24px 16px;
        height: 440px;

        .icon-hover {
          opacity: 0;
          transition: opacity 0.45s;
        }

        &:hover {
          .icon-hover {
            opacity: 1;
          }

          p {
            color: fade(#000000, 65);
          }
        }
      }

      .image {
        width: 32px;
        height: 32px;
        margin: 8px auto 12px;
      }

      h3 {
        margin-bottom: 24px;
      }

      p {
        margin-bottom: 12px;
        color: @contentColor;
        transition: color 0.3s;
        font-weight: 400;
      }

      .exp {
        position: absolute;
        bottom: 24px;
        left: 0;
        width: 100%;
        font-size: 20px;
        color: fade(#000, 25);
      }
    }
  }

  .home-user-wrapper {
    height: 440px;

    h2 {
      margin-top: 64px;
    }

    .col {
      margin-bottom: 32px;

      i {
        display: block;
        width: 170px;
        height: 56px;
        background-repeat: no-repeat;
        background-position: center;
        margin: auto;
      }
    }
  }
}

// 手机样式（iphone-x）
.device,
.device::before,
.device::after,
.device *,
.device *::before,
.device *::after {
  box-sizing: border-box;
  display: block;
}

.device {
  position: relative;
  transform: scale(1);
  z-index: 1;
}

.device .device-frame {
  z-index: 1;
}

.device .device-content {
  background-color: #fff;
  background-position: center center;
  background-size: cover;
  object-fit: cover;
  position: relative;
}

.device-iphone-x {
  height: 868px;
  width: 428px;
}

.device-iphone-x .device-frame {
  background: #222;
  border-radius: 68px;
  box-shadow: inset 0 0 2px 2px #c8cacb, inset 0 0 0 7px #e2e3e4;
  height: 868px;
  padding: 28px;
  width: 428px;
}

.device-iphone-x .device-content {
  border-radius: 40px;
  height: 812px;
  width: 375px;
}

.device-iphone-x .device-stripe::after,
.device-iphone-x .device-stripe::before {
  border: solid rgba(51, 51, 51, 0.25);
  border-width: 0 7px;
  content: '';
  height: 7px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.device-iphone-x .device-stripe::after {
  top: 85px;
}

.device-iphone-x .device-stripe::before {
  bottom: 85px;
}

.device-iphone-x .device-header {
  background: #222;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 30px;
  left: 50%;
  margin-left: -102px;
  position: absolute;
  top: 28px;
  width: 204px;
}

.device-iphone-x .device-header::after,
.device-iphone-x .device-header::before {
  content: '';
  height: 10px;
  position: absolute;
  top: 0;
  width: 10px;
}

.device-iphone-x .device-header::after {
  background: radial-gradient(
    circle at bottom left,
    transparent 0,
    transparent 75%,
    #222 75%,
    #222 100%
  );
  left: -10px;
}

.device-iphone-x .device-header::before {
  background: radial-gradient(
    circle at bottom right,
    transparent 0,
    transparent 75%,
    #222 75%,
    #222 100%
  );
  right: -10px;
}

.device-iphone-x .device-sensors::after,
.device-iphone-x .device-sensors::before {
  content: '';
  position: absolute;
}

.device-iphone-x .device-sensors::after {
  background: #444;
  border-radius: 3px;
  height: 6px;
  left: 50%;
  margin-left: -25px;
  top: 32px;
  width: 50px;
}

.device-iphone-x .device-sensors::before {
  background: #444;
  border-radius: 50%;
  height: 14px;
  left: 50%;
  margin-left: 40px;
  top: 28px;
  width: 14px;
}

.device-iphone-x .device-btns {
  background: #c8cacb;
  height: 32px;
  left: -3px;
  position: absolute;
  top: 115px;
  width: 3px;
}

.device-iphone-x .device-btns::after,
.device-iphone-x .device-btns::before {
  background: #c8cacb;
  content: '';
  height: 62px;
  left: 0;
  position: absolute;
  width: 3px;
}

.device-iphone-x .device-btns::after {
  top: 60px;
}

.device-iphone-x .device-btns::before {
  top: 140px;
}

.device-iphone-x .device-power {
  background: #c8cacb;
  height: 100px;
  position: absolute;
  right: -3px;
  top: 200px;
  width: 3px;
}

//修改位置
.home-main .home-layout-wrapper .home-layout {
  margin: 0 auto;
  width: 1340px;
  padding: 0 24px;
  overflow: hidden;
  height: 750px;
}

.device-iphone-x {
  height: 521px;
  width: 257px;
}

.device-iphone-x .device-frame {
  background: #222;
  border-radius: 43px;
  box-shadow: inset 0 0 2px 1px #c8cacb, inset 0 0 0 5px #e2e3e4;
  height: 521px;
  padding: 28px;
  width: 257px;
}

.device-iphone-x .device-header {
  background: #222;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 20px;
  left: 50%;
  margin-left: -59px;
  position: absolute;
  top: 15px;
  width: 122px;
}

.device-iphone-x .device-btns {
  background: #c8cacb;
  height: 19px;
  left: -3px;
  position: absolute;
  top: 80px;
  width: 3px;
}

.device-iphone-x .device-power {
  background: #c8cacb;
  height: 60px;
  position: absolute;
  right: -3px;
  top: 120px;
  width: 3px;
}

.device-iphone-x .device-content {
  border-radius: 30px;
  height: 471px;
  width: 228px;
  margin-left: -13px;
  margin-top: -9px;
}

.device-iphone-x .device-btns::after,
.device-iphone-x .device-btns::before {
  background: #c8cacb;
  content: '';
  height: 45px;
  left: 0;
  position: absolute;
  width: 3px;
  top: 95px;
}

.device-iphone-x .device-btns::after {
  top: 36px;
}

.device-iphone-x .device-stripe::after,
.device-iphone-x .device-stripe::before {
  border: solid rgba(51, 51, 51, 0.25);
  border-width: 0 7px;
  content: '';
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 9;
}

.device-iphone-x .device-stripe::after {
  top: 53px;
}

.device-iphone-x .device-stripe::before {
  bottom: 53px;
}

.device-iphone-x .device-sensors::before {
  background: #444;
  border-radius: 50%;
  height: 10px;
  left: 48%;
  margin-left: 38px;
  top: 17px;
  width: 10px;
}

.device-iphone-x .device-sensors::after {
  background: #444;
  border-radius: 3px;
  height: 4px;
  left: 50%;
  margin-left: -13px;
  top: 20px;
  width: 34px;
}

//二维码位置
.home-main .home-case-wrapper .col .code-wrapper h4 {
  font-size: 16px;
  font-weight: normal;
  margin: 72px auto 12px;
  margin-top: 120px;
  color: rgba(0, 0, 0, 0.85);
}

//手机距离
.home-main .home-layout-wrapper .home-layout {
  margin: 0 auto;
  width: 1440px;
  padding: 0 0px;
  overflow: hidden;
  height: 733px;
}

//手机内部图片样式
.home-main .home-layout-wrapper .home-layout .col .content-wrapper {
  background: #fff;
  height: 490px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  border-radius: 26px;
  padding: 0px;
  top: 0px;
}

.home-main .home-case-wrapper .col .content-wrapper .image {
  width: 100%;
  height: 100%;
  border-radius: 13px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
