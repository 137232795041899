.message-list{
    overflow: auto;
}
.show_chat {
    width: 400px;
    height: 500px;
    opacity: 1;
    z-index: 20;
    right: 0;
    bottom: 0;


    background-color: #f9f9f9;
    position: fixed;
    -webkit-box-shadow: 0 2px 20px rgb(0 0 0 / 40%);
    box-shadow: 0 2px 20px rgb(0 0 0 / 40%);
    border-radius: 6px;
    cursor: default;
    overflow: hidden;
    display: none;


    .chat_tit {
        padding: 12px 20px;
        background-color: #ff8741;
        line-height: 26px;
        font-size: 20px;
        position: relative;
        color: #fff;

        .btn_min {
            &:hover {
                background: #ff9659;
            }
        }

        img {
            float: left;
            width: 26px;
            height: 26px;
            border-radius: 6px;
            margin-right: 6px;
        }

        span {
            width: 50px;
            height: 50px;
            position: absolute;
            right: 0;
            top: 0;
            transition: all .3s ease-out 0s;

            //最小化按钮
            img {
                width: 20px;
                height: 20px;
                width: 37px;
                height: 36px;
                margin-top: 6px;
                margin-left: 6px;

                &:hover {
                    transform: rotate(90deg);
                    transition: all .8s;
                }
            }
        }
    }

    .chat_box {
        margin: 3px 5px 0 3px;
        padding-bottom: 20px;
        padding-right: 18px;
        overflow-y: auto;
        color: #333;
        height: 357px;

        .chat_def {
            text-align: center;
            padding: 15px 0;
            color: #888;
        }
    }

    .chat_enter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 90px;
        padding: 15px 20px;
        background-color: #fff;
        border-top: 1px solid #ddd;
        -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 10%);
        box-shadow: 0 0 5px rgb(0 0 0 / 10%);

        .chat_enter_input {
            width: 80%;
            height: 60px;
            resize: none;
            line-height: 19px;
            border: none;
            font-size: small;
            margin-left: -7px;
        }

        .chat_enter_btn {
            width: 60px;
            position: absolute;
            right: 20px;
            top: 15px;
            padding: 7px 15px;
            font-size: 12px;
            border-radius: 3px;
        }
    }
}

.chat_enter_btn {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    border: 1px solid #dcdfe6;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    transition: .1s;
    font-weight: 500;
    padding: 12px 20px;
    font-size: 14px;
    border-radius: 4px;
    background: #ff8741;
    border-color: #ff8741;
    color: #fff;

    &:hover {
        cursor: pointer;
        background: #ff975a;
        transition: .1s;
    }
}

