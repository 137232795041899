@feature6: feature6;
@ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
@primary-color: #0000;
@text-color: #000;
.@{feature6} {
  &-wrapper {
    min-height: 360px;
    margin: 0 auto;
    overflow: hidden;

    &.home-page-wrapper {
      .home-page {
        padding: 64px 24px;
      }
    }
  }

  &-title {
    display: inline-block;

    &-wrapper {
      text-align: center;
      margin-bottom: 48px;
    }

    &-text {
      display: inline-block;
      margin: 0 72px;
      font-size: 24px;
      color: @text-color;
      transition: color 0.45s @ease-in-out;
      cursor: pointer;

      &.active {
        color: #ff6600;
      }
    }

    &-bar {
      height: 6px;
      background: linear-gradient(90deg, #ff813a, #ff3d3d);
      width: 20%;
      margin: auto;
      display: block;

      &-wrapper {
        position: relative;
        margin-top: 8px;
        transition: left 0.45s @ease-in-out;
      }
    }
  }

  &-number,
  &-text {
    text-align: center;
  }

  &-number {
    font-size: 48px;
    color: #ff3d3d; //linear-gradient(90deg, #ff813a, #ff3d3d);
    font-weight: 600;

    &-wrapper {
      text-align: center;
    }
  }

  &-text {
    font-size: 20px;
  }

  &-unit {
    font-size: 16px;
    color: #ff813a; //linear-gradient(90deg, #ff813a, #ff3d3d);
  }

  .ant-carousel {
    .slick-dots-bottom {
      bottom: -24px;
    }

    .slick-dots li {
      button {
        background: fade(@primary-color, 30);
      }

      &.slick-active button {
        background: linear-gradient(90deg, #ff813a, #ff3d3d);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .@{feature6}-wrapper {
    padding-bottom: 0;
    min-height: 580px;

    &.home-page-wrapper {
      .home-page {
        padding: 56px 24px 64px;
      }
    }
  }

  .@{feature6}-title {
    &-text {
      margin: 0 14px;
    }

    &-bar {
      width: 40%;
    }
  }

  .@{feature6}-number-wrapper {
    margin-bottom: 16px;
  }
}
