.header {
  height: 55px;
  position: fixed;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  transition: background 0.3s, opacity 0.3s;
  background-color: #202329;
  min-width: 1280px;
  -webkit-backdrop-filter: saturate(95%) blur(6px);
  backdrop-filter: saturate(95%) blur(6px);
  // background-color: transparent;

  &.hasBG {
    background: rgba(18, 21, 26, 0.8);
    backdrop-filter: blur(20px);
  }
  .header-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }

  img.logo {
    cursor: pointer;
    height: 30px;
    text-align: center;
    margin-top: 14px;
  }

  .header-items {
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    &-middle {
      width: 400px;
      display: flex;
      flex: 1 1;
      justify-content: center;
      margin: 0 auto;
    }
    .header-item {
      flex: none;
      position: relative;
      height: 100%;
      padding: 0 12px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC',
        'Microsoft YaHei', 'WenQuanYi Micro Hei', sans-serif;
      font-weight: 400;
      text-align: left;
      color: #fff;
      cursor: pointer;
      line-height: 20px;
      transition: all 0.3s ease;
      > img {
        width: 40px;
        height: 20px;
        margin-left: 10px;
      }
      &.active {
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        &::after {
          opacity: 1;
          width: 50%;
          margin: 0 auto;
          text-align: center;
          margin-left: 35px;
          align-items: center;
        }
      }

      &::after {
        content: '';
        width: 0%;
        height: 2px;
        background: linear-gradient(90deg, #ff813a, #ff3d3d);
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        transition: all 0.3s ease;
      }

      .node {
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background: white;
        margin-right: 8px;
        position: relative;
        left: -14px;
        opacity: 0;
        transform: scaleX(1.67);
        transition: left 0.3s, transform 0.3s, opacity 0.1s;
        &.active {
          transform: scaleX(1);
          opacity: 1;
          left: 0;
        }
      }

      &:hover {
        .node {
          transform: scaleX(1);
          opacity: 1;
          left: 0;
        }
      }
    }

    .header-item:hover {
      color: #ff3d3d;
      font-size: 15px;
      font-weight: 500;
    }
    .header-item .lottie-anima-new {
      width: 50px;
      height: 50px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    .header-item::after {
      content: '';
      width: 0%;
      height: 2px;
      background: #ff3d3d;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: all 0.3s ease;
    }

    .header-item:hover::after {
      opacity: 1;
      width: 50%;
      margin: 0 auto;
      text-align: center;
      margin-left: 35px;
      align-items: center;
    }
  }

  .regist {
    cursor: pointer;
    flex: none;
    width: 108px;
    height: 70px;
    background: rgba(255, 255, 255, 0.05);
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #fff;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .animation {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 100%;
      opacity: 1;
      background: linear-gradient(90deg, #ff813a, #ff3d3d);
      transition: left 0.3s, opacity 0.1s;
    }

    &:hover {
      .animation {
        left: 0;
        opacity: 1;
      }
    }
  }

  .login {
    cursor: pointer;
    flex: none;
    width: 150px;
    height: 55px;
    background: rgba(255, 255, 255, 0.1);
    font-size: 14px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: 20px;
    padding-left: 40px;
    display: flex;
    align-items: center;
    position: relative;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
    }

    .animation {
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 100%;
      opacity: 1;
      background: linear-gradient(90deg, #ff813a, #ff3d3d);
      transition: left 0.3s, opacity 0.1s;
    }

    &:hover {
      .animation {
        left: 0;
        opacity: 1;
      }
    }
  }
}
.dropdown {
  background: #fff;
  border-radius: 20px !important;
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.04), 0 4px 16px 4px rgba(0, 0, 0, 0.02),
    0 4px 12px 0 rgba(0, 0, 0, 0.02);
  height: 388px;
  margin-top: 14px !important;
  padding: 0;
  // position: relative;
  width: 336px !important;
  .profile-bg {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 96px;
    left: 0;
    position: absolute;
    top: 0;
    width: 336px;
    z-index: -1;
  }
  .header-profile-top {
    overflow: hidden;
    padding-left: 24px;
    padding-top: 24px;
    .self-info-name {
      align-items: center;
      display: flex;
      .name-desc {
        height: 48px;
        margin-left: 8px;
        margin-top: 4px;
        .name {
          align-items: center;
          display: flex;
          height: 20px;
          .name-img {
            height: 14px;
            margin-left: 2px;
          }
          .name-link {
            color: #ff813a;
            font-weight: 500;
          }
        }
        .identify {
          color: #999 !important;
          cursor: pointer;
          font-size: 12px;
          line-height: 18px;
          margin-top: 4px;
          width: -moz-max-content;
          width: max-content;
          .hove-to-green {
            width: max-content;
            display: flex;
            align-items: center;
          }
          .nc-icon {
            vertical-align: -0.15em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            font-style: normal;
            line-height: 0;
            text-align: center;
            text-rendering: optimizeLegibility;
            text-transform: none;
          }
        }
      }
    }

    .self-page {
      box-sizing: border-box;
      color: #32ca99;
      display: block;
      font-size: 12px;
      font-weight: 500;
      height: 26px;
      line-height: 26px;
      position: absolute;
      right: 16px;
      text-align: center;
      top: 66px;
      width: 56px;
    }
  }
  .menu-top {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.header-right {
  display: flex;
  align-items: center;
  width: 200px;
}
.profile-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.vip-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 25px;
  cursor: pointer;

  &-icon {
  }
  &-text {
    color: #f0f0f0;
    font-size: 12px;
    font-weight: 400;
    height: 12px;
    line-height: 12px;
    margin-top: 6px;
  }
}
.ling-lone {
  background: #f8f8f8;
  height: 1px;
  margin-top: 20px;
  width: 336px;
}
.vip-card {
  background-size: cover;
  border-radius: 12px;
  box-sizing: border-box;
  height: 78px;
  margin-left: 24px;
  margin-top: 12px;
  padding-left: 16px;
  padding-top: 18px;
  position: relative;
  width: 288px;
  &-bg {
    border-radius: 12px;
    height: 78px;
    left: 0;
    position: absolute;
    top: 0;
    width: 288px;
    z-index: -1;
  }
  .vip-text-label {
    align-items: center;
    color: #333;
    display: flex;
    font-weight: 500;
    height: 24px;
    width: 108px;
    .yj-vip-text {
      flex-shrink: 0;
      font-size: 16px;
      line-height: 24px;
      &.vip {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(312deg, #53f7e2, #bafca3 46%, #f2f07c 79%, #ffe741);
        -webkit-background-clip: text;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .vip-label-pic {
      flex-shrink: 0;
      height: 18px;
      margin-left: 4px;
      width: 40px;
    }
  }
  .yj-vip-desc {
    color: #999;
    font-size: 12px;
    line-height: 26px;
  }
  .open-vip-btn {
    -webkit-box-pack: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    background: -webkit-linear-gradient(135deg, #ffdead, #ffbd7f 21%, #fff9d7 53%, #ffebba);
    background: linear-gradient(315deg, #ffdead, #ffbd7f 21%, #fff9d7 53%, #ffebba);
    border-radius: 15px;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    height: 24px;
    -webkit-justify-content: center;
    justify-content: center;
    left: 208px;
    position: absolute;
    top: 27px;
    width: 64px;
    &-text {
      color: #4c260e;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
    }
  }
}
.nc-nav-header-link {
  margin-left: 24px;
  width: 288px;
  &-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-box-align: center;
    -webkit-align-items: center;
    align-items: center;
    border-radius: 8px;
    color: #555;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    height: 58px;
    line-height: 14px;
    margin-top: 12px;
    width: 72px;
    &-icon {
      margin-top: 8px;
      width: 20px;
      height: 20px;
    }
    &-text {
      margin-top: 8px;
    }
  }
  &-line {
    background: #f8f8f8;
    height: 1px;
    margin-bottom: 12px;
    margin-top: 12px;
    width: 288px;
  }
  .account-set {
    align-items: center;
    color: #555;
    display: flex;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    &-item {
      margin-left: 44px;
      cursor: pointer;
      display: inline-block;
    }
    &-middle {
      width: 1px;
      height: 14px;
      background: #f8f8f8;
      margin-left: 44px;
    }
  }
}
.vip-menu-dropdown {
  background-image: url(https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/profile/vip-card-bg.png);
  box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.04), 0 4px 16px 4px rgba(0, 0, 0, 0.02),
    0 4px 12px 0 rgba(0, 0, 0, 0.02);
  background-size: cover;
  border-radius: 20px;
  box-sizing: border-box;
  height: 292px;
  overflow: hidden;
  padding-left: 30px;
  width: 364px;
  .title-line-1 {
    -webkit-text-fill-color: transparent;
    background: linear-gradient(315deg, #53f7e2, #bafca3 46%, #f2f07c 79%, #f9e555);
    -webkit-background-clip: text;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    height: 20px;
    line-height: 20px;
    margin-top: 30px;
  }
  .title-line-2 {
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    width: 144px;
  }
  .vip-desc-card-warpper {
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    background: linear-gradient(149deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.12));
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    height: 98px;
    justify-content: space-between;
    margin-top: 20px;
    padding: 8px;
    width: 304px;
    .privilege-card {
      align-items: center;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 82px;
      width: 88px;
      &-icon {
        height: 36px;
        margin-top: 12px;
        width: 36px;
      }
      &-text {
        color: #fff;
        display: block;
        font-size: 12px;
        line-height: 12px;
        margin-top: 10px;
        text-align: center;
      }
    }
  }
  .vips-btn {
    box-sizing: border-box;
    display: flex;
    margin-top: 20px;
    .learn-vip-btn-bg {
      background: linear-gradient(270deg, #ffc185, #fff3cf, #ffebbc);
      border-radius: 24px;
      box-shadow: 0 2px 12px 0 rgba(255, 195, 135, 0.36);
      box-sizing: border-box;
      height: 48px;
      margin-left: 12px;
      padding: 1px;
      width: 128px;
      &-a {
        background: #080d26;
        height: 46px;
        width: 126px;
        align-items: center;
        border-radius: 24px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        font-size: 16px;
        justify-content: center;
        &-span {
          color: #ffc285;
          font-weight: 500;
          line-height: 16px;
        }
      }
    }
    .open-vip-btn {
      background: linear-gradient(315deg, #ffdead, #ffbd7f 21%, #fff9d7 53%, #ffebba);
      color: #4c260e;
      font-weight: 500;
      height: 48px;
      margin-left: 24px;
      position: relative;
      width: 128px;
      align-items: center;
      border-radius: 24px;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      font-size: 16px;
      justify-content: center;
    }
  }
}
