.mid-content {
  padding-top: 180px;
  width: 988px;
  margin: 0 auto;

  &:before {
    display: block;
    content: '';
    width: 260px;
    height: 260px;
    background: #ff4f4f;
    opacity: 0.04;
    border-radius: 50%;
    position: absolute;
    right: -60px;
    top: 86px;
  }

  .indexButton {
    margin: 0 auto;
    display: block;
    line-height: 62px;
    width: 188px;
    background: #ff4f4f;
    box-shadow: 0 6px 24px -4px #ffa4a4;
    border-radius: 31px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
  }
}

.mid-content2 {
  display: flex;
  align-items: center;
  width: 1065px;
  padding-top: 134px;
  margin: 0 auto;
  width: 1020px;
  position: relative;

  .pic2 {
    width: 677px;
    height: 516px;
    margin-right: 62px;
    float: left;
    display: block;
    margin-left: -22px;
  }

  .text {
    flex: 1;
    position: relative;
    float: right;

    &-title {
      line-height: 34px;
      font-size: 30px;
      font-weight: 700;
      color: #404040;
    }

    .text-in {
      line-height: 23px;
      margin: 50px 0 74px;
      font-size: 14px;
      color: #606060;

      p {
        font-size: 14px;
        font-weight: 400;
        color: #404040;
        line-height: 16px;
        margin-bottom: 12px;

        img {
          width: 15px;
        }
      }
    }

    .indexButton {
      display: block;
      line-height: 62px;
      width: 188px;
      background: #ff4f4f;
      box-shadow: 0 6px 24px -4px #ffa4a4;
      border-radius: 39px;
      cursor: pointer;
      font-size: 20px;
      color: #fff;
      text-align: center;
    }
  }
}

.mid-content3 {
  width: 960px;
  margin: 0 auto 180px;
  padding-top: 180px;
  position: relative;

  &-title {
    line-height: 42px;
    font-size: 30px;
    font-weight: 700;
    color: #404040;
    text-align: center;
  }

  img {
    margin-top: 80px;
    width: 960px;
    height: 364px;
    -o-object-fit: contain;
    object-fit: contain;
    float: right;
    display: block;
  }
}

.header-content .header1 .indexButton,
.mid-content .indexButton,
.mid-indexButton,
.mid-content2 .text .indexButton {
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);

  &:hover {
    transform: translateY(-4px);
    transition: all 0.6s;
    background: hsl(27, 100%, 50%);
  }
}

a,
div,
p,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
}
