.index_content .content_box .btn_get {
    margin: 50px auto 0;
    cursor: pointer;
    width: 288px;
    height: 60px;
    line-height: 60px;
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#13D8A7, endColorstr=#00C2EF);
    background-image: -webkit-gradient(linear, left top, right top, color-stop(3%, #13d8a7), to(#00c2ef));
    background-image: linear-gradient(90deg, #13d8a7 3%, #00c2ef);
    border-radius: 30px;
    font-size: 26px;
    color: #fff;
    -webkit-box-shadow: 0 10px 20px rgb(19 216 167 / 30%);
    box-shadow: 0 10px 20px rgb(19 216 167 / 30%);
    -webkit-transition: all .2s ease-out 0s;
    transition: all .2s ease-out 0s;
    position: relative;
    z-index: 2;
    text-align: center;

    &:hover {
        transform: translateY(-6px);
        transition: all .4s;
    }
}

.content_fuwu {
    margin-top: -550px;
    position: relative;
    z-index: 2;

    .fuwu_card {
        padding: 10px 0 20px;
        overflow: hidden;
        max-width: 1100px;
        margin: 0 auto;

        li {
            float: left;
            width: 46%;
            text-align: center;
            font-size: 14px;
            margin: 20px 2% 0;
            padding: 0 10px 30px;
            background-color: #fff;
            border-radius: 50% 50% 0 0;
            min-height: 440px;
            -webkit-box-shadow: 0 -1px 10px rgb(0 0 0 / 10%);
            box-shadow: 0 -1px 10px rgb(0 0 0 / 10%);

            h4 {
                font-size: 24px;
                color: #222;
                text-align: center;
                line-height: 20px;
                margin-top: 38px;
            }

            p {
                margin-top: 25px;
                color: #888;
                min-height: 44px;
            }

            dl {
                padding-left: 12%;
                margin-top: -10px;

                dd {
                    padding: 10px 0 10px 30px;
                    text-align: left;
                    line-height: 13px;
                    position: relative;
                    overflow: hidden;
                    box-sizing: border-box;

                    .webicon-xuanze {
                        position: absolute;
                        left: 2px;
                        top: 7px;
                        font-size: 16px;

                        img {
                            width: 21px;
                            height: 21px;
                            color: #13d8a7;
                        }
                    }

                    span {
                        color: #ff3b00;
                    }
                }
            }

            .btn_get {
                width: 200px;
                height: 44px;
                line-height: 44px;
                font-size: 20px;
                margin-top: 30px;
            }
        }
    }
}