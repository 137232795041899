.resume {
  width: 100%;
  background-color: #f5f5f7;
  height: 800px;

  &-content {
    width: 1200px;
    margin: 0 auto;
    padding: 80px 0 80px;
    font-size: 100%;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    display: flex;
    &-left {
      width: 370px;
      list-style: none;
      border-radius: 5px;
      overflow: hidden;
      -webkit-box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
      box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
      background-color: #fff;
      height: 600px;
      margin-top:10px;
      .resumeHeader {
        height: 80px;

        &-btn {
          text-align: center;
          margin: 0 auto;
          width: 100%;
          height: 85%;
          padding-top: 20px;

          button {
            width: 45%;
            height: 100%;
            font-size: larger;
            border-radius: 5px;
          }

          &-left {
            background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
            border-width: 0;
            margin-right: 6px;

            span {
              color: #fff;
              font-weight: bold;
            }

            &:hover {
              background-image: linear-gradient(90deg, #ffa16b, #fe6060);
            }
          }

          &-right {
            background: #fff;
            margin-left: 9px;
            border-width: 1px;
            border-style: solid;
            border-color: #ff3d3d;

            &-img {
              width: 20px;
              height: 20px;
            }

            span {
              color: #ff813a;
              font-weight: bold;
            }

            &:hover {
              background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
              border-width: 0;
              span {
                color: #fff;
              }
            }
          }

          img {
            width: 20px;
            height: 20px;
            margin-right: 7px;
            margin-top: -2px;
          }
        }
      }

      .resumeTab {
        margin: 0 15px;
        position: relative;
        height: 500px;

        .resumeCard {
          position: relative;
          width: 98%;
          height: 85px;
          border-radius: 8px;
          cursor: pointer;
          background: url(https://636c-cloud1-7gqksuyw589b951e-1310768562.tcb.qcloud.la/youjia/resume/background.jpeg?sign=36a2bf0247578fcf3b0c749b52e48dfb&t=1652179567) no-repeat 20% / cover;
          margin: 0 auto;
          margin-bottom: 10px;
          &-fileImg{
            position:absolute;
            width: 50px;
            height: 50px;
            left:10px;
            top: 10px;
          }
          &-on {
            float: right;
            width: 80%;
            margin-top: 13px;

            &-name {
              font-weight: bold;
            }

          }

          &-down {
            float: right;
            width: 80%;

            &-score {
              display: inline;
            }

            &-language {
              display: inline;
            }

            &-time {
              margin-top: 2px;
              color: #868686;
              font-size: smaller;
              float: right;
              margin-right: 20px;
            }
          }
          &-choose{
            display: inline-block;
            position:absolute;
            bottom: 5px;
            margin-left:20px;
            a{
              color: #ff6e1a;
              font-size: smaller;
              img{
                width: 11px;
                height: 11px;
                margin-right:2px;
                margin-bottom: 2px;
              }
            }
            a:nth-child(n+2){
              margin-left:25px;
            }
          }
          &:hover {
            box-shadow: 0 0 8px rgb(218, 218, 218);
          }
        }

        .bottom-img {
          width: 100%;
          position: absolute;
          bottom: 0;
          cursor: pointer;
        }
      }
    }

    &-right {
      width: 872px;
      overflow: visible;
      margin-top:10px;
      margin-left: 15px;
      &-analyseCard{
        width: 100%;
        height: 150px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        overflow: visible;
        &-children{
          cursor: pointer;
          border-radius: 5px;
          width: 49.2%;
          height: 95%;
          background-color: #ffffff;
          padding: 20px;
          &:hover{
            box-shadow: 0 0 10px rgb(212, 212, 212);
          }
          .analyseCard-header{
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            padding-bottom: 10px;
            border-bottom: 1px solid #f9f9f9;
            &-icon{
              width: 20px;
              height: 20px;
              border-radius: 5px; 
            }
            span{
              margin-left: 5px;
              font-size:14px;
            }
            &-btn{
              cursor: pointer;
              &:link,&:visited{
                text-transform: uppercase;
                text-decoration: none;
                color: rgb(98, 98, 98);
                font-size: small;
                font-weight: 400;
                padding: 3px 15px;
                border: 1px solid rgb(175, 175, 175);
                border-radius: 1000px;
                display: inline-block;
                transition: all .2s;
                position: absolute;
                right: 0;
              }
              
              &:hover{
                transform: translateY(-2px);
                box-shadow: 0 3px 7px rgba(101, 101, 101, 0.5);
                &::after{
                  background-color: rgb(255, 180, 106);
                  transform: scaleX(1.2) scaleY(1.3);
                  opacity: 0;
              }
              }
              &:active{
                  transform: translateY(-3px);
              }
              &::after{
                content: "";
                display: inline-block;
                height: 100%;
                width: 100%;
                border-radius: 100px;
                top: 0;
                position: absolute;
                right: 0;
                z-index: -1;
                transition: all .3s;
              }
            }
          }
          .analyseCard-content{
            margin-top: 12px;
            width: 100%;
            height: 100%;
            margin-left: 2px;
            &-image{
              float: left;
              width: 60px;
              height: 60px;
            }
            &-describe{
              margin-left:80px;
              padding-top:5px;
              &-state{
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 3px;
              }
              &-text{
                font-size: 14px;
                font-weight: 400;
                color: #606060;
              }
            }
          }
        }
      }
      .recommend{
        width: 100%;
        height: 400px;
        border-radius: 5px;
        background: #fff;
        margin-top: 7px;
        padding: 20px;
        &-title{
          display: flex;
          align-items: center;
          font-size: 14px;
          line-height: 14px;
          color: #404040;
          font-weight: 400;
          position: relative;
          margin-bottom: 20px;
        }
        &-box{
          display: flex;
          &-img{
            position: relative;
            width: 242px;
            height: 322px;
            border-radius: 4px;
            margin-right: 20px;
            cursor: pointer;
            &-resume{
              width: 100%;
              height: 100%;
              object-fit: cover;
              border: 1px solid #ececec;
              border-radius: 4px;
            }
          }
          &-info{
            flex:1;
            &-title{
              display: flex;
              align-items: center;
              margin-bottom: 6px;
              h6{
                font-size: 18px;
                font-weight: 500;
                color: #404040;
                line-height: 25px;
                margin-right: 8px;
                margin-bottom: 0;
              }
              span{
                font-size: 12px;
                font-weight: 400;
                color: #f64;
                line-height: 12px;
                padding: 4px 8px;
                background: #fff6f4;
                border-radius: 10px;
              }
            }
            &-text{
              font-size: 14px;
              font-weight: 400;
              color: #606060;
              line-height: 20px;
              margin-bottom: 16px;
            }
            &-content{
              padding: 16px 16px 18px;
              background: #f9f9f9;
              margin-bottom: 20px;
              border-radius: 20px 0 20px 0;
              &-title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                &-header{
                  &-img{
                    width: 22px;
                    height: 22px;
                    object-fit: cover;
                    border-radius: 50%;
                    margin-right: 8px;
                  }
                  span{
                    font-size: 12px;
                    font-weight: 500;
                    color: #404040;
                    line-height: 17px;
                  }
                }
                &-img{
                  width: 28px;
                }
              }
              &-text{
                font-size: 12px;
                font-weight: 400;
                color: #606060;
                line-height: 17px;
              }

            }
            &-btn{
              display: flex;
              align-items: center;
              position: relative;
              top: 10px;
              button{
                width: 94px;
                height: 36px;
                padding: 0;
                font-size: 14px;
                font-weight: 500;
                border-radius: 4px;
                margin-right: 12px;
              }
              &-cn{
                background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
                border-width: 0;
                margin-right: 6px;
                color: #f5f5f7;
                &:hover {
                  background-image: linear-gradient(90deg, #ffa16b, #fe6060);
                  box-shadow: 3px 3px 10px #ffeded;
                  transform: translateY(-1px);
                }
              }
              &-en{
                border-width: 1px;
                border-style: solid;
                border-color: #ff3d3d;
                color: #ff813a;
                background: #fff;
                font-weight: bold;
                &:hover {
                  box-shadow: 3px 3px 10px #ffd3ba;
                  transform: translateY(-1px);
                }
              }
              &-right{
                position: absolute;
                right: 0;
                &-server{
                  background: linear-gradient(50deg,#ffeed5,#ffc87b);
                  padding: 0.35em 0;
                  font-size: 17px;
                  border: none;
                  color: #462a03;
                  font-weight: 500;
                  border-radius: 1.2em;
                  letter-spacing: 0.08em;
                  display: flex;
                  position: relative;
                  align-content: center;
                  align-items: center;
                  overflow: hidden;
                  height: 2.6em;
                  padding-left: 2.8em;
                  padding-right: 0.9em;
                  font-size: 13px;
                  &-icon{
                    background: #fff;
                    height: 2em;
                    width: 2em;
                    border-radius: 2em;
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0.4em;
                    transition: all 0.5s; 
                    img{
                      transition: all 0.5s; 
                    }
                  }  
                  &:hover 
                    .recommend-box-info-btn-right-server-icon{
                    width: calc(100% - 0.8rem);
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
