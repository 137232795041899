.vip-modal {
  .ant-modal {
    box-shadow: 0 4px 15px 0 #e8e8e8;
    border-radius: 20px;
  }
  .ant-modal-header {
    padding: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom: 0px;

    .vip-model-header {
      height: 116px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      background-image: url(https://static.nowcoder.com/fe/file/oss/1660807988193WFMQM.png);
      background-size: 100% 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      height: 116px;
      padding-left: 30px;
      padding-top: 24px;
      align-items: center;
      .vip-modal-header-v {
        display: flex;
      }
      .el-image {
        margin-right: 8px;
      }
      .tw-text-whitle {
        color: #fff;
        .tw-text-name {
          font-size: 16px;
          line-height: 24px;
        }
        .tw-text-desc {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
  .ant-modal-content {
    border-radius: 20px;
    .ant-modal-close-x {
      color: #fff;
    }
    .ant-modal-body {
      padding: 0;
    }
    .vip-dialog-body {
      border-radius: 20px;
      margin-top: -20px;
      position: relative;
      -webkit-box-sizing: border-box !important;
      box-sizing: border-box !important;
      height: 455px !important;
      padding: 16px;
      background-color: #f5f6fa;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }
    .vip-content-left {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 608px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 8px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      .vip-options {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 191px;
        padding: 25px 16px 16px 16px;
        background: #fff;
        border-radius: 8px;
        &-item {
          width: 110px;
          height: 150px;
          background: #f5f6fa;
          border-radius: 8px;
          color: #404040;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          font-weight: 400;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          .position-bottom {
            width: 110px;
            height: 28px;
            border-radius: 0 0 8px 8px;
            bottom: 0;
            webkit-box-sizing: border-box;
            box-sizing: border-box;
            background: linear-gradient(132deg, #fff2e2, #e3c8a9);
            color: #3b3f57;
            font-weight: 500;
            font-size: 12px;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: absolute;
          }
          .recommend-final-vip-layout {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            background: linear-gradient(132deg, #fff2e2, #e3c8a9);
            color: #3b3f57;
            font-weight: 500;
            font-size: 12px;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            position: absolute;
            width: 43px;
            height: 22px;
            border-radius: 10px 0 10px 0;
            top: -12px;
            left: 0;
          }
          > .time {
            height: 14px;
            margin-top: 28px;
            line-height: 14px;
            margin-bottom: 14px;
            font-size: 14px;
            font-weight: 500;
            color: #404040;
            cursor: pointer;
            &.active {
              color: #efdfc9;
            }
          }
          .pic-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            height: 24px !important;
            margin-bottom: 12px;
          }
          .pic-tip {
            padding-top: 4px;
            padding-right: 4px;
            font-size: 14px;
          }
          .pic-text {
            line-height: 24px;
            text-align: center;
            font-size: 24px;
            font-weight: 600;
          }
          .old-pic {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            font-weight: 300;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            position: relative;
            height: 12px;
            font-size: 12px;
            color: #9c9c9c;
          }
          &.active {
            background: #272742;
            color: #efdfc9 !important;
          }
          &:last-child {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            width: 210px;
            border: 1px solid #d6a77a;
            background: #fcf7f2;
            &.active {
              background: #272742;
              color: #efdfc9 !important;
            }
          }
          .pic-explain {
            height: 12px;
            font-size: 12px;
            font-weight: 600;
            color: #c6884c;
            line-height: 12px;
            margin-top: 11px;
            &.active {
              color: #efdfc9;
            }
          }
        }
      }
      &-bottom {
        height: 216px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background: linear-gradient(44deg, #fff6f4, #fff);
        border-radius: 8px;
        overflow: hidden;
        padding: 20px 0 20px 0;
        .vip-icon-container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          flex-flow: wrap;
        }
        .kind {
          &.opcity {
            opacity: 0.4;
          }
          .div-img {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            > img {
              width: 50px;
              height: 50px;
            }
          }
          > p {
            display: block;
            height: 14px;
            width: 100px !important;
            margin: 4px 0;
            font-weight: 500;
            color: #404040;
            text-align: center;
            -webkit-transform: scale(0.8);
            transform: scale(0.8);
          }
          > span {
            display: block;
            width: 100%;
            text-align: center;
            -webkit-transform: scale(0.7);
            transform: scale(0.7);
            font-size: 8px;
            font-weight: 400;
            color: #9c9c9c;
          }
        }
        .svip-container {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          .left {
            width: 162px;
            height: 186px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          .and {
            margin: 0 32px 0 31px;
            height: 20px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          .right {
            width: 308px;
            height: 174px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .vip-content-right {
      border-radius: 8px;
      height: 423px;
      .pay-fixed {
        position: relative;
        width: 182px;
        height: 423px;
        background: #fff;
        border-radius: 8px;
        .pay-title {
          width: 182px;
          height: 42px;
          text-align: center;
          line-height: 42px;
          font-style: 14px;
          font-weight: 400;
          color: #282b42;
          background: #ecedf2;
          border-radius: 8px 8px 0 0;
        }
        .pay-comps {
          cursor: pointer;
          position: relative;
          margin: 0 auto;
          height: 32px;
          width: 70px;

          > a {
            text-decoration: underline;
            color: #f64;
          }
        }
        .pay-QRcode {
          width: 112px;
          height: 112px;
          position: relative;
          margin: 16px auto 24px;
          .Qrcode {
            width: 120px;
            height: 121px;
            margin-right: 18px;
            position: relative;
            top: 10px;
            .Qr-mask {
              position: absolute;
              top: -5px;
              left: -5px;
              width: 130px;
              height: 132px;
              background-color: rgba(98, 95, 95, 0.818);
              justify-content: center;
              padding: 40px 20px;
              text-align: center;
            }
          }
        }
        .pay-success {
          width: 100%;
          height: 200px;
          padding: 80px 16px;
          .ant-result-success {
            padding: 0;
          }
          .ant-result-title {
            font-size: 20px;
          }
          .ant-result-success .ant-result-icon {
            margin-bottom: 5px;
            & > .anticon {
              color: #52c41a;
              font-size: 25px;
            }
          }
        }
        .pay-type {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          &-img {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            > img {
              width: 19px;
              height: 18px;
              margin-right: 10px;
            }
            .pay-icon {
              margin-top: 10px;
            }
          }
        }
        .pay-price {
          text-align: center;
          font-size: 24px;
          color: #f64;
          font-weight: 600;
          margin-top: 10px;
          margin-bottom: 16px;
        }
        .pay-success-btn {
          -webkit-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          button {
            font-size: 18px;
            display: inline-block;
            outline: 0;
            border: 0;
            cursor: pointer;
            will-change: box-shadow, transform;
            background: radial-gradient(100% 100% at 100% 0%, #ff813a 0%, #ff3d3d 100%);
            box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%),
              0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%),
              inset 0px -0.01em 0px rgb(58 65 111 / 50%);
            padding: 0 2em;
            border-radius: 0.3em;
            color: #fff;
            height: 2.6em;
            text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
            transition: box-shadow 0.15s ease, transform 0.15s ease;
          }

          button:hover {
            box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
              0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #ff3d3d;
            transform: translateY(-0.1em);
          }

          button:active {
            box-shadow: inset 0px 0.1em 0.6em #ff3d3d;
            transform: translateY(0em);
          }
        }
      }
    }
  }
}
