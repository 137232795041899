.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 200px;
  transition: all 0.2s ease;

  .y-input {
    .ant-input-affix-wrapper {
      border: 1px solid #f3f4f6;
    }
    .Yinput {
      border-radius: 5px;
      color: rgb(17 24 39);
      background-color: #f3f4f6;
    }
  }
}
.ant-form-item-control-input:hover {
  width: 210px;
}
