.layout-wrapper {
  position: relative;
  .layout-header {
    position: fixed;
    z-index: 3;
    width: 100%;
    padding: 0;
    padding-right: 24px;
    background-color: white;
    box-shadow: 0 5px 21px 0 rgb(78 78 78 / 5%);
    .logo-wrapper img {
      margin-left: 16px;
      cursor: pointer;
    }
    .ant-input-affix-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      min-height: 32px;
      border-radius: 5px;
      width: 250px;
      background-color: #f3f4f6;
      transition: all 0.2s ease;
      border: 1px solid #f3f4f6;
      .ant-input {
        background-color: #f3f4f6;
        transition: all 0.2s ease;
        border: 1px solid #f3f4f6;
      }
      &-focused {
        box-shadow: 0 0 0 2px rgba(254, 144, 0, 0.3);
      }
    }
    .ant-input-affix-wrapper:hover {
      width: 300px;
    }
  }
  .layout-content {
    position: relative;
    //     margin: 0 208px;
    padding: 24px 24px;
    margin-top: 64px;
  }

  .side-collap {
    padding: 12px;
    border-top: 1px solid #f0f0f0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: right;
  }
  .trigger {
    font-size: 16px;
  }
  .trigger:hover {
    color: #1890ff;
  }
  .ant-menu-horizontal {
    border: none;
  }

  .page-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 24px 24px 0;
    overflow-x: hidden;
  }
  .layout-sider {
    z-index: 1;
    position: relative;
    &-left {
      left: 0;
      top: 64px;
      height: 100vh;
      overflow: auto;
      position: fixed;
      z-index: 1;
      width: 120px;
      min-width: 120px;
      transition: all 0.3s;
      &-menuItem {
        width: 100%;
        height: 90px;
        padding-left: 10px;
      }
      .menuItem-box {
        width: 75px;
        height: 75px;
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
        color: #333;
        cursor: pointer;
        transition: background 0.2s;
        margin-top: 5px;
        padding-top: 10px;
        a {
          width: 10px;
          height: 20px;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .menuItem-box:hover {
        background-color: rgba(254, 144, 0, 1);
      }
    }
    &-right {
      right: 0;
      top: 64px;
      height: 100vh;
      position: fixed;
      z-index: 1;
      width: 120px;
      background: rgba(254, 144, 0, 0);
      min-width: 120px;
      transition: all 0.3s;
      .ant-menu {
        background: rgba(254, 144, 0, 0);
      }
      &-menuItem {
        width: 100%;
        height: 90px;
        padding-right: 10px;
      }
      .menuItem-box {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        overflow: hidden;
        text-align: center;
        color: #fff;
        cursor: pointer;
        transition: background 0.2s;
        margin-top: 5px;
        padding-top: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(20px);
        opacity: 1;
        transition: all 0.4s;

        .menuItem-text {
          width: 100%;
          height: 35px;
          font-size: 13px;
          font-weight: 500;
          position: absolute;
          top: 13px;
          right: 3px;
          opacity: 0;
        }
        .menuItem-text:hover {
          opacity: 1;
        }
      }
      .menuItem-box:hover {
        background-color: rgba(0, 0, 0, 0.8);
        box-shadow: 0 5px 21px 0 rgba(224, 162, 26, 0.05);
      }
    }
  }
}
.pdf-confirm {
  &-box {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    &-name {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;
      &-tips {
        font-size: 9px;
        color: #c7c7c7;
        padding: 5px;
      }
      .ant-input-affix-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 32px;
        border-radius: 5px;
        width: 250px;
        background-color: #f3f4f6;
        transition: all 0.2s ease;
        border: 1px solid #f3f4f6;
        .ant-input {
          background-color: #f3f4f6;
          transition: all 0.2s ease;
          border: 1px solid #f3f4f6;
        }
        &-focused {
          box-shadow: 0 0 0 2px rgba(254, 144, 0, 0.3);
        }
      }
      .ant-input-affix-wrapper:hover {
        width: 300px;
      }

      .ant-radio-checked .ant-radio-inner {
        border-color: #ff4906;
        &::after {
          background: #ff4906;
        }
      }
    }
  }
}
