@font-face {
  font-family: '思源黑体 Medium';
  font-weight: 500;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/q7_PsTQyPvO135qdVeP8B.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/1blCc4F0p92H-ThpSMCxm.woff') format('woff');
  font-display: swap;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */

@font-face {
  font-family: MicrosoftYaHei;
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/IugGDA0sxXGyx6Me8DftE.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/bbkOTmCuycOXH0W885hQo.woff') format('woff');
  font-display: swap;
}
.mid-page {
}
.mid-content {
  padding-top: 70px;
  width: 988px;
  margin: 0 auto;

  .mid-tit {
    line-height: 42px;
    font-size: 30px;
    font-weight: 700;
    color: #404040;
    font-family: '思源黑体 Medium' !important;
    text-align: center;
  }

  .mid-list {
    margin: 77px 0 408px;

    .clear-list {
      position: relative;
      width: 205px;
      padding: 13px 14px 0;
      height: 268px;
      box-shadow: 0 10px 36px 0 hsl(0deg 0% 87% / 50%);
      border-radius: 4px;
      margin: 0 21px;
      background-color: #fff;
      float: left;
      &:hover {
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
        .card-button {
          transform: translate(-50%, 50%);
          opacity: 1;
        }
      }

      .lottie-anima,
      .pic {
        width: 177px;
        height: 104px;
      }

      img {
        display: block;
      }

      .f-tit {
        line-height: 18px;
        margin: 34px 0 17px;
        font-size: 18px;
        font-weight: 700;
        color: #404040;
        text-align: center;
      }

      .text {
        line-height: 20px;
        margin: 0 2px;
        font-size: 14px;
        color: #606060;
      }
    }
    .card-button {
      transform: translate(-50%, 125%);
      width: 60%;
      border-radius: 1rem;
      border: none;
      background: linear-gradient(90deg, #ff813a, #ff3d3d);
      color: #fff;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      position: absolute;
      left: 50%;
      bottom: 0;
      opacity: 0;
      transition: 0.3s ease-out;
    }
  }
}
