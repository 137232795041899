.caseIndex {
    width: 1032px;
    margin: 180px auto 0;
    height: 468px;

    .casetit {
        line-height: 42px;
        margin-bottom: 98px;
        font-size: 38px;
        font-weight: 700;
        color: #404040;
        text-align: center;
    }

    .clearfix {
        display: flex;
        justify-content: center;
        align-items: center;
        &-item {
            width: 306px;
            height: 418px;
            display: block;
            position: relative;
            padding-top: 38px;
            .item-header {
                &-title {
                    line-height: 28px;
                    margin-bottom: 14px;
                    padding-left: 114px;
                    display: flex;
                    align-items: center;
                    font-size: 26px;
                    font-weight: 700;
                    color: #404040;
                }
        
                &-describe {
                    bottom: 0;
                    text-align: center;
                    line-height: 18px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 14px;
                    font-weight: 700;
                    color: #9c9c9c;
                }
            }
            .item-icon {
                width: 120px;
                height: 120px;
                margin: 36px auto;
            
                .icon {
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
}

.itemList {
    opacity: 1;
    line-height: 28px;
    padding-left: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #404040;

    .list::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        position: relative;
        top: 6px;
        background:
            url(https://mxm1923893223-ulteh-1302287111.tcloudbaseapp.com/modify_icon/right2.png) no-repeat;
        background-size: 60%;
        background-position: center;
    }

    .contentActive {
        width: 95%;
        margin: 0 auto;
        transform: translateY(-115px);
        margin-bottom: 25px;
    }


}

.mid-indexButtons {
    margin: auto;
    display: block;
    line-height: 62px;
    width: 188px;
    background: #ff4f4f;
    box-shadow: 0 6px 24px -4px #ffa4a4;
    border-radius: 31px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    position: absolute;
    left: 40px;
    bottom: 30px;
}