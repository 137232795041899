.recruit-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 2;
  color: #ffff;
}
.recruit-item {
  display: flex;
  width: 100%;
  flex-flow: wrap;
  position: relative;

  &-code {
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
      Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 10px;
  }
  &-date {
    display: flex;

    color: #909090;
  }
  &-btn {
    display: flex;
    justify-content: space-between;
  }
  &-website {
    margin-left: 12px;
    flex: none;
    font-size: 14px;
    font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
    text-align: left;
    color: #8c8c8c;
    line-height: 21px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .state {
    display: inline-block;
    &-icon {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
      margin-top: 1px;
    }
  }
}
.ant-card-bordered {
  border-radius: 0.4em;
  transition: border 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border: rgb(250, 250, 250) 0.1em solid;
}
.ant-card-bordered:hover {
  box-shadow: 0.3em 0.3em 0.7em #00000015;
}
.ant-typography {
  margin-bottom: 0px;
}
.ant-avatar {
  width: 43px;
  height: 43px;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0px;
}
