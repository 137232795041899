.header-content {
  display: flex;
  padding-top: 50px;
  width: 988px;
  margin: 0 auto;
  margin-top: 70px;

  &:before {
    display: block;
    content: '';
    width: 150px;
    height: 150px;
    background: #ff4f4f;
    opacity: 0.04;
    border-radius: 50%;
    position: absolute;
    left: -54px;
    top: 100px;
  }

  .header_img {
    width: 602px;
    height: 495px;
    margin-right: -102px;
    float: right;
    display: block;
  }
}
.modal-title{
  width: '100%';
  cursor: 'move';
  text-align: center;
  align-items: center;
}
.pr {
  position: relative;
}

.header-content .header1 {
  padding-bottom: 32px;
  padding-left: 20px;
  margin: 120px 0 0 -20px;
  box-flex: 1;
  flex: 1;
  overflow: hidden;
  position: relative;
  float: left;

  .tit1 {
    line-height: 38px;
    margin-bottom: 26px;
    font-size: 38px;
    font-weight: 700;
    color: #404040;
  }

  p {
    font-size: 18px;
    font-weight: 400;
    color: #404040;
    line-height: 36px;

    span {
      font-weight: 500;
      color: #ff4f4f;
    }
  }

  .indexButton {
    margin-top: 34px;
    display: block;
    height: 62px;
    width: 188px;
    background: #ff4f4f;
    box-shadow: 0 6px 24px -4px #ffa4a4;
    border-radius: 31px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
    width: 240px;
    border-width: 0px;
  }
}
