@text-color: black;
@startuse: Startuse-home-page;
.@{startuse}-wrapper {
  width: 100%;
  position: relative;
  overflow: hidden;
  .@{startuse} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .Startuse-title-wrapper {
    margin: 0 auto 45px;
    text-align: center;
  }
  .Startuse-title-wrapper > h1,
  > h1 {
    font-size: 42px;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei,
      Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    color: black;
    margin-bottom: 16px;
  }
}
.content-button {
  letter-spacing: 0.5px;
  .Startuse-button {
    width: 264px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    background: -webkit-gradient(linear, left top, right top, from(#ff813a), to(#ff3d3d));
    background: linear-gradient(90deg, #ff813a, #ff3d3d);
    box-shadow: 0 10px 18px -1px rgb(255 94 59 / 35%);
    border-radius: 33px;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
    margin: 30px auto 112px;
    cursor: pointer;
  }
}
.Startuse-button:hover {
  background: linear-gradient(90deg, #61ff3a, #3d91ff);
}

.@{startuse} {
  padding: 10px 24px;
}
