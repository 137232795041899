.home {
  height: 100%;
  width: 100%;
  overflow: auto;
}

body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum', 'tnum';
  font: 12px/1.5 Helvetica Neue, Arial, PingFang SC, Microsoft YaHei, SimSun, sans-serif;
  background-color: #fff;
  color: #333;
}

input,
samp,
textarea {
  font-family: Helvetica Neue, Arial, PingFang SC, Microsoft Yahei, SimSun, sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
}

*,
:after,
:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

div,
p,
textarea {
  box-sizing: border-box;
}

li,
ul {
  list-style: none;
}

dl {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.banner {
  padding: 130px 0 120px;
  text-align: center;
  position: relative;
  height: 700px;
  margin-top: 70px;

  .banner_bg {
    background: url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic.616pic.com%2Fbg_w1180%2F00%2F13%2F23%2FWjzkkY0eMd.jpg&refer=http%3A%2F%2Fpic.616pic.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642232967&t=d18b07220e418021faa673e94e46ec41)
      30% no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.22;
  }

  h2 {
    font-size: 40px;
    color: #222;
    line-height: 42px;
    position: relative;
    font-weight: 700;
    z-index: 2;
  }

  .text {
    font-size: 18px;
    color: #888;
    line-height: 24px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
  }
}

.index_content .content_box .btn_get {
  margin: 50px auto 0;
  cursor: pointer;
  width: 288px;
  height: 60px;
  line-height: 60px;
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#13D8A7, endColorstr=#00C2EF);
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(3%, #13d8a7),
    to(#00c2ef)
  );
  background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
  border-radius: 30px;
  font-size: 26px;
  color: #fff;
  -webkit-box-shadow: 0 10px 20px rgba(194, 144, 35, 0.3);
  box-shadow: 0 10px 20px rgba(218, 162, 43, 0.3);
  -webkit-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
  position: relative;
  z-index: 2;
  text-align: center;
}

.float_r {
  display: block;
  position: fixed;
  box-sizing: border-box;
  font-size: 13px;
  color: rgb(255, 255, 255);
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  z-index: 1000000000;
  width: 64px;
  border-radius: 4px;
  right: 0px;
  bottom: 0px;
  height: 64px;
  margin-bottom: 80px;

  ul {

    img {
      width: 40px;
      height: 37px;
      margin-left: 6px;
      margin-top: 5px;
    }

    .gotop img {
      width: 39px;
      height: 32px;
      margin-left: 6px;
      margin-top: 11px;
    }

    li {
      margin-top: 20px;
      width: 52px;
      height: 52px;
      background-color: #ff7220;
      position: relative;
      color: #fff;
      cursor: pointer;

      i {
        display: block;
        line-height: 52px;
        text-align: center;
        font-size: 36px;
      }

      p {
        position: absolute;
        right: 52px;
        top: 0;
        height: 52px;
        overflow: hidden;
        text-align: center;
        color: #fff;
        background-color: #ff8741;
        line-height: 52px;
        font-size: 14px;
        border-radius: 30px 0 0 30px;
        padding-left: 20px;
        width: 0;
        transition: all 0.2s ease-out 0s;
      }

      &:hover {
        background-color: #ff8741;
        transition: all 0.2s ease-out 0s;

        p {
          width: 100px;
        }
      }
    }
  }
}
