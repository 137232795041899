.problem-content {
    height: 563px;
    margin: 180px 0 75px;
    overflow: hidden;

    &:before {
        display: block;
        content: "";
        width: 3000px;
        height: 563px;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -3144px;
        background: #fff8f8;
    }

    .content {
        width: 1016px;
        margin: 0 auto;
        height: 563px;

        .left-tab {
            width: 364px;
            height: 563px;
            float: left;

            .lefttit {
                width: 100%;
                height: 34px;
                margin: 36px 0;
                font-size: 30px;
                font-weight: 700;
                color: #404040;
                text-align: center;
            }

            .Active {
                background-color: #fff;
            }

            .tab-cursor {
                width: 364px;
                height: 110px;
                margin-bottom: 32px;
                border-radius: 8px 0 0 8px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon img {
                    width: 50px;
                    height: 50px;
                    margin: 0 25px 0 72px;
                }

                img {
                    display: block;
                }

                .flex1 {
                    flex: 1;
                    overflow: hidden;

                    &-title {
                        line-height: 22px;
                        margin-bottom: 10px;
                        font-size: 20px;
                        font-weight: 700;
                        color: #404040;
                        padding-top: 0;
                    }

                    &-text {
                        font-size: 14px;
                        color: #404040;
                    }
                }
            }
        }

        .pic {
            width: 600px;
            height: 540px;
            float: right;

            img {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
}

.mid-indexButton {
    margin: auto;
    display: block;
    line-height: 62px;
    width: 188px;
    background: #ff4f4f;
    box-shadow: 0 6px 24px -4px #ffa4a4;
    border-radius: 31px;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    text-align: center;
}