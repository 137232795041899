@template-bg-color: #001529;
@template-bg-color-light: #ececec;
@template-text-color: #ccc;
@template-text-title-color: #bcbcbc;
@template-text-color-light: #fff;
@template-footer-text-color: #999;
@text-color: #999;
@primary-color: #ff3d3d;
@homepage: footer;
.@{homepage}-wrapper {
  width: 100%;
  position: relative;
  //   overflow: hidden;
  .@{homepage} {
    height: 100%;
    max-width: 1200px;
    position: relative;
    margin: auto;
    will-change: transform;
  }
  .title-wrapper > h1,
  > h1 {
    font-size: 32px;
    color: @text-color;
    margin-bottom: 16px;
  }
  .title-wrapper {
    margin: 0 auto 64px;
    text-align: center;
  }
}

.@{homepage} {
  padding: 128px 24px;
}
.footer1-wrapper {
  background: @template-bg-color;
  overflow: hidden;
  position: relative;
  min-height: 360px;
  color: @template-footer-text-color;
  .footer1 {
    .footer {
      padding: 64px 24px 20px;
    }
  }
  .block {
    padding: 0 32px;
    .logo {
      max-width: 180px;
    }
    .slogan {
      font-size: 12px;
      margin-top: -20px;
    }
    > h2 {
      margin-bottom: 24px;
      color: @template-text-color;
    }
    a {
      color: @template-footer-text-color;
      margin-bottom: 12px;
      float: left;
      clear: both;
      &:hover {
        color: @primary-color;
      }
    }
  }
  .copyright-wrapper {
    width: 100%;
    //border-top: 1px solid fade(@line-color, 10);
    .footer {
      padding: 0 24px;
      overflow: hidden;
    }
    .copyright {
      height: 80px;
      text-align: center;
      line-height: 80px;
    }
  }
}
.flooter-img-box {
  display: flex;
  flex-direction: row;
  width: 1047px;
  margin: 0 auto;
  justify-content: space-around;
  margin-top: 30px;
  &-item {
    width: 100px;
    height: 120px;
    text-align: center;
    > img {
      height: 100px;
      width: 100px;
      margin-bottom: 5px;
    }
  
  }
}

@media screen and (max-width: 767px) {
  .footer1 {
    min-height: 550px;
    &-wrapper {
      .footer1 {
        .footer {
          padding: 64px 24px 32px;
        }
      }
    }
    .logo {
      margin: 0 auto 24px;
    }
    .block {
      text-align: center;
      margin-bottom: 32px;
      padding: 0;
    }
    > ul {
      width: 90%;
      margin: 20px auto 0;
      padding: 10px 0;
      > li {
        width: 100%;
        h2 {
          margin-bottom: 10px;
        }
        li {
          display: inline-block;
          margin-right: 10px;
        }
      }
    }
    .copyright {
      &-wrapper {
        .footer {
          padding: 0;
          .copyright {
            font-size: 12px;
          }
        }
      }

      span {
        width: 90%;
      }
    }
  }
}
