.alert-main {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    .import-resume {
        width: 600px;
        height: 332px;
        top: 50%;
        transform: translate(-4%, -42%);
        background: #fff;
        border-radius: 6px;
        -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
        text-align: center;
        padding-top: 2px;

        .tips {
            width: 512px;
            height: 170px;
            background: #f9f9f9;
            border-radius: 6px;
            border: 2px dashed #cfcfcf;
            margin: 44px auto 30px;

            .title{
                font-size: 18px;
                color: #404040;
                margin-top: 56px;
                line-height: 50px;
                padding-top: 8px;
                font-weight: 700;
                text-align: center;
            }
            .msg {
                color: #9c9c9c;
                margin-top: 16px;
                margin-bottom: 24px;
            }
        }

        .upload-resume {
            width: 600px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: transparent;

            .ele-upload {
                width: 100%;
                height: 100%;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                outline: 0;

                .ele-upload-dragger {
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    box-sizing: border-box;
                    text-align: center;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;

                    .upload-btn {
                        margin-top: 250px;
                        width: 207px;
                        height: 46px;
                        background: #ff7a41;
                        border-radius: 4px;
                        border: none;
                        font-size: 16px;
                        line-height: 33px;
                        cursor: pointer;
                        &:hover {
                            transform: translateY(-2px);
                            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
                        }
                    }
                }
                .ele-upload-input {
                    display: none;
                }
            }
        }
    }
}