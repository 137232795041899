.ant-form-item-control-input {
 
  display: flex;
  align-items: center;
  min-height: 32px;
  width: 200px;
  transition: all 0.2s ease;
  .y-select {
    .ant-select-selector {
      border-radius: 5px;
      color: rgb(17 24 39);
      background-color: #f3f4f6;
      border: 1px solid #f3f4f6;
    }
    .ant-select-selection-placeholder {
      text-align: left;
    }
    .ant-select-selection-item {
      text-align: left;
    }
    
  }
}
.ant-form-item-control-input:hover {
  width: 210px;
}
