#Nav3_0.jzih1dpqqrg-editor_css {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.ant-menu-item > .item-sub-item > .jzj81c9wabh-editor_css {
  float: none;
  margin: 4px 0px 0px;
}
.ant-menu > .ant-menu-item > .jzj8295azrs-editor_css {
  padding: 0;
}
#Feature0_0 .ant-col > .content0-block-item > .jzj8xt5kgv7-editor_css {
  font-size: 18px;
}
#Nav3_0 .home-page-mobile > .jzjgnya1gmn-editor_css {
  width: 110px;
}
#Feature0_0 .ant-row > .ant-col > .content0-block-item {
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .content0-block-icon {
  width: 10px;
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .jzjgrlz134-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjgrlz134-editor_css {
  width: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjncn210ql-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndq0dueg-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndsyw8sf-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndw5oerk-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne24af8c-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne54fwqm-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-row > .ant-col > .jzjgrrupf2c-editor_css {
  padding: 0;
}

#Feature0_0 div > .ant-row > .content0-block {
  min-height: 340px;
  padding: 24px;
  transition: box-shadow 0.45s ease 0s;
}
#Feature0_0 div > .ant-row > .content0-block:hover {
  box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
}
#Footer1_0 .ant-row > .ant-col > .jzl0qcpyjra-editor_css {
  width: 100px;
}
#Footer1_0 .ant-col > div > .jzl0u1bko6-editor_css {
  margin: 16px 0 0;
}
