.container_sum {
    background-image: url(https://files.wondercv.com/pc/member/bottom-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 15.75rem;
    position: relative;
    .sum_main {
        position: relative;
        top: 3rem;
        width: 43.75rem;
        height: 9.75rem;
        margin: 0 auto;
        .main_intro {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .intro_item {
                width: 9.25rem;
                height: 3.5rem;
                text-align: center;
                p:first-child{
                    height: 2.0625rem;
                    line-height: 2.0625rem;
                    font-size: 1.5rem;
                    font-weight: 600;
                    color: #f9f9f9;
                }
                p:last-child{
                    height: 1.25rem;
                    line-height: 1.25rem;
                    font-size: .875rem;
                    font-weight: 600;
                    color: #f9f9f9;
                }
            }

        }
        .main_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2.375rem auto;
            width: 15rem;
            height: 3.875rem;
            border-radius: 1.9375rem;
            background-image: linear-gradient(90deg,#f64,#ff9f79);
            // background-image: -moz-linear-gradient(0deg,#f64,#ff9f79);
            cursor: pointer;
            p {
                font-size: 1.25rem;
                color: #f9f9f9;
            }
        }
    }
}
