.alert-main {
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    .template {
        width: 824px;
        top: 50%;
        transform: translate(-3%, -42%);
        padding: 32px 32px 24px;
        background: #fff;
        border-radius: 8px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        height: 478px;

        .menu-list{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            .menu:first-child{
                margin-bottom: 16px;
            }
            .menu {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 178px;
                height: 170px;
                background: #fff;
                border-radius: 8px;
                border: 1px solid #ececec;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                padding: 20px 0 24px;
                cursor: pointer;

                &:hover {
                    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
                }
                &:hover .btn{
                    opacity: 1;
                }

                img {
                    display: block;
                    width: 52px;
                    height: 52px;
                    margin-bottom: 6px;

                }
                p{
                    padding: 0 6px;
                    text-align: center;
                    display: block;
                    margin-block-start: 16px;
                    margin-block-end: 16px;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                }
                .title {  
                    width: 103px;
                    font-size: 15px;
                    font-weight: 500;
                    color: #404040;
                    line-height: 17px;
                    margin-bottom: -6px;
                }
                .desc {
                    font-size: 12px;
                    font-weight: 400;
                    color: #9c9c9c;
                    line-height: 17px;
                }
                .btn {
                    position: absolute;
                    display: flex;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    background: #fff;
                    z-index: 100;

                    button {
                        width: 154px;
                        height: 35px;
                        color: #f64;
                        background: #fff;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 27px;
                        padding: 0;
                        margin: 5px 0;
                        border-radius: 4px;
                        border: 1px solid #f64;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;

                        &:hover {
                            display: block;
                            background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
                            border-width: 0;
                            color: #fff;
                        }
                    }
                    
                }

            }
            
        }

        .template-more-btn {
            width: 240px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid #ececec;
            font-weight: 400;
            font-size: 16px;
            color: #606060;
            text-align: center;
            line-height: 42px;
            box-sizing: border-box;
            margin: 24px auto 0;

            &:hover {
                background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
                border-width: 0;
                color: #fff;
            }
        }


    }
}