.personal {
  width: 100%;
  background-color: #f5f5f7;

  &-content {
    width: 1116px;
    margin: 0 auto;
    padding: 90px 0 80px;
    font-size: 100%;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    display: flex;

    &-left {
      width: 260px;
      list-style: none;
      border-radius: 2px;
      overflow: hidden;
      -webkit-box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
      box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
      background-color: #fff;

      .personalItem {
        height: 70px;
        margin: 10px;
        padding: 20px 40px 20px 30px;
        cursor: pointer;

        &-content {
          border-left: 6px solid #fff;
          color: #404040;
          text-align: center;
          line-height: 30px;
          font-size: 16px;

          &:hover {
            color: #ff4906;
            transition: all 0.3s ease;
          }

          &.active {
            border-color: #ff4906;
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .ant-divider-horizontal {
        margin: 0;
      }
    }

    &-right {
      width: 832px;
      overflow: hidden;
      border-radius: 2px;
      padding: 0 24px;

      .baseInfo {
        background-color: #fff;
        -webkit-box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
        box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
        padding: 24px;
        display: flex;
        flex-direction: column;
        position: relative;

        &-from {
          margin-top: 20px;
        }

        &-btn {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .button {
            border-radius: 10px;
            width: 50px;
            height: 20px;
            background-color: #ff4906;
          }
        }

        &-isEdit {
          position: absolute;
          top: 20px;
          right: 50px;
        }
      }

      .account {
        background-color: #fff;
        -webkit-box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
        box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
        padding: 24px;
        display: flex;
        flex-direction: column;
        position: relative;

        &-from {
          margin-top: 20px;
        }

        &-btn {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;

          .button {
            border-radius: 10px;
            width: 50px;
            height: 20px;
            background-color: #ff4906;
          }
        }

        &-isEdit {
          position: absolute;
          top: 20px;
          right: 50px;
        }
      }

      .order {
        background-color: #fff;
        -webkit-box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
        box-shadow: 0 2px 4px 0 rgb(1 35 99 / 4%);
        padding: 24px;
        display: flex;
        flex-direction: column;
        position: relative;

        &-table {
          margin-top: 20px;
        }
      }
    }
  }
}