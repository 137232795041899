.side-img-container {
  width: 120px;
  height: 323px;
  border-radius: 8px;
  position: fixed;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  z-index: 2;

  .close-sideImg {
      position: absolute;
      right: 0px;
      top: 1px;
      width: 35px;
      height: 25px;
      background-color:transparent;
      border-style:none;
      &:hover{
          background-color: #ff9c62;
      }
      img {
          position: relative;
          margin: 0 auto;
          width: 100%;
          height: 100%;
          &:hover {
              transform: rotate(90deg);
              transition: all .8s;
          }
      }
  }

  .side-img {
      width: 100%;
      height: 100%;
      display: block;
  }
}