.login {
  &-title {
    padding-top: 40px;
    &-left,
    &-right {
      line-height: 44px;
      border: 1px solid #cfcfcf;
      width: 154px;
      border-radius: 2px;
      display: inline-block;
      margin: 0 8px;
      font-family: MicrosoftYaHei;
      &.active {
        border-color: #ff4f4c;
        color: #ff4f4c;
        cursor: pointer;
      }
    }
    &-left:hover,
    &-right:hover {
      border-color: #ff4f4c;
      color: #ff4f4c;
      cursor: pointer;
    }
  }
  .qr {
    margin-top: 28px;
    position: relative;
    &-imageCode {
      border: 1px solid rgba(168, 168, 168, 0.8);
      width: 200px;
      height: 200px;
    }
    &-logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &-fild {
      position: absolute;
      width: 201px;
      height: 201px;
      top: 0;
      left: 23%;
      background: rgba(255, 255, 255, 0.9);
      display: flex;
      align-items: center;
      justify-content: center;
      &-content {
        width: 50px;
        height: 50px;
        background: url('https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/youjia/%E9%87%8D%E6%96%B0%E5%8A%A0%E8%BD%BD.png?sign=46f79ff890a1cfcc3828f70e96bf393a&t=1648471317')
          no-repeat 50% / cover;
      }
      .button {
        cursor: pointer;
        width: 140px;
        height: 45px;
        background: #f83e25;
        word-spacing: 6px;
        font-size: 16px;
        transition: all 0.2s ease-in;
        line-height: 45px;
        color: #ffff;
        font-weight: 500;
        border-radius: 4px;
      }
      .button:active {
        background: #c59e99;
      }
    }
    P {
      position: absolute;
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      font-size: 14px;
      font-weight: 500;
      // color: #f93920;
      text-align: left;
      line-height: 20px;

      transition: all 0.2s ease-in;
    }
  }
  .login-text {
    line-height: 30px;
    margin-top: 10px;

    .iconfont {
      color: #3fab40;
      font-size: 22px;
      top: 2px;
      font-family: 'iconfont' !important;
    }
    .text {
      margin: 0;
      padding-left: 5px;
      border: 0;
      font-size: 100%;
      font-weight: 400;
      vertical-align: baseline;
    }
  }
  .privacy {
    line-height: 15px;
    margin-top: 15px;
    display: block;
    font-size: 14px;
    color: #9c9c9c;
    text-align: center;
  }
  .ant-modal {
    box-shadow: 0 4px 15px 0 #e8e8e8;
    border-radius: 6px;
  }
}
