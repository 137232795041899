@banner5: banner5;

.@{banner5} {
  // 如果在第一屏且导航位置为 relative, 一屏为 height: calc(~"100vh - 64px");
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fdfdfd;
  background-image: linear-gradient(to top, fade(#fdfdfd, 30) 0%, fade(#c1daff, 30) 100%);
  overflow: hidden;
  background: url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/home-banner.png?sign=4a3d64265c396b94b4a9a5be653a4fe6&t=1634014076)
      no-repeat 50% / cover;
margin-top: 60px;
  & &-page {
    padding: 1px 0 0;
  }

  &-title {
    font-size: 48px;
    line-height: 1.5;
    color: #ffffff;
    margin-bottom: 8px;
    font-family:FZJUNH_CUJW ;
    font-weight: 500;
    &-wrapper {
      width: 40%;
      max-width: 450px;
      position: relative;
      top: 30%;
      left: 24px;
      font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    }
  }

  &-explain {
    color: #fff;
    font-size: 24px;
    margin-bottom: 24px;
  }

  &-content {
    font-size: 18px;
    color: rgb(228, 223, 223);
    margin-bottom: 48px;
  }
 
  &-button-wrapper {
    .@{banner5}-button {
        background-image: linear-gradient(135deg, #ff813a 0%,#ff3d3d 100%);
      box-shadow: 0 8px 24px fade(#5f9bf1, 35);
      border-radius: 20px;
      border: none;
      padding: 0 42px;
      line-height: 40px;
      height: 40px;
      transition: transform .3s, box-shadow .3s;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 13px 24px fade(#5f9bf1, 45);
        background-image: linear-gradient(to right bottom, #82b5ff 0%, #3c89f6 0%);
      }
    }
  }

  &-image {
    overflow: hidden;
    width: 55%;
    max-width: 710px;
    position: absolute;
    top: 15%;
    right: 24px;
  }
}

@media screen and (max-width: 767px) {
  .@{banner5} {
    min-height: 500px;

    & &-page.home-page {
      padding-top: 1px;
      padding-bottom: 0;
    }

    &-title-wrapper {
      text-align: center;
      position: static;
      width: 100%;
      margin-top: 64px;
      left: 0;
      top: 0;
    }

    &-title {
      font-size: 32px;
    }

    &-explain {
      font-size: 18px;
    }

    &-content {
      font-size: 14px;
    }

    &-image {
      position: static;
      width: 100%;
      top: 0;
      right: 0;
      margin: 32px 0 24px;
    }
  }
}
