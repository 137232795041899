.content_bg{
    background-color: #f9f9f9;
}
.content_box{
    padding: 80px 0;
    h3{
        text-align: center;
        font-size: 32px;
        color: #222;
        line-height: 36px;
    }
    .liucheng_list{
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px 0;
        overflow: hidden;
        margin-top: 30px;
        li{
            float: left;
            width: 18%;
            min-height: 160px;
            padding: 40px 20px;
            border-radius: 10px 66px 10px 66px;
            background-color: #fff;
            -webkit-box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
            box-shadow: 0 4px 10px rgb(0 0 0 / 12%);
            margin-left: 2%;
            text-align: center;
            position: relative;
            h4{
                font-size: 18px;
                color: #222;
            }
            p{
                margin-top: 15px;
                color: #999;
                font-size: 13px;
            }
            span{
                position: absolute;
                right: -22px;
                width: 20px;
                text-align: center;
                top: 50%;
                line-height: 20px;
                margin-top: -10px;
                img{
                    width: 18px;
                    height: 18px;
                }
            }
        }
        li:first-child{
            margin-left: 10px;
        }
    }
}