.success-main {
  width: 100%;
  height: 1000px;
  // background: #fff;
  margin: 56px auto 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  .title {
    width: 100%;
    height: 600px;
    background: linear-gradient(180deg, #ecfafa, #f8f8f8);
    background-repeat: no-repeat;
    background-size: 100% 600px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(51, 51, 51, 1);
    position: relative;
    .icon {
      text-align: center;
      border-radius: 50%;
      margin-top: 32px;
      position: relative;
      > img {
        width: 45px;
        height: 45px;
      }
    }
    .p-title-success {
      font-size: 24px;
      font-weight: 700;

      font-family: cursive;
    }
    .member-tips {
      opacity: 0.7;
      margin-top: 10px;
    }
    .member-img {
      width: 230px;
      height: 230px;
      margin: 32px auto 0;
      text-align: center;
      box-shadow: 0 6px 24px -4px #ecfafa;
    }
    .number-code-tips {
      font-size: 16px;
      margin-top: 16px;
      line-height: 26px;
      text-align: center;
      color: rgba(51, 51, 51, 0.7);
    }
    .member-desc {
      position: absolute;
      left: 50%;
      bottom: -125px;
      transform: translateX(-50%);
      width: 707px;
      background: #fff;
      box-shadow: 0 6px 48px 0 rgba(0, 0, 0, 0.13);
      border-radius: 8px;
      margin-top: 20px;
      .vip-content-left {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 608px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border-radius: 8px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .vip-options {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          -ms-flex-pack: distribute;
          justify-content: space-around;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          height: 191px;
          padding: 25px 16px 16px 16px;
          background: #fff;
          border-radius: 8px;
          &-item {
            width: 110px;
            height: 150px;
            background: #f5f6fa;
            border-radius: 8px;
            color: #404040;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-weight: 400;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            cursor: pointer;
            position: relative;
            .position-bottom {
              width: 110px;
              height: 28px;
              border-radius: 0 0 8px 8px;
              bottom: 0;
              webkit-box-sizing: border-box;
              box-sizing: border-box;
              background: linear-gradient(132deg, #fff2e2, #e3c8a9);
              color: #3b3f57;
              font-weight: 500;
              font-size: 12px;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              position: absolute;
            }
            .recommend-final-vip-layout {
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              background: linear-gradient(132deg, #fff2e2, #e3c8a9);
              color: #3b3f57;
              font-weight: 500;
              font-size: 12px;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              position: absolute;
              width: 43px;
              height: 22px;
              border-radius: 10px 0 10px 0;
              top: -12px;
              left: 0;
            }
            > .time {
              height: 14px;
              margin-top: 28px;
              line-height: 14px;
              margin-bottom: 14px;
              font-size: 14px;
              font-weight: 500;
              color: #404040;
              cursor: pointer;
              &.active {
                color: #efdfc9;
              }
            }
            .pic-container {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              height: 24px !important;
              margin-bottom: 12px;
            }
            .pic-tip {
              padding-top: 4px;
              padding-right: 4px;
              font-size: 14px;
            }
            .pic-text {
              line-height: 24px;
              text-align: center;
              font-size: 24px;
              font-weight: 600;
            }
            .old-pic {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              font-weight: 300;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              position: relative;
              height: 12px;
              font-size: 12px;
              color: #9c9c9c;
            }
            &.active {
              background: #272742;
              color: #efdfc9 !important;
            }
            &:last-child {
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 210px;
              border: 1px solid #d6a77a;
              background: #fcf7f2;
              &.active {
                background: #272742;
                color: #efdfc9 !important;
              }
            }
            .pic-explain {
              height: 12px;
              font-size: 12px;
              font-weight: 600;
              color: #c6884c;
              line-height: 12px;
              margin-top: 11px;
              &.active {
                color: #efdfc9;
              }
            }
          }
        }
        &-bottom {
          height: 216px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          background: linear-gradient(44deg, #fff6f4, #fff);
          border-radius: 8px;
          overflow: hidden;
          padding: 20px 0 20px 0;
          .vip-icon-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-flow: wrap;
          }
          .kind {
            &.opcity {
              opacity: 0.4;
            }
            .div-img {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              > img {
                width: 50px;
                height: 50px;
              }
            }
            > p {
              display: block;
              height: 14px;
              width: 100px !important;
              margin: 4px 0;
              font-weight: 500;
              color: #404040;
              font-size: 14px;
              text-align: center;
              -webkit-transform: scale(0.8);
              transform: scale(0.8);
            }
            > span {
              display: block;
              width: 100%;
              text-align: center;
              -webkit-transform: scale(0.7);
              transform: scale(0.7);
              font-size: 8px;
              font-weight: 400;
              color: #9c9c9c;
            }
          }
          .svip-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .left {
              width: 162px;
              height: 186px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .and {
              margin: 0 32px 0 31px;
              height: 20px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
            .right {
              width: 308px;
              height: 174px;
              > img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
  .text-welfare {
    width: 100%;
    color: #494d5b;
    font-size: 14px;
    text-align: center;
    margin-top: 140px;
  }
  .item {
    width: 713px;
    margin-top: 44px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #494d5b;
    line-height: 24px;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    &-p-title {
      font-size: 16px;
      font-weight: 700;
    }
    &-p-desc {
      margin-top: 10px;
    }
  }
}
