.index_content {
    .content_tuandui {
        margin-top: -30px;
    }

    .content_box {
        padding: 80px 0;
        position: relative;

        .team-icon {
            text-align: center;

            &_ {
                width: 40px;
                height: 40px;
            }
        }

        h3 {
            text-align: center;
            font-size: 32px;
            color: #222;
            line-height: 36px;
        }

        .text_tit {
            text-align: center;
            margin-top: 18px;
            color: #666;
            font-size: 16px;
        }

        .teacher_list {
            max-width: 1150px;
            margin: 0 auto;
            overflow: hidden;
            padding: 200px 0 60px;

            li {
                float: left;
                height: 250px;
                width: 24%;
                position: relative;

                .tutorAvatar {
                    position: absolute;
                    float: left;
                    top: -150px;
                    width: 190px;
                    z-index: -1;
                    margin-left: 45px;
                    top: -140px;

                }

                .teacher_card {
                    position: absolute;
                    width: 96%;
                    margin: auto;
                    height: 339px;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    padding: 20px;
                    overflow: hidden;
                    z-index: 1;
                    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.45));

                    .tutorName {
                        position: absolute;
                        width: 85%;
                        font-size: 20px;
                        font-weight: 700;
                        color: #333;
                        margin-bottom: 10px;
                        top: 90px;

                        &-odd {
                            transform: skew(10deg, -10deg);
                            background-image: linear-gradient(to right, rgb(184, 184, 184) 70%, rgba(93, 89, 89, 0) 0%);
                            background-position: bottom;
                            background-size: 4px 1.1px;
                            background-repeat: repeat-x;
                            width: 100%;
                            float: right;

                            &-name {
                                position: absolute;
                                display: inline-block;
                                width: 50%;
                                overflow: auto;
                                background: #ff813a;
                                text-align: center;
                                margin-top: -15px;
                                transform: skew(-15deg, 0);
                                right: -10px;
                                font-family: 'DFKai-SB';
                            }
                        }

                        &-even {
                            width: 230px;
                            transform: skew(-10deg, 10deg);
                            background-image: linear-gradient(to right, rgb(184, 184, 184) 70%, rgba(93, 89, 89, 0) 0%);
                            /* 35%设置虚线点x轴上的长度 */
                            background-position: bottom;
                            /* top配置上边框位置的虚线 */
                            background-size: 4px 1.1px;
                            /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
                            background-repeat: repeat-x;
                            float: left;

                            &-name {
                                position: absolute;
                                display: inline-block;
                                width: 50%;
                                overflow: auto;
                                background: #ff813a;
                                text-align: center;
                                transform: skew(15deg, 0);
                                margin-top: -15px;
                                left: -5px;
                            }
                        }
                    }
                }
            }

            .teacher_info {
                position: absolute;
                margin-top: 120px;
                padding-right: 15px;

                p {
                    font-size: 15px;
                    line-height: 1.6;
                    position: relative;
                    padding-left: 10px;
                    margin-top: 3px;

                }

                p:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background-color: #ff813a;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 7px;
                }
            }
        }

        .teacher_background {
            width: 100%;
            position: absolute;
            bottom: 150px;
            z-index: -1;

            &-ellipse {
                width: 1200px;
                height: 240px;
                margin: 0 auto;
                background: #ff813a;
                border-radius: 50% / 100% 100% 0 0;

            }
        }
    }

}