@font-face {
  font-family: MicrosoftYaHei;
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/IugGDA0sxXGyx6Me8DftE.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/bbkOTmCuycOXH0W885hQo.woff') format('woff');
  font-display: swap;
}

.service-page {
  width: 100vw;
  background: #f9f9f9;
  .service-content {
    margin: 0 auto;
    height: auto;
    width: 1054px;
    padding: 90px 0 35px;
  }
  .service-info {
    background: #fff;
    border-radius: 10px;
    padding: 32px 72px 48px 72px;
    width: 1054px;

    &-title {
      font-size: 18px;
      color: #404040;
      font-weight: 700;
      position: relative;
      > .num {
        width: 32px;
        position: absolute;
        height: 19px;
        line-height: 19px;
        top: 3px;
        font-size: 18px;
        left: -40px;
        border-radius: 10px 6px 0 10px;
        background: #ff4f4f;
        background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
        color: #fff;
        text-align: center;
      }
    }
    &-contant {
      margin-top: 32px;
      margin-bottom: 12px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 12px;
      &-item {
        padding: 16px 20px 20px;
        border-radius: 6px;
        border: 1px solid #ececec;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        background: #fff;
        cursor: pointer;
        margin-bottom: 20px;
        width: 445px;

        &:hover {
          box-shadow: 0 0 10px rgb(212, 212, 212);
        }
        .service-item-header {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-bottom: 10px;
          > h6 {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            font-size: 15px;
            font-weight: 500;
            color: #404040;
            line-height: 22px;
            > img {
              width: 50px;
              height: 20px;
              margin-left: 10px;
            }
          }
          &-detail {
            position: relative;
            width: 48px;
            height: 22px;
            line-height: 22px;
            background: #f9f9f9;
            border-radius: 11px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            font-size: 13px;
            font-weight: 400;
            color: #bcbcbc;
            text-align: center;
            &:hover {
              background-color: #505667;
              color: #fff;
            }
          }
        }
        .service-item-desc {
          height: 45px;
          font-size: 13px;
          font-weight: 400;
          color: #606060;
          line-height: 20px;
        }
        .service-item-flooter {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;

          &-left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            .img {
              width: 44px;
              margin-right: 6px;
            }
            > span {
              font-size: 12px;
              font-weight: 400;
              color: #9c9c9c;
              line-height: 12px;
              width: 100px;
            }
          }
          &-btn {
            width: 76px;
            height: 28px;
            line-height: 26px;
            font-size: 13px;
            border-radius: 14px;
            text-align: center;
            cursor: pointer;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            color: #ff4f4c;
            border: 1px solid #ff4f4c;
            background: #fff;
            position: relative;
            padding-left: 14px;
            &:hover {
              background: #ff4f4c;
              border: 1px solid #ff4f4c;
              color: #fff;
              i {
                color: #fff;
              }
            }
            &.active {
              background: #ff4f4c;
              border: 1px solid #ff4f4c;
              color: #fff;
              padding-left: 0;
            }
            > i {
              font-size: 10px;
              color: #ff4f4c;
              font-family: 'iconfont' !important;
              font-size: 15px;
              font-style: normal;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              &::before {
                content: '+';
                position: absolute;
                left: 15px;
                top: -1px;
              }
            }
          }
        }
      }
      .condition-title {
        font-size: 18px;
        font-weight: 600;
        font-family: MicrosoftYaHei;
      }
    }
    &-step {
      margin-top: 32px;
      margin-bottom: 12px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: center;
      .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
        background: #ff4f4f;
        border-color: #ff4f4f;
      }
      .ant-steps-item-title:hover {
        color: #ff4f4f;
      }
      .ant-steps-item-finish .ant-steps-item-icon {
        background-color: #fff;
        border-color: #ff4f4f;
      }
      .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
        color: #ff4f4f;
      }

      .ant-steps-item-wait .ant-steps-item-icon:hover {
        border-color: #ff4f4f;
      }
      .ant-steps-item-wait
        > .ant-steps-item-container
        > .ant-steps-item-content
        > .ant-steps-item-title:hover {
        color: #ff4f4f;
      }
      .step-box {
        width: 500px;
        margin: 0 auto;
      }
      > .btn {
        width: 200px;
        display: flex;
        margin: 0 auto;
        justify-content: space-around;
      }
    }
  }
  .service-other {
    background: #fff;
    border-radius: 10px;
    padding: 32px 72px 48px 72px;
    width: 1054px;

    border-radius: 2px;
    margin-top: 24px;
    &-process {
      width: 100%;
      height: 120px;
      margin-top: 40px;
      > img {
        width: 100%;
        height: 75px;
      }
    }
    &-question {
      background: #fff;
      border-radius: 2px;
      height: 380px;
      width: 100%;
      overflow-y: auto;
      margin-top: 20px;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .question-desc {
        color: #9c9c9c;
        font-size: 14px;
        margin-top: -10px;
        line-height: 20px;
      }
      .service-page .service-other-question .question-desc {
        color: #9c9c9c;
        font-size: 14px;
        /* margin-top: 14px; */
        line-height: 20px;
      }
      .red-text {
        color: #ff4f4c;
      }
    }
  }
  .service-floot {
    height: 126px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    -webkit-box-shadow: 0 -2px 12px 0 hsl(0deg 0% 88% / 50%);
    box-shadow: 0 -2px 12px 0 hsl(0deg 0% 88% / 50%);
    background-color: #fff;
    &-cont {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      width: 1054px;
      margin: 0 auto;
      &-left {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-right: 24px;
        margin-top: 24px;
        width: 600px;
        .left-title {
          line-height: 29px;
          font-size: 13px;
          color: #404040;
          > span {
            color: #ff4f4c;
          }
        }
      }
      &-right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .oderCont {
          text-align: right;
          margin-top: 24px;
          .oder-cont-money {
            margin-top: 6px;
            line-height: 28px;
            font-size: 16px;
            color: #404040;
            > span {
              top: 2px;
              font-size: 28px;
              color: #ff4f4c;
              position: relative;
              > .pre-num {
                font-size: 14px;
                font-weight: 400;
                color: #9c9c9c;
                display: inline-block;
                margin-left: 3px;
                position: relative;
                bottom: 4px;
              }
            }
          }
          .use-coupons {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #606060;
            line-height: 20px;
            cursor: pointer;
          }
          .service-agreement {
            color: gray;
            line-height: 29px;
            font-size: 12px;
            > span {
              cursor: pointer;
            }
          }
        }
        .btnCont {
          width: 164px;
          height: 52px;
          line-height: 52px;
          background: #ff4f4c;
          border-radius: 4px;
          cursor: pointer;
          margin: 37px 0 0 22px;
          color: #fff;
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }
}
.coupons-modal {
  .ant-modal-header {
    padding: 16px 24px 10px 24px;

    border-radius: 4px 3px 0 0;
    border-bottom: 0px;
  }
  .ant-modal-body {
    padding: 0 24px;
    font-size: 14px;
    line-height: 1.5715;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    border-top: 0px solid #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.pay-modal {
  .ant-modal {
    box-shadow: 0 4px 15px 0 #e8e8e8;
    border-radius: 6px;
  }
  .ant-modal-header {
    padding: 28px 24px 10px 24px;
    border-radius: 10px 10px 0 0;
    border-bottom: 0px;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  .pay-error {
    width: 223px;
    text-align: end;
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #f64;
    line-height: 46px;
  }

  .detail {
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei,
      Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    width: 100%;
    background: #f9f9f9;
    border-radius: 8px;
    margin: 0 auto;
    padding: 24px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: left;
    &-title {
      width: 70px;
      height: 13px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9c9c9c;
      line-height: 24px;
    }
    &-desc {
      width: 473px;
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #404040;
      line-height: 24px;
    }
  }
  .contant {
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei,
      Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    padding-top: 24px;
    .pay {
      width: 100%;
      height: 174px;
      border-radius: 8px;
      margin-bottom: 4px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #f5f6fa;
      padding: 24px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .Qrcode {
        width: 95px;
        height: 96px;
        margin-right: 18px;
        position: relative;
        top: 10px;
        .Qr-mask {
          position: absolute;
          top: -5px;
          left: -5px;
          width: 105px;
          height: 107px;
          background-color: rgba(98, 95, 95, 0.818);
          justify-content: center;
          padding-top: 40px;
          text-align: center;
        }
      }

      .paycontent {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        > p {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 10px;
          color: #404040;
          font-size: 14px;
          -webkit-box-align: end;
          -ms-flex-align: end;
          align-items: flex-end;
          .count {
            font-size: 28px;
            font-weight: 600;
            color: #f64;
          }
        }
      }

      .pay-success-btn {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
        button {
          font-size: 18px;
          display: inline-block;
          outline: 0;
          border: 0;
          cursor: pointer;
          will-change: box-shadow, transform;
          background: radial-gradient(100% 100% at 100% 0%, #ff813a 0%, #ff3d3d 100%);
          box-shadow: 0px 0.01em 0.01em rgb(45 35 66 / 40%),
            0px 0.3em 0.7em -0.01em rgb(45 35 66 / 30%), inset 0px -0.01em 0px rgb(58 65 111 / 50%);
          padding: 0 2em;
          border-radius: 0.3em;
          color: #fff;
          height: 2.6em;
          text-shadow: 0 1px 0 rgb(0 0 0 / 40%);
          transition: box-shadow 0.15s ease, transform 0.15s ease;
        }

        button:hover {
          box-shadow: 0px 0.1em 0.2em rgb(45 35 66 / 40%),
            0px 0.4em 0.7em -0.1em rgb(45 35 66 / 30%), inset 0px -0.1em 0px #ff3d3d;
          transform: translateY(-0.1em);
        }

        button:active {
          box-shadow: inset 0px 0.1em 0.6em #ff3d3d;
          transform: translateY(0em);
        }
      }
      &-success {
        width: 100%;
        height: 200px;
        .ant-result-success {
          padding: 0;
        }
        .ant-result-title {
          font-size: 20px;
        }
        .ant-result-success .ant-result-icon {
          margin-bottom: 5px;
          & > .anticon {
            color: #52c41a;
            font-size: 25px;
          }
        }
      }
    }
  }
}
