.mid-content4 {
    padding-top: 180px;
    background: #fff8f8;
    width: 100%;
  
    &-title {
      line-height: 34px;
      margin-bottom: 130px;
      font-weight: 700;
      color: #404040;
      text-align: center;
      font-size: 42px;
      font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC, Microsoft YaHei,
        Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    }
  
    &-card {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 581px;
    }
  }
  
  .mid-content4-card .lists {
    height: 463px;
    padding: 40px 24px 24px;
    background: #fff;
    border-radius: 8px;
    margin-right: 24px;
    margin-bottom: 70px;
    cursor: pointer;
    // transition: height .3s ease;
    box-sizing: border-box;
  
    .empty-button {
      height: 10px;
      width: 280px;
    }
  
    .content {
      width: 280px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
      transition: all 0.5s;
      .lottie-anima {
        width: 120px;
        height: 120px;
      }
  
      .head {
        font-size: 24px;
        font-weight: 500;
        color: #404040;
        line-height: 26px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
  
        .money {
          color: #ff4f4f;
          font-size: 14px;
          position: relative;
          top: 5px;
          left: 2px;
          margin-left: 5px;
          font-weight: 600;
        }
  
        .num {
          width: 52px;
          height: 26px;
          font-weight: 500;
          font-size: 26px;
          color: #ff4f4f;
        }
      }
  
      .listcontent {
        font-size: 14px;
        font-weight: 400;
        color: #9c9c9c;
        line-height: 22px;
        margin-bottom: 22px;
      }
  
      .advantage {
        width: 100%;
        height: 136px;
      }
    }
  }
  
  .lists {
    height: 463px;
  
    .list-buttons {
      width: 280px;
      border-radius: 25px;
      box-sizing: border-box;
      border: 1px solid;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 14px;
      color: #fff;
      background: #ff4f4f;
      background-image: linear-gradient(90deg, #ff813a, #ff3d3d);
      visibility: hidden;
  
      &:hover {
        background: hsl(27, 100%, 50%);
        transition: 0.6s;
      }
    }
  
    .list-havebuy {
      font-size: 14px;
      font-weight: 400;
      color: #9c9c9c;
      line-height: 20px;
    }
  
    &:hover {
      box-shadow: 0 10px 36px 0 hsl(0deg 0% 87% / 50%);
    }
  }
  