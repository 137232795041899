/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '思源黑体 Medium';
  font-weight: 500;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/q7_PsTQyPvO135qdVeP8B.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/1blCc4F0p92H-ThpSMCxm.woff') format('woff');
  font-display: swap;
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'FONT-Family';
  font-weight: 700;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/f_5ND-JUB7SPEqC3nUgu4.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/NZhs-WFqGQNn0l5EF-Pf-.woff') format('woff');
  font-display: swap;
}
@font-face {
  font-family: MicrosoftYaHei;
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}
/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'Regular';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/IugGDA0sxXGyx6Me8DftE.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/bbkOTmCuycOXH0W885hQo.woff') format('woff');
  font-display: swap;
}
// @font-face {
//   font-family: 'FZJUNH_CUJW';
//   src: url('//ali2.a.kwimgs.com/udata/pkg/ks-ad-ssp-cdn/home/home2021/FZJHJW-subfont.ttf');
// }
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '阿里妈妈数黑体 Bold';
  font-weight: 700;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/C443pzCX73qc.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/c4Kk7dzkelPm.woff') format('woff');
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '阿里妈妈数黑体2';
  font-weight: 700;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/KxijwPQqCeMw.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/rI4rAQzvnYFk.woff') format('woff');
  font-display: swap;
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: '仓耳渔阳体 W04';
  font-weight: 400;
  src: url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/2NzepqxsFPak.woff2') format('woff2'),
    url('//at.alicdn.com/wf/webfont/Ss1EhhY7hSKV/8yCPNLzipYTJ.woff') format('woff');
  font-display: swap;
}
.home {
  .brand {
    width: 100%;
    height: calc(100vw / 16 * 9);
    overflow-y: hidden;
    color: #fff;
    background: url(https://6d78-mxm1923893223-ulteh-1302287111.tcb.qcloud.la/home-banner.png?sign=4a3d64265c396b94b4a9a5be653a4fe6&t=1634014076)
      no-repeat 50% / cover;
    position: relative;
    .banner-bg {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      object-fit: unset;
      display: block;
    }
    > .bg-info {
      z-index: 2;
      position: absolute;
      top: 25%;

      .name {
        font-size: 66px;
        font-family: '阿里妈妈数黑体2';
        text-align: left;
        color: #fff;
        line-height: 90px;
        letter-spacing: 5px;

        > span {
          display: inline-block;
        }
      }
      .slogon {
        font-size: 66px;
        font-family: '仓耳渔阳体 W04';
        text-align: left;
        color: #fff;
        line-height: 90px;
        letter-spacing: 5px;

        > span {
          display: inline-block;
        }
      }

      .animation-item {
        transform: translateY(50%);
        opacity: 0;
        transition: transform 0.5s cubic-bezier(0.39, 0.575, 0.565, 1),
          opacity 0.3s cubic-bezier(0.67, 0.19, 0.94, 0.81);
      }
      .btns {
        display: flex;
        margin-top: 30px;
        .realization {
          cursor: pointer;
          width: 194px;
          height: 50px;
          background: linear-gradient(90deg, #ff813a, #ff3d3d);
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          text-align: left;
          color: #fff;
          line-height: 22px;
          letter-spacing: 1px;
        }
        .to-ad {
          cursor: pointer;
          margin-left: 24px;
          width: 194px;
          height: 50px;
          border: 1px solid #fff;
          border-radius: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-family: MicrosoftYaHei;
          text-align: left;
          color: #fff;
          line-height: 22px;
          letter-spacing: 1px;
        }
      }
      .description {
        margin-top: 27px;
        width: 410px;
        font-size: 10px;
        font-family: MicrosoftYaHei;
        text-align: left;
        color: #fff;
        line-height: 24px;
      }
    }
  }
}
