@font-face {
  font-family: 'FZJUNH_CUJW';
  //src: url('https://ali2.a.kwimgs.com/udata/pkg/ks-ad-ssp-cdn/home/home2021/FZJHJW-subfont.ttf');
}
@font-face {
  font-family: 'Gilroy-ExtraBold';
  //src: url('https://ali2.a.kwimgs.com/udata/pkg/ks-ad-fe/Gilroy_ExtraBold.ttf');
}
@font-face {
  font-family: 'MicrosoftYaHei';
  src: local('MicrosoftYaHei'), local('PingFangSC'), local('PingFang SC');
}

/* @font-face { */
/*   font-family: "FZJUNH_CUJW"; */
/*   src: url("https://ali2.a.kwimgs.com/udata/pkg/ks-ad-fe/FZJHJW.ttf"); */
/* } */
body,
.app.pc {
  width: 100%;
}
.home::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.home {
  height: auto;
  width: 100%;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
  > *:nth-child(2) {
    min-height: calc(100vh - 190px);
  }
  .calculate-margin-left {
    margin-left: calc(80px + (100vw - 1440px) / 2);
  }
  .calculate-padding-left {
    padding-left: calc(80px + (100vw - 1440px) / 2);
  }
  .calculate-left {
    left: calc(40px + (100vw - 1440px) / 2);
  }
  .calculate-right {
    right: calc(40px + (100vw - 1440px) / 2);
  }
  @media (max-width: 1440px) {
    .calculate-margin-left {
      margin-left: 80px;
    }
    .calculate-padding-left {
      padding-left: 80px;
    }
    .calculate-left {
      left: 40px;
    }
    .calculate-right {
      right: 40px;
    }
  }
  figure {
    margin: 0;
  }
  .toTop {
    height: 90px;
    width: 90px;
    position: fixed;
    bottom: 59px;
    right: 9px;
    z-index: 10;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
    &.visible {
      opacity: 1;
    }
    img {
      user-select: none;
      pointer-events: none;
      width: 100%;
      height: 100%;
      position: absolute;
      transition: opacity 0.3s;
    }
  }
  .fade-in {
    opacity: 0;
    transform: translate3d(0, 90px, 0);
    transition: opacity 1s, transform 1s;
    &.visible {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  .fade-in-40 {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
    transition: opacity 1s, transform 1s;
    &.visible {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  .animation-number {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 48px;
    overflow: hidden;
    div.number-box {
      transition: top 1s;
      width: 36px;
      position: relative;
      top: 0;
      .num-item {
        width: 36px;
        height: 48px;
        text-align: center;
      }
    }
  }
  .animation-button {
    position: relative;
    overflow: hidden;
    > span {
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      transition: transform 0.3s, opacity 0.3s;
      transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    }
    // &::after {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   content: '';
    //   opacity: 0;
    //   transition: opacity 0.3s;
    //   transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    // }
    &::before {
      transition: transform 0.3s, opacity 0.3s;
      transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
      content: attr(data-text);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &:hover::before {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    &:hover::after {
      opacity: 1;
    }
    &:not(:hover) > span {
      transition-delay: 0s !important;
    }
    &:hover > span {
      opacity: 1;
      transform: translateZ(0);
    }
  }
  .solution {
    height: 500px;
  }
  .news {
    min-height: 100vh;
    .news-banner {
      background-color: #607aab;
      text-align: center;
      img {
        width: auto;
        height: 360px;
      }
    }
    .news-live {
      background-color: #080504;
      text-align: center;
      height: 891px;
      position: relative;
      img {
        width: 1300px;
        height: 693px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-35%, 0);
      }
      iframe {
        border: none;
        position: relative;
        width: 1280px;
        height: 640px;
        margin-top: 140px;
      }
      video {
        object-fit: fill;
        position: relative;
        width: 1280px;
        height: 640px;
        margin-top: 140px;
        border: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        outline: none;
        background: black;
        text-decoration: none;
      }
    }

    .ssp-tabs {
      margin-top: 28px;
      .ant-tabs-ink-bar {
        background: #ff4906;
      }
      .ant-tabs-tab-btn {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 18px;
        letter-spacing: 0;
      }
      .ant-tabs-tab {
        color: #1f1f1f;
        &:hover {
          color: #ff4906;
        }
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        font-size: 14px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        color: #1f1f1f;
        text-align: left;
        line-height: 18px;
        letter-spacing: 0;
      }
      .ant-tabs-nav::before {
        border: none;
      }
    }
    .list-container {
      margin-top: 24px;
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-left: -40px;
      }
    }
  }
  .policy {
    background: #f7f7f7;
    .policy-banner {
      text-align: center;
      background-size: auto 100%;
      min-width: 1280px;
      flex-direction: column;
      align-items: center;
      height: 360px;
      position: relative;
      overflow: hidden;
      background: url('https://ali2.a.kwimgs.com/udata/pkg/ks-ad-ssp-cdn/home/news-header-banner.jpg')
        no-repeat 50%;
      background-color: #080504;
      color: white;
      h1 {
        font-family: FZJUNH_CUJW;
        text-align: center;
        color: #fff;
        line-height: 48px;
        margin-top: 147px;
      }
      p {
        font-size: 14px;
        text-align: center;
        color: #fff;
        line-height: 30px;
        letter-spacing: 0;
        margin-top: 8px;
      }
    }

    .ssp-tabs {
      margin-top: 28px;
      .ant-tabs-ink-bar {
        background: #ff4906;
      }
      .ant-tabs-tab {
        color: #1f1f1f;
        &:hover {
          color: #ff4906;
        }
      }
      .ant-tabs-tab-btn {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 18px;
        letter-spacing: 0;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #1f1f1f;
        font-weight: 700;
      }
      .ant-tabs-nav::before {
        border: none;
      }
    }
    .list-container {
      margin-top: 24px;
      .list {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-left: -40px;
      }
    }
  }
  .detail {
    min-height: calc(100vh - 190px);
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 44px;
    .dt-header {
      padding-top: 20px;
      height: 150px;
      h1 {
        margin-top: 24px;
        font-size: 36px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        text-align: center;
        color: #1a1e2a;
        line-height: 46px;
      }
    }
    .detail-content {
      margin-top: 48px;
      font-size: 16px;
      line-height: 1.8;
      img {
        max-width: 100%;
      }
    }
  }
}

.ssp-layout {
  width: 100%;
  .ssp-layout-innner {
    position: relative;
    margin: 0 auto;
    width: 1280px;
  }
}

.report-card {
  cursor: pointer;
  margin-bottom: 72px;
  width: 400px;
  height: 426px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 16px 30px -4px rgba(0, 0, 0, 0.04);
  margin-left: 40px;
  .card-pic {
    height: 225px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .card-info {
    padding: 20px 30px;
    .title {
      letter-spacing: 1px;
      line-height: 26px;
      color: #1f1f1f;
      text-align: left;
      font-weight: 700;
      font-size: 20px;
      height: 52px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .intro {
      margin-top: 16px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      text-align: left;
      color: #8c8c8c;
      line-height: 22px;
      letter-spacing: 0;
      overflow: hidden;
      height: 44px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .card-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      a {
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei-Bold;
        font-weight: 700;
        text-align: left;
        color: #8c8c8c;
        line-height: 1;
        letter-spacing: 1px;
        position: relative;
        span {
          vertical-align: middle;
          margin-right: 4px;
        }
        .arrow {
          display: inline-block;
          position: relative;
          width: 22px;
          height: 2px;
          z-index: 1;
          background-color: #8c8c8c;
          &::before,
          &::after {
            content: ' ';
            height: 2px;
            width: 7px;
            background-color: #8c8c8c;
            position: absolute;
            right: -2px;
            transition: all 0.2s;
          }
          &::before {
            bottom: 2px;
            transform: rotate(45deg);
          }
          &::after {
            top: 2px;
            transform: rotate(-45deg);
          }
        }
      }
      .date {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        text-align: right;
        color: #8c8c8c;
        line-height: 22px;
        letter-spacing: 0;
      }
    }
  }
}

.ssp-layout {
  width: 100%;
  .ssp-layout-innner {
    position: relative;
    margin: 0 auto;
    width: 1280px;
  }
}
