.discourse {
    width: 1282px;
    margin: 0 auto 12px;
    padding-top: 30px;
    position: relative;

    &-title {
        line-height: 34px;
        font-size: 30px;
        font-weight: 700;
        color: #404040;
        text-align: center;
    }

    &-swiper {
        width: 1282px;
        height: 846px;
        margin-top: 40px;
        background-color: #fff;
        color: #404040;
        position: relative;
    }
}

.userComment {
    background-size: cover;
    background-position: center center;
    position: unset;
    display: block;
    width: 100%;
    transform: translate(0px, 0px);
}

.userComments {
    height: 1000px
}

.banner-anim {
    .banner-anim-arrow-default {
        position: absolute;
        width: 60px;
        height: 60px;
        margin-top: -30px;
        top: 45%;
        margin: 0 auto;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.1);
        z-index: 2;
        margin: 0 -10px;
    }

    .banner-anim-arrow-default:hover {
        transition: all 1s;
        background: rgb(243, 132, 4);
        box-shadow: 0 0 30px 0 rgb(0 9 85 / 8%);
    }

    .banner-anim-arrow-default.next:before,
    .banner-anim-arrow-default.prev:before,
    .banner-anim-arrow-default.next:after,
    .banner-anim-arrow-default.prev:after {
        width: 2px;
        height: 15px;
        background: #fff;
        display: block;
        content: ' ';
        position: absolute;
        margin-left: 18px;
    }
}