.recruit-info-banner-item {
  height: 250px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
/* 移动端 */
@media (max-width: 999px) {
  .recruit-info-banner-item {
    height: 200px;
    width: 750px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
@media (max-width: 759px) {
  .recruit-info-banner-item {
    height: 150px;
    width: 370px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
